import { FormControl } from '@material-ui/core';
import React from 'react';
import { Origin } from 'types';

interface IProps {
  value: Origin | undefined;
  onChange: (value: Origin) => void;
}

export function OriginControl(props: IProps) {
  const { value, onChange } = props;

  const handleChange = (event: React.ChangeEvent<{ value: unknown }>) => {
    onChange(event.target.value as Origin);
  };

  const options: Array<{ value: Origin; label: string }> = [
    { value: '', label: '(none)' },
    { value: 'partners', label: 'Partners' },
    { value: 'RA', label: 'RA' },
    { value: 'westyorkshire', label: 'West Yorkshire' },
  ];

  return (
    <FormControl>
      <label htmlFor="origin-control" className="control-label">
        Origin
      </label>
      <select
        id="origin-control"
        className="form-control"
        defaultValue={value}
        onChange={handleChange}
      >
        {options.map(({ value, label }) => (
          <option key={value} value={value}>
            {label}
          </option>
        ))}
      </select>
    </FormControl>
  );
}

