import { Grid } from '@material-ui/core';
import { IFormlyField } from 'formly/IFormlyConfig';
import { IModel } from 'formly/IModel';
import React, { memo, ReactNode, useState } from 'react';

interface IProps {
  children: ReactNode;
  field: IFormlyField;
  model: IModel;
}

function CollapsableGroup(props: IProps) {
  const { children, field, model } = props;

  const [collapsed, setCollapsed] = useState(true);

  const fieldGroup = field.fieldGroup?.[0];
  if (!fieldGroup) {
    console.warn('no field group', field);
    return null;
  }

  const requiredFieldKeys = field
    .fieldGroup!.filter((f) => f.templateOptions?.required)
    .map((f) => ({ key: f.key!, type: f.type! }));
  const answeredCount = requiredFieldKeys.filter(({ key, type }) => {
    if (type === 'tlrScoredCheckboxes') {
      const value = model[key]?.model;
      if (value && Array.isArray(value)) {
        return value.some((v) => v != null);
      }
    } else {
      return model[key] != null;
    }
  }).length;
  const isComplete = answeredCount === requiredFieldKeys.length;

  const groupClasses = !collapsed ? 'formly-form-collapsable-groups__group--visible' : '';
  const headClasses =
    (isComplete ? 'formly-form-collapsable-groups__head--complete ' : '') +
    (answeredCount > 0 ? 'formly-form-collapsable-groups__head--begun' : '');

  return (
    <Grid item xs={12} style={{ paddingTop: 0, paddingBottom: 0 }}>
      <div className="formly-form formly-form-collapsable-groups">
        <div className={`formly-form-collapsable-groups__group ${groupClasses}`}>
          <div
            className={`formly-form-collapsable-groups__head ${headClasses}`}
            id={`group-header-${fieldGroup.key}`}
            onClick={() => setCollapsed((prevCollapsed) => !prevCollapsed)}
          >
            <div className="formly-form-collapsable-groups__head-title">
              <h3>
                {fieldGroup.type === 'heading'
                  ? fieldGroup.templateOptions?.label
                  : field.data?.label}
              </h3>
            </div>
            <div className="formly-form-collapsable-groups__head-status">
              <p>
                Completed: <span>{`${answeredCount}/${requiredFieldKeys.length}`}</span>
              </p>
            </div>
            <div className="formly-form-collapsable-groups__head-toggle">
              <button
                type="button"
                className="mdl-button mdl-js-button mdl-button--raised mdl-js-ripple-effect"
              >
                {collapsed ? 'view' : 'hide'}
              </button>
            </div>
          </div>
          <div className="formly-form-collapsable-groups__body">{children}</div>
        </div>
      </div>
    </Grid>
  );
}

export default memo(CollapsableGroup);
