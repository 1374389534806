import { useCreateLibraryFiles } from 'apollo-hooks';
import { VERIFIERS_FOLDER_ID } from 'appGlobals';
import { CouncilControl } from 'components/Reviewers/CouncilControl';
import { TillrModal } from 'components/Shared/TillrModal';
import { format } from 'date-fns';
import { CouncilData, ReviewersData } from 'hooks/useGetReviewersData';
import React, { ChangeEventHandler, useState } from 'react';
import { Link, useHistory } from 'react-router-dom';
import { CreateLibraryFilesMutationVariables } from 'tillr-graphql';
import { getFileUrl, uploadFile } from 'utils';
import { AdminPageTitle } from '../AdminPageTitle';
import { convertCsvToReviewersData } from './utils';

export function UpdateVerifiersPage() {
  const history = useHistory();

  const [reviewersData, setReviewersData] = useState<{ json: string; data: ReviewersData }>();
  const [error, setError] = useState<boolean>();
  const [council, setCouncil] = useState<CouncilData>();
  const [confirmUpdate, setConfirmUpdate] = useState(false);

  const [createLibraryFiles, createLibraryFilesState] = useCreateLibraryFiles({
    folderId: VERIFIERS_FOLDER_ID,
  });
  const [isUploading, setIsUploading] = useState(false);
  const [uploadError, setUploadError] = useState<string>();

  const handleFileChange: ChangeEventHandler<HTMLInputElement> = (e) => {
    const file = e.target.files?.[0];
    if (file) {
      const reader = new FileReader();
      reader.onload = (ev: ProgressEvent<FileReader>) => {
        const fileContents = ev.target?.result as string | null;

        if (!fileContents) {
          setError(true);
          return;
        }

        const convertedData = convertCsvToReviewersData(fileContents);
        if (convertedData) {
          setReviewersData(convertedData);
          setError(false);
        } else {
          setError(true);
        }
      };
      reader.readAsText(file);
    }
  };

  const handleUpdate = () => {
    setConfirmUpdate(true);
  };

  const handleCancelUpdate = () => {
    setConfirmUpdate(false);
  };

  const handleConfirmUpdate = () => {
    if (!reviewersData) {
      throw new Error('reviewersData not set.');
    }

    const fileName = `lsavi-verifiers-list-${format(new Date(), 'yyyy-MM-dd-HH-mm-ss')}.json`;

    setIsUploading(true);

    const variables: CreateLibraryFilesMutationVariables = {
      folderId: VERIFIERS_FOLDER_ID,
      fileNames: [fileName],
    };
    createLibraryFiles({
      variables,
    })
      .then((response) => {
        if (response.data?.createLibraryFiles) {
          const { uploadUrlTemplate, files } = response.data.createLibraryFiles;
          const uploadUrl = getFileUrl(uploadUrlTemplate, files[0].id, files[0].name);
          const file = new File(
            [new Blob([reviewersData.json], { type: 'application/json' })],
            files[0].name,
          );

          uploadFile(uploadUrl, file, (percentComplete: number) => {
            if (percentComplete === 100) {
              setConfirmUpdate(false);
              setIsUploading(false);
            }
          }).catch((reason: Error) => {
            setConfirmUpdate(false);
            setUploadError(reason.message);
          });
        } else {
          setConfirmUpdate(false);
          setIsUploading(false);
        }
      })
      .catch(() => {
        setConfirmUpdate(false);
        setIsUploading(false);
      });
  };

  const handleOk = () => {
    history.push('/admin');
  };

  return (
    <>
      {confirmUpdate && (
        <TillrModal
          title="Are you certain?"
          okButtonText="Yes, update it"
          onOk={handleConfirmUpdate}
          onCancel={handleCancelUpdate}
          disabled={createLibraryFilesState.loading || isUploading}
        >
          Please confirm that the CSV file looks to be valid, you have tested at least one police
          authority / local authority combination, and are happy for the verifier list to be updated
          for all users
        </TillrModal>
      )}
      {createLibraryFilesState.data && !isUploading && (
        <TillrModal title="Thank you" onOk={handleOk} okButtonText="Close">
          The verifiers list has been updated.
        </TillrModal>
      )}
      {(createLibraryFilesState.error || uploadError) && (
        <TillrModal title="Apologies" onOk={handleOk} okButtonText="Close">
          We were unable to update the verifiers list or contact support@tillr.io.
        </TillrModal>
      )}
      <AdminPageTitle title="Update Verifiers List" />
      <div className="dashboard">
        <section className="verifiers-admin">
          <p>To update the verifiers list, follow these steps:</p>

          <ol>
            <li>Export/download the latest verifiers list as a CSV file.</li>
            <li>Upload the CSV file by clicking on the upload button below.</li>
            <li>
              Confirm that the upload has been successful by selecting one or more combinations of
              police authorities and local councils.
            </li>
            <li>Hit the 'Update Verifiers List' button.</li>
          </ol>

          <button
            className="mdl-button mdl-js-button mdl-button--raised mdl-js-ripple-effect"
            onClick={() => document.getElementById('files')?.click()}
            disabled={reviewersData !== undefined}
          >
            UPLOAD CSV FILE
          </button>

          <input type="file" id="files" style={{ display: 'none' }} onChange={handleFileChange} />

          {error && (
            <div className="alert alert--error" role="alert">
              <strong>
                This CSV above cannot be converted into the correct format. Please check your input.
              </strong>
            </div>
          )}
          {reviewersData && (
            <div>
              {!error && (
                <div>
                  <div className="well">
                    <h5>The CSV is valid, and users will be given the following options:</h5>

                    <CouncilControl
                      data={reviewersData.data}
                      onChangeCouncil={(value) => setCouncil(value?.council)}
                    />

                    {council && (
                      <div>
                        <p>Will send emails to:</p>
                        <ul>
                          <li>
                            Police:{' '}
                            {council.policeAuthorityEmail.length > 0
                              ? council.policeAuthorityEmail
                              : 'accreditation@licensingsavi.com'}
                          </li>
                          <li>
                            Local Authority:{' '}
                            {council.localCouncilEmail.length > 0
                              ? council.localCouncilEmail
                              : 'accreditation@licensingsavi.com'}
                          </li>
                        </ul>
                      </div>
                    )}
                  </div>
                </div>
              )}

              <div className="buttons buttons-pair">
                <Link to="/admin" style={{ textDecoration: 'none' }}>
                  <button className="mdl-button mdl-js-button mdl-button--raised mdl-js-ripple-effect verifiers-admin-cancel">
                    Cancel
                  </button>
                </Link>
                {!error && (
                  <button
                    className="mdl-button mdl-js-button mdl-button--raised mdl-js-ripple-effect"
                    disabled={!council}
                    onClick={handleUpdate}
                  >
                    Update Verifiers List
                  </button>
                )}
              </div>

              <p className="pending-file-status"></p>
            </div>
          )}
        </section>
      </div>
    </>
  );
}

