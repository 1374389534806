import { ReturnLink } from 'components/Shared/ReturnLink';
import FormlyForm from 'formly/FormlyForm';
import { IFormlyConfig } from 'formly/IFormlyConfig';
import { IModel } from 'formly/IModel';
import React from 'react';

interface IProps {
  siteName?: string;
  formlyConfig: IFormlyConfig;
  formData: IModel;
}

export function AssessmentPrintView(props: IProps) {
  const { siteName, formlyConfig, formData } = props;

  return (
    <>
      <style>
        {`
		.print-friendly {
				display: none;
		}
			.recommendations section .go-to-link {
				display: none;
		}
			.advice-and-recommendations h1 a img {
				width: 180px;
		}
			.advice-and-recommendations h3 {
				display: none;
		}
			.advice-and-recommendations h3.print-friendly {
				display: block;
		}
			.advice-and-recommendations div.collapsable-groups__group {
				display: none;
		}
			.advice-and-recommendations div.collapsable-groups__group.print-friendly-temp {
				display: block;
		}
			.advice-and-recommendations div.collapsable-groups__group.print-friendly-temp .collapsable-groups__head-title {
				padding: 0 !important;
				margin-bottom: 16px;
		}
			.advice-and-recommendations div.collapsable-groups__group.print-friendly-temp .collapsable-groups__head-title h3 {
				display: block;
		}
			.advice-and-recommendations div.collapsable-groups__group.print-friendly-temp .collapsable-groups__head-toggle {
				display: none;
		}
			.advice-and-recommendations div.collapsable-groups__group.print-friendly-temp .collapsable-groups__body {
				display: block;
				border: none;
				box-shadow: none;
				padding: 0;
		}
			.advice-and-recommendations div.collapsable-groups__group.print-friendly-temp .collapsable-groups__body hr {
				border-color: #000;
		}
			.advice-and-recommendations div.collapsable-groups__group.print-friendly-temp .collapsable-groups__body .recommendation {
				max-height: none;
				padding: 0;
				overflow: auto;
		}
			.advice-and-recommendations div.collapsable-groups__group.print-friendly-temp .collapsable-groups__body .read-more, .advice-and-recommendations div.collapsable-groups__group.print-friendly-temp .collapsable-groups__body .read-less {
				display: none;
		}
			.advice-and-recommendations section {
				padding: 0;
				margin: 16px;
		}
			.advice-and-recommendations section h3 button {
				display: none;
		}
			footer >
			div {
				display: none;
		}
			footer p {
				display: none;
		}
			footer p.print-friendly {
				display: block;
		}
			.mdl-layout__container {
				display: block !important;
		}
			body.home-layout main.mdl-layout__content, main.mdl-layout__content {
				margin-top: 0 !important;
		}
			#snackbar {
				display: none;
		}
			aside {
				display: none;
		}
			p, input, select, label {
				page-break-inside: avoid;
		}
			select::-ms-expand {
				display: none;
		}
			.form-control[readonly] {
				color: #666;
				background-color: #fff;
				border-radius: 0;
				border: 1px solid #ccc;
		}
			.radio input[type=radio], .radio-inline input[type=radio], .checkbox input[type=radio], .checkbox-inline input[type=radio] {
				margin-left: 0;
		}
			.checkbox input[type=checkbox], .checkbox-inline input[type=checkbox] {
				top: 5px;
		}
			.radio input[type=radio], .radio-inline input[type=radio] {
				top: 3px;
		}
			input.form-control, select.form-control {
				border: 1px solid #ccc;
				box-shadow: none;
				transition: none;
		}
			.form-group .form-control, .form-group .form-control.friendly-readonly[disabled] {
				border: 1px solid #ccc;
		}
			.print-textarea {
				display: block;
				overflow: visible;
				white-space: pre-line;
				min-height: 100px;
				padding: 0 12px 12px;
				font-size: 14px;
				line-height: 1.42857143;
				color: #666;
				background-color: #fff;
				border: 1px solid #ccc;
		}
			.well {
				padding-left: 0 !important;
				padding-right: 0 !important;
				margin-bottom: 0;
		}
			body.home-layout .mdl-layout__header .mdl-layout__header-row h6 {
				display: block;
		}
			.ribbon, body .ribbon, body.home-layout.body--scrolled .ribbon, body.body--scrolled .ribbon {
				display: none !important;
		}
			.progress, body .progress, body.home-layout.body--scrolled .progress, body.body--scrolled .progress {
				display: none !important;
		}
			body.home-layout .mdl-layout__header {
				display: block;
		}
			body.home-layout .mdl-layout__header h1 {
				top: 8px;
		}
			body .mdl-layout__header {
				display: block;
		}
			body .mdl-layout__header h1 {
				left: 0 !important;
				top: 8px;
		}
			body .mdl-layout__header {
				display: block;
		}
			body .mdl-layout__header h6 {
				right: 0 !important;
		}
			body.home-layout .mdl-layout__header {
				background-color: transparent;
		}
			body.home-layout.body--scrolled .mdl-layout__header {
				background-color: transparent;
		}
			.paper .paper-clip + h2 {
				text-indent: 0;
		}
			.radio input[type=radio] {
				margin-left: 0;
		}
			.checkbox input[type=checkbox] {
				margin-left: 0;
		}
			.paper button.tillr-form-info {
				display: none;
		}
			.action-buttons {
				display: none;
		}
			section {
				padding: 0;
				border: none;
				box-shadow: none;
				background-color: #fff;
		}
			section.paper {
				padding: 0;
		}
			.mdl-layout__header, body.home-layout.body--scrolled .mdl-layout__header, body.body--scrolled .mdl-layout__header {
				border: none;
				position: static;
				left: 0;
				top: 0;
		}
			a[href]:after {
				content: none !important;
		}
			.home-layout h1#main-logo, body.home-layout.body--scrolled h1#main-logo {
				display: none;
		}
			.home-layout h6, body.home-layout.body--scrolled h6 {
				display: block;
				z-index: 10000;
		}
			.home-layout h6 a span, body.home-layout.body--scrolled h6 a span {
				display: none;
		}
			.mdl-layout__header {
				position: static;
				left: 0;
				top: 0;
		}
			.welcome section button {
				display: none;
		}
			footer {
				padding: 10px 0 0 0;
		}
			footer .mdl-mega-footer--top-section {
				display: none;
		}
			footer .mdl-mega-footer--bottom-section {
				display: none;
		}
			#contact-button {
				display: none;
		}
			div.assessment section, div.assessment section:not(.step) {
				max-width: 100%;
				width: 100%;
				margin: 0;
				padding: 16px;
				border: none;
				box-shadow: none;
				background-color: #fff;
		}
			div.assessment section .row, div.assessment section:not(.step) .row {
				margin: 0;
		}
		div.assessment section .form-paper {
			box-shadow: none;
			border: none;
		}
			div.assessment section .form-paper h2, div.assessment section:not(.step) .form-paper h2 {
				font-size: 1.8rem;
				line-height: 2.2rem;
				border-bottom: solid 1px #000;
		}
			div.assessment section div.alert.alert--info.alert--info-small, div.assessment section:not(.step) div.alert.alert--info.alert--info-small, div.assessment section div.form-paper-status, div.assessment section:not(.step) div.form-paper-status, div.assessment section div.form-button-container, div.assessment section:not(.step) div.form-button-container, div.assessment section div.required-fields-info, div.assessment section:not(.step) div.required-fields-info {
				display: none;
		}
			div.assessment section section.step, div.assessment section:not(.step) section.step {
				padding: 0;
		}
			div.assessment section div.formly-field, div.assessment section:not(.step) div.formly-field {
				padding-left: 0 !important;
				padding-right: 0 !important;
		}
			div.assessment section .formly-form.formly-form-collapsable-groups .formly-form-collapsable-groups__group .formly-form-collapsable-groups__head, div.assessment section:not(.step) .formly-form.formly-form-collapsable-groups .formly-form-collapsable-groups__group .formly-form-collapsable-groups__head {
				display: none;
		}
			div.assessment section .formly-form.formly-form-collapsable-groups .formly-form-collapsable-groups__group .formly-form-collapsable-groups__body, div.assessment section:not(.step) .formly-form.formly-form-collapsable-groups .formly-form-collapsable-groups__group .formly-form-collapsable-groups__body {
				display: block;
				border: none;
				padding: 0;
		}
			div.assessment section .formly-form.formly-form-collapsable-groups .formly-form-collapsable-groups__group .formly-form-collapsable-groups__body h2, div.assessment section:not(.step) .formly-form.formly-form-collapsable-groups .formly-form-collapsable-groups__group .formly-form-collapsable-groups__body h2 {
				font-size: 1.6rem;
				line-height: 3rem;
				border-bottom: solid 1px #000;
		}
    
    div.form-paper {
      background-color: #fff;
    }
    `}
      </style>
      <div className="assessment print-view">
        <section>
          <h3>
            {siteName ?? 'Assessment'} <ReturnLink />
          </h3>

          <div className="form-paper">
            <FormlyForm model={formData} config={formlyConfig} mode="readonly" customButtons />
          </div>
        </section>
      </div>
    </>
  );
}

