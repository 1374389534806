import React, { useEffect } from 'react';

interface IProps {
  value: number;
}

export function StarRating(props: IProps) {
  const { value } = props;

  const stars = [1, 2, 3, 4, 5];
  const initialTimer = 1000;
  const starTimer = 500;

  const showStar = (star: HTMLSpanElement, i: number) => {
    // it's a half star score
    if (value % 1 !== 0) {
      const roundedScore = value + 0.5;
      if (i + 1 < roundedScore) {
        star.classList.add('star-full');
      }
      if (i + 1 === roundedScore) {
        star.classList.add('star-half');
      }
      return;
    }
    // show full stars
    if (i + 1 <= value) {
      star.classList.add('star-full');
    }
  };

  const showStars = () => {
    const nodes = document.querySelectorAll('.dashboard-rating > span');
    const icons = Array.prototype.slice.call(nodes);
    if (icons && icons.length) {
      icons.forEach((star, i) => {
        ((j) => {
          setTimeout(() => {
            showStar(star, j);
          }, (j + 1) * starTimer);
        })(i);
      });
    }
  };

  useEffect(() => {
    setTimeout(() => showStars(), initialTimer);
  }, []);

  return (
    <>
      <div className="dashboard-rating">
        {stars.map((_, i) => (
          <span key={i}>
            <i className="material-icons dashboard-rating__star">star</i>
            <i className="material-icons dashboard-rating__star">star</i>
          </span>
        ))}
        <em>{value}</em>
      </div>
    </>
  );
}

