import { useGetBuildNumber } from 'apollo-hooks';
import buildNumber from 'build-number';
import React from 'react';

export default function BuildNumbersDisplay() {
  const { loading, error, data } = useGetBuildNumber();
  const apiBuildNumber = loading ? 'loading...' : error ? 'error' : data?.buildNumber;
  return (
    <div className="page">
      <section>
        <p>
          Build numbers: UI
          {` ${buildNumber?.substring(0, 7) ?? 'unknown'}`}, API
          {` ${apiBuildNumber?.substring(0, 7) ?? 'unknown'}`}
        </p>
      </section>
    </div>
  );
}
