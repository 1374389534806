import { Typography } from '@material-ui/core';
import React from 'react';
import { Link } from 'react-router-dom';

interface IProps {
  title: string;
  crumbs?: Array<{ name: string; to: string }>;
}

export function PageTitle(props: IProps) {
  const { title, crumbs } = props;

  return (
    <div className="banner">
      <div className="banner__inner">
        {crumbs && (
          <div className="banner__breadcrumbs">
            <ul>
              {crumbs.map(({ name, to }) => (
                <li key={name}>
                  <Link to={to}>{name}</Link> &gt;
                </li>
              ))}
            </ul>
          </div>
        )}
        <div className={`banner__title ${!crumbs ? 'no--crumbs' : ''}`}>
          <Typography component="h2" color="primary">
            {title}
          </Typography>
        </div>
      </div>
    </div>
  );
}

