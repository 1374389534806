import { ApolloError } from '@apollo/client';
import { SerializedError } from '@reduxjs/toolkit';
import { FetchBaseQueryError } from '@reduxjs/toolkit/dist/query';
import { format } from 'date-fns';
import { GraphQLError } from 'graphql';
import { Origin } from 'types';
import { IModel } from './formly/IModel';

export function extractErrors(error: ApolloError): string[] {
  if (error.graphQLErrors?.length > 0) {
    return error.graphQLErrors.map((x) => x.message);
  }

  const networkError = error.networkError as any;
  if (networkError?.statusCode === 401) {
    return ['Unauthorized'];
  }
  // error.graphQLErrors doesn't appear to be working for mutations, so check this too
  // (REF: https://github.com/apollographql/apollo-client/issues/2810)
  if (networkError?.result && networkError.result.errors && networkError.result.errors.length) {
    return networkError.result.errors.map((x: GraphQLError) => x.message);
  }

  // This might only be useful for tests
  if (error.networkError?.message) {
    return [error.networkError.message];
  }

  return [];
}

export function getErrorMessage(
  error: FetchBaseQueryError | SerializedError | undefined,
): string | null {
  if (!error) {
    return null;
  }

  const { status } = error as FetchBaseQueryError;

  if (typeof status === 'number' && status >= 400 && status < 500) {
    const clientError = (error as FetchBaseQueryError).data as { Message?: string } | undefined;
    if (clientError) {
      return clientError.Message ?? 'Client error';
    }
  }

  return 'An unexpected error has occurred. If it persists, please contact support.';
}

export function getFileUrl(urlTemplate: string, id: string, fileName: string) {
  return urlTemplate.replace('{id}', id).replace('{fileName}', fileName);
}

function padLeft(number: number, length: number) {
  let str = `${number}`;
  while (str.length < length) {
    str = `0${str}`;
  }
  return str;
}

export function uploadFile(
  url: string,
  file: File,
  progressCallback?: (percentComplete: number) => void,
): Promise<void> {
  return new Promise((resolve, reject) => {
    const blockIds = new Array<string>();
    // Split into 100KiB blocks - max is 100MiB but smaller blocks provide better progress updates
    const maxBlockSize = 5 * 1024 * 1024;
    let bytesUploaded = 0;

    const fileReader = new FileReader();

    function readNextBlock() {
      const blockId = `block-${padLeft(blockIds.length, 6)}`;
      blockIds.push(btoa(blockId));
      const blockContent = file.slice(bytesUploaded, bytesUploaded + maxBlockSize);
      fileReader.readAsArrayBuffer(blockContent);
    }

    function commitBlockList() {
      let blockListXml = '<?xml version="1.0" encoding="utf-8"?><BlockList>';
      blockIds.forEach((x) => {
        blockListXml += `<Latest>${x}</Latest>`;
      });
      blockListXml += '</BlockList>';

      const xhr = new XMLHttpRequest();
      xhr.onload = () => {
        if (xhr.status >= 200 && xhr.status < 300) {
          resolve();
        } else {
          reject(new Error(xhr.statusText));
        }
      };
      xhr.onerror = () => reject(new Error('Error uploading file.'));

      const blockListUrl = `${url}&comp=blocklist`;
      xhr.open('PUT', blockListUrl, true);
      // This is causing added files to open in the browser window (in the same tab)
      // rather than downloading them, as it's supposed to:
      // xhr.setRequestHeader('x-ms-blob-content-type', file.type);
      xhr.send(blockListXml);
    }

    fileReader.onloadend = (e) => {
      if (e.target?.readyState === FileReader.DONE) {
        const blockData = new Uint8Array(e.target.result as ArrayBuffer);

        const xhr = new XMLHttpRequest();
        xhr.onload = () => {
          if (xhr.status >= 200 && xhr.status < 300) {
            bytesUploaded += blockData.length;

            if (progressCallback) {
              const percentComplete = (bytesUploaded / file.size) * 100;
              progressCallback(percentComplete);
            }

            if (bytesUploaded < file.size) {
              readNextBlock();
            } else {
              commitBlockList();
            }
          } else {
            reject(new Error(xhr.statusText));
          }
        };
        xhr.onerror = () => reject(new Error('Error uploading file.'));

        const blockUrl = `${url}&comp=block&blockid=${blockIds[blockIds.length - 1]}`;
        xhr.open('PUT', blockUrl, true);
        xhr.setRequestHeader('Content-Type', 'application/octet-stream');
        xhr.setRequestHeader('x-ms-blob-type', 'BlockBlob');
        xhr.send(blockData);
      }
    };

    readNextBlock();
  });
}

export function uploadFormDataFile(
  url: string,
  file: File,
  progressCallback?: (percentComplete: number) => void,
): Promise<void> {
  return new Promise((resolve, reject) => {
    const blockIds = new Array<string>();
    // Split into 100KiB blocks - max is 100MiB but smaller blocks provide better progress updates
    const maxBlockSize = 5 * 1024 * 1024;
    let bytesUploaded = 0;

    const fileReader = new FileReader();

    function readNextBlock() {
      const blockId = `block-${padLeft(blockIds.length, 6)}`;
      blockIds.push(btoa(blockId));
      const blockContent = file.slice(bytesUploaded, bytesUploaded + maxBlockSize);
      fileReader.readAsArrayBuffer(blockContent);
    }

    function commitBlockList() {
      let blockListXml = '<?xml version="1.0" encoding="utf-8"?><BlockList>';
      blockIds.forEach((x) => {
        blockListXml += `<Latest>${x}</Latest>`;
      });
      blockListXml += '</BlockList>';

      const xhr = new XMLHttpRequest();
      xhr.onload = () => {
        if (xhr.status >= 200 && xhr.status < 300) {
          resolve();
        } else {
          reject(new Error(xhr.statusText));
        }
      };
      xhr.onerror = () => reject(new Error('Error uploading file.'));

      const blockListUrl = `${url}&comp=blocklist`;
      xhr.open('PUT', blockListUrl, true);
      // This is causing added files to open in the browser window (in the same tab)
      // rather than downloading them, as it's supposed to:
      // xhr.setRequestHeader('x-ms-blob-content-type', file.type);
      xhr.send(blockListXml);
    }

    fileReader.onloadend = (e) => {
      if (e.target?.readyState === FileReader.DONE) {
        const blockData = new Uint8Array(e.target.result as ArrayBuffer);

        const xhr = new XMLHttpRequest();
        xhr.onload = () => {
          if (xhr.status >= 200 && xhr.status < 300) {
            bytesUploaded += blockData.length;

            if (progressCallback) {
              const percentComplete = (bytesUploaded / file.size) * 100;
              progressCallback(percentComplete);
            }

            if (bytesUploaded < file.size) {
              readNextBlock();
            } else {
              commitBlockList();
            }
          } else {
            reject(new Error(xhr.statusText));
          }
        };
        xhr.onerror = () => reject(new Error('Error uploading file.'));

        const blockUrl = `${url}&comp=block&blockid=${blockIds[blockIds.length - 1]}`;
        xhr.open('PUT', blockUrl, true);
        xhr.setRequestHeader('Content-Type', 'application/octet-stream');
        xhr.setRequestHeader('x-ms-blob-type', 'BlockBlob');
        xhr.send(blockData);
      }
    };

    readNextBlock();
  });
}

export function getAvatarFromName(name: string) {
  const names = name.split(' ').map((str) => str.charAt(0));
  return names.join(' ');
}

export function uploadFiles(
  urlTemplate: string,
  files: { id: string; file: File }[],
  progressCallback?: (fileIndex: number, percent: number) => void,
): Promise<void[]> {
  return Promise.all(
    files.map((x, i) => {
      const url = getFileUrl(urlTemplate, x.id, x.file.name);
      // eslint-disable-next-line max-len
      return uploadFile(url, x.file, (percentComplete) =>
        progressCallback ? progressCallback(i, percentComplete) : null,
      );
    }),
  );
}

// REF: https://jcward.com/UUID.js
// via https://stackoverflow.com/questions/105034/create-guid-uuid-in-javascript/21963136#21963136
const lut: string[] = [];
for (let i = 0; i < 256; i += 1) {
  lut[i] = (i < 16 ? '0' : '') + i.toString(16);
}
export function generateUuid() {
  /* eslint-disable no-bitwise,no-mixed-operators,prefer-template */
  const d0 = (Math.random() * 0xffffffff) | 0;
  const d1 = (Math.random() * 0xffffffff) | 0;
  const d2 = (Math.random() * 0xffffffff) | 0;
  const d3 = (Math.random() * 0xffffffff) | 0;
  return (
    lut[d0 & 0xff] +
    lut[(d0 >> 8) & 0xff] +
    lut[(d0 >> 16) & 0xff] +
    lut[(d0 >> 24) & 0xff] +
    '-' +
    lut[d1 & 0xff] +
    lut[(d1 >> 8) & 0xff] +
    '-' +
    lut[((d1 >> 16) & 0x0f) | 0x40] +
    lut[(d1 >> 24) & 0xff] +
    '-' +
    lut[(d2 & 0x3f) | 0x80] +
    lut[(d2 >> 8) & 0xff] +
    '-' +
    lut[(d2 >> 16) & 0xff] +
    lut[(d2 >> 24) & 0xff] +
    lut[d3 & 0xff] +
    lut[(d3 >> 8) & 0xff] +
    lut[(d3 >> 16) & 0xff] +
    lut[(d3 >> 24) & 0xff]
  );
  /* eslint-enable */
}

export const FRIENDLY_DATE_FORMAT = 'EEEE do MMMM yyyy';

export const PICKERS_DATE_FORMAT = 'dd/MM/yyyy';

export function formatAsFriendlyDate(date: Date) {
  return format(date, FRIENDLY_DATE_FORMAT);
}

export function getAvatarName(name: string) {
  const words = name.split(' ');
  const result = words.map((word) => word.charAt(0));
  return result.join('');
}

interface IAvatarCacheEntry {
  [name: string]: {
    [name: string]: string | number | undefined;
  };
}

export const avatarNameCache: IAvatarCacheEntry = {};

const localStorageStartUpRoute = 'tillr-start-up-route';

export function setStartUpRoute(path: string) {
  if (!localStorage) return;
  if (path.match(/site-0/)) return;
  localStorage.setItem(localStorageStartUpRoute, path);
}

export function getStartUpRoute(remove = false) {
  if (!localStorage) return null;
  const route = localStorage.getItem(localStorageStartUpRoute);
  if (route) {
    if (remove) {
      localStorage.removeItem(localStorageStartUpRoute);
    }
    // eslint-disable-next-line consistent-return
    return route;
  }
  // eslint-disable-next-line consistent-return
  return null;
}

const ORIGINS: Array<Origin> = ['', 'partners', 'RA', 'westyorkshire'];
export function getOrigin(): Origin {
  const split = window.location.href.split('/');
  const testOrigin = split[split.length - 1] ?? '';
  const origin = ORIGINS.find(
    (o) => o.localeCompare(testOrigin, undefined, { sensitivity: 'base' }) === 0,
  );
  return origin ?? '';
}

export function getFormSection(index: number): string {
  return 'ABCD'[index];
}

export function getStepIndexFromQuestion(question: string): number {
  // eg. B12.3
  return question.charCodeAt(0) - 65;
}

export function getScoreFromOptionValue(value: string): number | null {
  const score = Number(value.split(':').at(-1));
  return Number.isFinite(score) ? score : null;
}

export function scrollToQuestion(label: HTMLLabelElement) {
  const targetNode = label.closest('div.formly-field') || label;
  window.scrollTo(0, targetNode.getBoundingClientRect().top);
}

export function scrollToSmoothly(position: number, duration: number = 500) {
  const currentPos = window.scrollY || window.screenTop;
  let start: number | null = null;
  window.requestAnimationFrame(function step(currentTime) {
    start = !start ? currentTime : start;
    if (currentPos < position) {
      let progress = currentTime - start;
      window.scrollTo(0, ((position - currentPos) * progress) / duration + currentPos);
      if (progress < duration) {
        window.requestAnimationFrame(step);
      } else {
        window.scrollTo(0, position);
      }
    } else {
      let progress = currentTime - start;
      window.scrollTo(0, currentPos - ((currentPos - position) * progress) / duration);
      if (progress < duration) {
        window.requestAnimationFrame(step);
      } else {
        window.scrollTo(0, position);
      }
    }
  });
}

export function getPercentageComplete(
  model: IModel | undefined,
  steps: string[][],
  stepIndex?: number,
) {
  if (!model) {
    return 0;
  }

  let questions = 0;
  let answered = 0;
  steps.forEach((step, index) => {
    if (stepIndex !== undefined && index !== stepIndex) {
      return;
    }

    questions += step.length;
    answered += step.filter((stepQuestion) => {
      if (model && model[stepQuestion]) {
        // Check for scored checkboxes
        if (model[stepQuestion].constructor === Object && model[stepQuestion].model) {
          let cbModel = model[stepQuestion].model;
          if (cbModel.constructor === Array) {
            let answered = cbModel.filter((option) => {
              return option !== null;
            });
            if (answered.length) {
              return stepQuestion;
            }
          }
        } else {
          // Any other standard
          return stepQuestion;
        }
      }
    }).length;
  });

  if (questions === 0) {
    console.warn('no questions');
  }

  return Math.round((answered / questions) * 100);
}

export function getAttachmentItemUrl(urlTemplate: string, id: string, fileName: string) {
  return urlTemplate.replace('{id}', id).replace('{fileName}', fileName);
}

export function formatReviewDate(date: Date | string | null | undefined): string {
  if (date === null || date === undefined) {
    return 'Invalid date';
  }
  const d = typeof date === 'string' ? new Date(date) : date;
  return format(d, 'eeee do MMMM yyyy');
}

export function getGreeting(): string {
  const hour = new Date().getHours();
  if (hour < 12) {
    return 'Good morning';
  } else if (hour >= 12 && hour <= 17) {
    return 'Good afternoon';
  } else {
    return 'Good evening';
  }
}

export function toDate(d: string | undefined): Date | undefined {
  return d ? new Date(d) : undefined;
}

export function pluralise(word: string, count: number): string {
  return `${word}${count === 1 ? '' : 's'}`;
}
