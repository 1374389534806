export type SiteType = 'CHAIN' | 'GROUP' | 'VENUE';

export const CHAIN_SITE_TYPE: SiteType = 'CHAIN';
export const GROUP_SITE_TYPE: SiteType = 'GROUP';
export const VENUE_SITE_TYPE: SiteType = 'VENUE';

export type MarketingPreferences = {
  primary: boolean;
  parent: boolean;
};

export type Origin = '' | 'partners' | 'RA' | 'westyorkshire';

export type UserSettings = {
  marketing?: MarketingPreferences;
  origin?: Origin;
  paymentExempt?: boolean;
};

