import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { userManager } from 'createUserManager';
import { getUrlPrefix } from 'environment-utils';
import { CreateReviewsPayload, GetReviewsResponse, UpdateReviewStatusPayload } from './types';

const setDefaultHeaders = async (headers: Headers) => {
  const user = await userManager.getUser();
  if (user) {
    headers.set('Authorization', `${user.token_type} ${user.access_token}`);
  }
  headers.set('ModuleId', '7');
  return headers;
};

export const api2Api = createApi({
  reducerPath: 'api2Api',
  baseQuery: fetchBaseQuery({
    baseUrl: `https://${getUrlPrefix()}api2.tillr.io`,
    prepareHeaders: setDefaultHeaders,
  }),
  tagTypes: ['Review'],
  endpoints: (builder) => ({
    getReviews: builder.query<GetReviewsResponse, { formId: string }>({
      query: ({ formId }) => ({ url: `Forms/Reviews?formId=${formId}`, method: 'GET' }),
      providesTags: [{ type: 'Review', id: 'LIST' }],
    }),
    createReviews: builder.mutation<void, CreateReviewsPayload>({
      query: (payload) => ({ url: 'Forms/Reviews/Create', method: 'POST', body: payload }),
      invalidatesTags: (result, error) => (error ? [] : [{ type: 'Review', id: 'LIST' }]),
    }),
    updateReviewStatus: builder.mutation<void, UpdateReviewStatusPayload>({
      query: ({ Status, ...payload }) => ({
        url: `Forms/Reviews/${Status}`,
        method: 'POST',
        body: payload,
      }),
      invalidatesTags: (result, error) => (error ? [] : [{ type: 'Review', id: 'LIST' }]),
    }),
  }),
});

export const { useGetReviewsQuery, useCreateReviewsMutation, useUpdateReviewStatusMutation } =
  api2Api;

