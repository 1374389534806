import DateFnsUtils from '@date-io/date-fns';
import Grid from '@material-ui/core/Grid';
import EventIcon from '@material-ui/icons/Event';
import { KeyboardDatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
import 'date-fns';
import formatISO from 'date-fns/formatISO';
import isValid from 'date-fns/isValid';
import set from 'date-fns/set';
import { IComponentProps } from 'formly/IComponentProps';
import withComponentPropsMemo from 'formly/withComponentPropsMemo';
import React, { useEffect } from 'react';
import { PICKERS_DATE_FORMAT } from 'utils';
import HelpText from './shared/HelpText';
import {
  FieldValidationMessage,
  getRuleMessage,
  NestedFieldValidationMessage,
} from './shared/Validation';

function DatePicker(props: IComponentProps) {
  const {
    field,
    readonly,
    modelValue,
    onValueUpdate,
    register,
    errors,
    setValue,
    clearErrors,
    setError,
    getValues,
  } = props;

  // "undefined" defaults to now, "null" defaults to unset.
  const value =
    modelValue === undefined
      ? formatISO(set(new Date(), { hours: 0, minutes: 0, seconds: 0 }))
      : modelValue;

  const customTrigger = () => {
    const values = getValues();
    if (field.templateOptions?.required && field.key && !values[field.key]?.length) {
      setError(field.key, {
        type: 'manual',
        message: getRuleMessage('required'),
      });
    } else {
      clearErrors(field.key);
    }
  };

  useEffect(() => {
    register({ name: field.key });
    setValue(field.key, value);
    if (field.key) {
      onValueUpdate(field.key, value);
    }
    customTrigger();
    // TODO: If I remove the empty array, useEffect keeps getting called. Isn't the
    // idea that an empty array will only call useEffect once?
    // eslint-disable-next-line
  }, []);

  const handleChange = (date: Date | string | null) => {
    if (field.key) {
      let fDate = date;
      if (fDate && isValid(fDate)) {
        fDate = formatISO(set(new Date(fDate), { hours: 0, minutes: 0, seconds: 0 }));
      }
      onValueUpdate(field.key, fDate);
      setValue(field.key, fDate, { shouldDirty: true });

      if (field.templateOptions?.required && field.key && !fDate) {
        setError(field.key, {
          type: 'manual',
          message: getRuleMessage('required'),
        });
        return;
      } else {
        clearErrors(field.key);
      }
    }
  };

  return (
    <>
      {field.className?.match(/clear/) && (
        <hr className="tillr-form-grid-element tillr-form-grid-element--break" />
      )}
      <Grid
        item
        xs={12}
        sm={field.className?.match(/col-xs-6/) ? 6 : 12}
        className={
          field.className?.match(/pull-right/)
            ? 'tillr-form-grid-element tillr-form-grid-element--align-right'
            : 'tillr-form-grid-element'
        }
      >
        <MuiPickersUtilsProvider utils={DateFnsUtils}>
          <KeyboardDatePicker
            id={field.key}
            inputVariant="outlined"
            label={field.templateOptions?.label}
            format={PICKERS_DATE_FORMAT}
            value={value}
            onChange={handleChange}
            KeyboardButtonProps={{
              'aria-label': 'change date',
            }}
            keyboardIcon={<>{errors[field.key!] ? <EventIcon color="error" /> : <EventIcon />}</>}
            minDate={field.data?.minDate}
            maxDate={field.data?.maxDate}
            disabled={readonly || field.templateOptions?.disabled}
            clearable
            required={!readonly && field.templateOptions?.required}
            invalidDateMessage={
              <NestedFieldValidationMessage
                message="Invalid date format. Please use DD/MM/YYYY."
                className="as-body1"
              />
            }
            error={!!errors[field.key!]}
          />
        </MuiPickersUtilsProvider>
        {errors[field.key!] && <FieldValidationMessage message={errors[field.key!].message} />}
        {field.data?.help && <HelpText value={field.data?.help} />}
      </Grid>
    </>
  );
}

export default withComponentPropsMemo(DatePicker);

