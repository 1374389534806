import { STRIPE_PRODUCT_SKU, STRIPE_PUBLISHABLE_KEY } from 'appGlobals';
import { TillrModal } from 'components/Shared/TillrModal';
import React, { useEffect, useState } from 'react';
import { useCreateCheckoutSessionMutation } from 'store/accounts/accountsApi';

interface IProps {
  isRenewal: boolean;
  disabled: boolean;
}

export function Payment(props: IProps) {
  const { isRenewal, disabled } = props;

  const [openModal, setOpenModel] = useState(false);

  const [createCheckoutSession, createCheckoutSessionState] = useCreateCheckoutSessionMutation();

  useEffect(() => {
    if (createCheckoutSessionState.data) {
      (window as any)
        .Stripe(STRIPE_PUBLISHABLE_KEY)
        .redirectToCheckout({ sessionId: createCheckoutSessionState.data.SessionId });
      // TODO: handle errors?
    }
  });

  const handleClick = () => {
    setOpenModel(true);
  };

  const handleOk = () => {
    createCheckoutSession({ Sku: STRIPE_PRODUCT_SKU, Quantity: 1 });
    setOpenModel(false);
  };

  const handleCancel = () => {
    setOpenModel(false);
  };

  return (
    <div>
      <div>
        {isRenewal ? (
          <p>Your LSAVI accreditation will expire shortly.</p>
        ) : (
          <p>You are now ready to begin your Licensing SAVI assessment.</p>
        )}
        <p>
          Click below to be redirected to the Stripe payment platform, where you will be able to
          securely purchase your annual licence. After a successful purchase, you will be returned
          back here, where you'll be able to start your assessment.
        </p>
      </div>
      <button type="button" disabled={disabled} onClick={handleClick}>
        Purchase Your Assessment
        <span className="material-icons">shopping_cart</span>
      </button>

      {openModal && (
        <TillrModal
          title="Purchase Assessment"
          okButtonText="Continue to Stripe"
          onOk={handleOk}
          onCancel={handleCancel}
        >
          To purchase the Assessment, you will be redirected to the Stripe website
        </TillrModal>
      )}
    </div>
  );
}

