import React, { useContext } from 'react';
import { RouteComponentProps } from 'react-router';
import UserManagerContext from 'UserManagerContext';
import { AuthScreen } from './AuthScreen';

export function Authenticate(props: RouteComponentProps) {
  const {
    location: { search },
  } = props;
  const loginHint = new URLSearchParams(search).get('login_hint');

  const userManager = useContext(UserManagerContext)!;
  userManager.signinRedirect({ login_hint: loginHint });

  return <AuthScreen state="redirecting" />;
}
