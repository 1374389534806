import React from 'react';
import { Link } from 'react-router-dom';

export function SignOutButton() {
  return (
    <Link to="/logout">
      <button type="button" className="link-button">
        sign out
      </button>
    </Link>
  );
}

