import { PageTitle } from 'components/Shared/PageTitle';
import { PremisesInfoAdminContainer } from 'components/Venues/PremisesInfoAdminContainer';
import React from 'react';
import { useGetChainFromParams } from '../useGetChainFromParams';

export function ChainVenuePremisesInfoAdminPage() {
  const { chainId, chainSite, venueId, venueSite } = useGetChainFromParams();

  return (
    <>
      <PageTitle
        crumbs={[{ name: chainSite?.name ?? 'Manage chain', to: `/chains/${chainId}/manage` }]}
        title={venueSite?.name ?? 'Premises info'}
      />
      <div className="page">
        <section>
          <PremisesInfoAdminContainer
            venueId={venueId!}
            returnButtonLabel="Return to chain"
            returnUrl={`/chains/${chainId}/manage`}
          />
        </section>
      </div>
    </>
  );
}

