import { FormControl } from '@material-ui/core';
import React, { ChangeEvent, useEffect, useState } from 'react';
import { MarketingPreferences } from 'types';

interface IProps {
  labelText?: string;
  value: MarketingPreferences | undefined;
  onChange: (value: MarketingPreferences) => void;
}

export function MarketingPreferencesControl(props: IProps) {
  const { labelText, value: initialValue, onChange } = props;

  const [value, setValue] = useState<MarketingPreferences>(
    initialValue ?? { primary: false, parent: false },
  );

  const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
    const { name, checked } = e.target;
    setValue((prev) => ({ ...prev, [name]: checked }));
  };

  useEffect(() => {
    onChange(value);
  }, [value]);

  const defaultLabelText =
    'We would like to keep in touch with you about our services, important news and events. You can unsubscribe at any time.';

  return (
    <FormControl component="fieldset">
      <label className="control-label">{labelText ?? defaultLabelText}</label>
      <div className="checkbox formly-field">
        <label htmlFor="marketingPreferencesPrimary" className="control-label">
          <input
            type="checkbox"
            id="marketingPreferencesPrimary"
            name="primary"
            className="formly-field magic-checkbox"
            checked={value.primary}
            onChange={handleChange}
          />
          <label htmlFor="marketingPreferencesPrimary" />
          Yes! I’d like to receive marketing communications from Licensing Security & Vulnerability
          Initiative (LSAVI).
        </label>
      </div>
      <div className="checkbox formly-field">
        <label htmlFor="marketingPreferencesParent" className="control-label">
          <input
            type="checkbox"
            id="marketingPreferencesParent"
            name="parent"
            className="formly-field magic-checkbox"
            checked={value.parent}
            onChange={handleChange}
          />
          <label htmlFor="marketingPreferencesParent" />
          Yes! I’d like to receive marketing communications from Police Crime Prevention Initiatives
          (PCPI), our parent organisation.
        </label>
      </div>
    </FormControl>
  );
}

