import { useGetSites } from 'apollo-hooks';
import { ActivityIndicator } from 'components/ActivityIndicator';
import ErrorDisplay from 'components/ErrorDisplay';
import React from 'react';
import { Link } from 'react-router-dom';
import { CHAIN_SITE_TYPE } from 'types';
import { AdminPageTitle } from '../AdminPageTitle';
import { AddChain } from './AddChain';

export function ManageChainsPage() {
  const { loading, error, data } = useGetSites({ parentSiteId: null, type: CHAIN_SITE_TYPE });

  return (
    <>
      <AdminPageTitle title="Manage chains" />
      <div className="page">
        <section>
          {loading && <ActivityIndicator showProgressBar />}
          {error && <ErrorDisplay error={error} />}
          {data && (
            <div>
              {data.sites && (
                <ul>
                  {data.sites.map((site) => (
                    <li key={site.id}>
                      <Link to={`/chains/${site.id}`}>{site.name}</Link>
                    </li>
                  ))}
                </ul>
              )}
              <AddChain />
            </div>
          )}
        </section>
      </div>
    </>
  );
}

