import { Button } from '@material-ui/core';
import { useUpdateForm } from 'apollo-hooks';
import {
  ASSESSMENT_FORM_TEMPLATE_ID,
  ASSESSMENT_REVIEWER_LOCAL_COUNCIL_FIELD_ID,
  ASSESSMENT_REVIEWER_POLICE_AUTHORITY_FIELD_ID,
  MODULE_ID,
} from 'appGlobals';
import { ActivityIndicator } from 'components/ActivityIndicator';
import ErrorDisplay from 'components/ErrorDisplay';
import { CouncilControl, CouncilControlValue } from 'components/Reviewers/CouncilControl';
import { TillrModal } from 'components/Shared/TillrModal';
import SiteContext from 'components/Sites/SiteContext';
import { addDays, format } from 'date-fns';
import { useGetLatestFormAndTemplate } from 'hooks/useGetLatestFormAndTemplate';
import { useGetReviewersData } from 'hooks/useGetReviewersData';
import { useGetReviewsData } from 'hooks/useGetReviewsData';
import React, { ChangeEventHandler, useContext, useState } from 'react';
import { useHistory } from 'react-router';
import { useCreateReviewsMutation } from 'store/api2/api2Api';
import { formatReviewDate, getErrorMessage } from 'utils';

interface IProps {
  isReviewer: boolean;
}

export function Reviewers(props: IProps) {
  const { isReviewer } = props;

  const history = useHistory();

  const getFormState = useGetLatestFormAndTemplate(ASSESSMENT_FORM_TEMPLATE_ID, {
    includePrevious: true,
  });
  const getReviewsState = useGetReviewsData(getFormState.form?.id);
  const getReviewersState = useGetReviewersData();

  const { siteId } = useContext(SiteContext)!;
  const module = MODULE_ID;

  const [updateForm, updateFormState] = useUpdateForm({
    siteId,
    module,
    id: getFormState.form?.id,
  });
  const [createReviews, createReviewsState] = useCreateReviewsMutation();

  const [councilControlValue, setCouncilControlValue] = useState<CouncilControlValue | undefined>();
  const [reviewerEmails, setReviewerEmails] = useState<Array<string>>([]);
  const [factsChecked, setFactsChecked] = useState(false);
  const [confirmSendForReview, setConfirmSendForReview] = useState(false);
  const [error, setError] = useState(false);

  const { form, formData, previousFormData } = getFormState;

  const handleCheckbox: ChangeEventHandler<HTMLInputElement> = (event) => {
    setFactsChecked(event.currentTarget.checked);
  };

  const handleChangeCouncil = (value: CouncilControlValue | undefined) => {
    setCouncilControlValue(value);
    setReviewerEmails(
      value?.council
        ? [
            'accreditation@licensingsavi.com',
            value.council.policeAuthorityEmail,
            value.council.localCouncilEmail,
          ].filter((x) => x)
        : [],
    );
  };

  const handleSendForReview = () => {
    setConfirmSendForReview(true);
  };

  const handleCancelSendForReview = () => {
    setConfirmSendForReview(false);
  };

  const handleConfirmSendForReview = () => {
    if (!form || !formData) {
      throw Error('Form not set.');
    }

    if (reviewerEmails.length === 0 || !councilControlValue) {
      throw Error('ReviewerEmails not set.');
    }

    const newFormData = {
      ...formData,
      [ASSESSMENT_REVIEWER_POLICE_AUTHORITY_FIELD_ID]: councilControlValue.policeAuthority,
      [ASSESSMENT_REVIEWER_LOCAL_COUNCIL_FIELD_ID]: councilControlValue.council.name,
    };

    updateForm({
      variables: {
        siteId,
        module,
        form: {
          id: form.id,
          name: form.name,
          formDataJson: JSON.stringify(newFormData),
          isSubmitted: true,
        },
      },
    }).then((result) => {
      if (result.errors) {
        setError(true);
      } else {
        createReviews({
          FormId: form.id,
          ReviewerEmails: reviewerEmails,
        }).then(() => setConfirmSendForReview(false));
      }
    });
  };

  const handleReturnToJourney = () => {
    history.push('/journey');
  };

  return (
    <>
      {confirmSendForReview && !error && (
        <TillrModal
          title="Send to Reviewers"
          okButtonText="Continue"
          onOk={handleConfirmSendForReview}
          onCancel={handleCancelSendForReview}
          disabled={updateFormState.loading || createReviewsState.isLoading}
        >
          Once sent, your Assessment will be locked, and your reviewers will have until{' '}
          <strong>{format(addDays(new Date(), 30), 'do MMMM yyyy')}</strong> to make a decision
          about your application.
        </TillrModal>
      )}
      {error && (
        <TillrModal
          title="There was a problem"
          onOk={handleReturnToJourney}
          okButtonText="Return to your journey"
        >
          We were unable to lock your Assessment before review. Please try again later.
        </TillrModal>
      )}
      {createReviewsState.isSuccess && (
        <TillrModal
          mediumWidth
          title="Sent to Reviewers"
          onOk={handleReturnToJourney}
          okButtonText="Return to your journey"
        >
          <p>
            Your application has been submitted to the following email addresses for review, and you
            will be notified of the outcome within 30 days:
          </p>
          <ul style={{ marginLeft: '16px' }}>
            {reviewerEmails.map((reviewerEmail, i) => (
              <li key={i}>{reviewerEmail}</li>
            ))}
          </ul>
        </TillrModal>
      )}
      <div className="textbox">
        <h4>Reviewer details</h4>
        {getFormState.loading || getReviewsState.isLoading ? (
          <ActivityIndicator showProgressBar />
        ) : (
          <>
            {getFormState.error && <ErrorDisplay error={getFormState.error} />}
            {getReviewsState.error && (
              <ErrorDisplay error={getErrorMessage(getReviewsState.error)} />
            )}
            {getReviewersState.error && <ErrorDisplay error={getReviewersState.error} />}
            {getReviewsState.data && getReviewsState.requestedDate && getReviewsState.expiryDate ? (
              <div>
                {!isReviewer && (
                  <p>
                    Your Assessment was sent to the following reviewers on{' '}
                    <span>{formatReviewDate(getReviewsState.requestedDate)}</span>.
                  </p>
                )}
                <ul className="mdl-list">
                  {getReviewsState.data.map((review, index) => (
                    <li key={index} className="mdl-list__item mdl-list__item--two-line">
                      <span className="mdl-list__item-primary-content">
                        <i className="material-icons mdl-list__item-avatar">person</i>
                        <span>
                          <span>{review.ReviewerEmail}</span>
                        </span>
                        <span className="mdl-list__item-sub-title">
                          Access expires:{' '}
                          <span>{formatReviewDate(getReviewsState.expiryDate)}</span>
                        </span>
                      </span>
                    </li>
                  ))}
                </ul>
                {getReviewsState.reviewStatus === 'PEND' && (
                  <CouncilControl
                    loading={getReviewersState.loading}
                    data={getReviewersState.data}
                    error={getReviewersState.error}
                    policeAuthority={formData?.[ASSESSMENT_REVIEWER_POLICE_AUTHORITY_FIELD_ID]}
                    localCouncil={formData?.[ASSESSMENT_REVIEWER_LOCAL_COUNCIL_FIELD_ID]}
                    onChangeCouncil={handleChangeCouncil}
                  />
                )}
              </div>
            ) : (
              <>
                <div>
                  <ul>
                    <li>
                      To finalise your Application you will be required to select the police force
                      and local authority / council that your premises sits within. In some areas
                      the local responsible authority will form part of the verification process and
                      be forwarded a copy of your application. Additionally the submission will be
                      sent to{' '}
                      <a href="mailto:accreditation@licensingsavi.com">
                        accreditation@licensingsavi.com
                      </a>
                    </li>
                    <li>
                      The police and local authority may contact you to discuss your responses prior
                      to verifying and confirming Accreditation. The Licensing SAVI team will verify
                      uploaded documents.
                    </li>
                    <li>
                      You will be copied into this email, which will include important information
                      about the next stage of your Accreditation application.
                    </li>
                  </ul>

                  <CouncilControl
                    loading={getReviewersState.loading}
                    data={getReviewersState.data}
                    error={getReviewersState.error}
                    policeAuthority={
                      previousFormData?.[ASSESSMENT_REVIEWER_POLICE_AUTHORITY_FIELD_ID]
                    }
                    localCouncil={previousFormData?.[ASSESSMENT_REVIEWER_LOCAL_COUNCIL_FIELD_ID]}
                    onChangeCouncil={handleChangeCouncil}
                  />
                </div>

                {!isReviewer && getReviewsState.data && (
                  <>
                    {getReviewsState.data.length === 0 && (
                      <div className="formly-field">
                        <div className="form-group">
                          <div className="checkbox">
                            <label htmlFor="confirm-checkbox" className="control-label">
                              <input
                                type="checkbox"
                                id="confirm-checkbox"
                                name="confirm-checkbox"
                                className="magic-checkbox"
                                required
                                checked={factsChecked}
                                onChange={handleCheckbox}
                              />
                              <label htmlFor="confirm-checkbox"></label>I believe that the facts I
                              have stated in this Licensing Security and Vulnerability Initiative
                              Self-Assessment are true.
                            </label>
                          </div>
                        </div>
                      </div>
                    )}
                  </>
                )}
              </>
            )}

            {getReviewsState.reviewStatus !== 'PENDING' && getReviewsState.data && (
              <div className="form__main-buttons">
                <Button
                  color="primary"
                  variant="contained"
                  disabled={
                    reviewerEmails.length === 0 ||
                    (!factsChecked && getReviewsState.data.length === 0)
                  }
                  onClick={handleSendForReview}
                >
                  {getReviewsState.data.length > 0 ? 'Resend to Reviewers' : 'Send to Reviewers'}
                </Button>
              </div>
            )}
          </>
        )}
      </div>
    </>
  );
}

