import { CircularProgress } from '@material-ui/core';
import React from 'react';
import ProgressBar from './Shared/ProgressBar';

interface IProps {
  size?: string | number;
  showProgressBar?: boolean;
}

export function ActivityIndicator(props: IProps) {
  const { size = '2rem', showProgressBar } = props;

  return (
    <>
      {showProgressBar && <ProgressBar />}
      <CircularProgress size={size} style={{ color: '#3ea0e2' }} />
    </>
  );
}
