import { UserManager, WebStorageStateStore } from 'oidc-client';
import { getOidcConfig } from './environment-utils';

function createUserManager() {
  const config = getOidcConfig();
  const userManager = new UserManager({
    authority: config.oidcProvider,
    client_id: config.clientId,
    redirect_uri: config.authRedirect,
    response_type: 'id_token token',
    scope: 'openid profile ui3 api api3',
    userStore: new WebStorageStateStore({ store: window.localStorage }),
    monitorSession: false, // not necessary, we control log out from the client app
    // Silent renew
    automaticSilentRenew: true,
    accessTokenExpiringNotificationTime: 60,
    silent_redirect_uri: config.silentRedirect,
  });
  userManager.events.addAccessTokenExpired(() => {
    userManager.removeUser();
  });
  return userManager;
}

export const userManager = createUserManager();
