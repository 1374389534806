import React, { PropsWithChildren } from 'react';
// This file is just to import styles in this order.
// Careful not to change the order eg with organizeImports
import './styles/vendor/index.scss';
import './styles/vendor/_bootstrap-form-grid.scss';
import './styles/sass/index.scss';

export function Styles(props: PropsWithChildren<{}>) {
  const { children } = props;

  return <>{children}</>;
}
