import { SignOutButton } from 'auth/SignOutButton';
import ErrorDisplay from 'components/ErrorDisplay';
import { ResourcesPanel } from 'components/Resources/ResourcesPanel';
import { TillrModal } from 'components/Shared/TillrModal';
import { UpdateMarketingPreferencesDialogContainer } from 'components/Shared/UpdateMarketingPreferencesDialogContainer';
import SiteContext from 'components/Sites/SiteContext';
import { isAfter } from 'date-fns';
import { useGetMyUserSettings } from 'hooks/useGetMyUserSettings';
import { useGetVenueStatuses } from 'hooks/useGetVenueStatuses';
import React, { useContext, useLayoutEffect } from 'react';
import { Redirect, useHistory, useLocation } from 'react-router';
import { Accreditation } from './Accreditation';
import { Advice } from './Advice';
import { Assessment } from './Assessment';
import { Checklist } from './Checklist';
import { Complete } from './Complete';
import { Registered } from './Registered';

export function JourneyPage() {
  const history = useHistory();

  const { siteId } = useContext(SiteContext)!;

  const { search } = useLocation();
  const paymentStatus = new URLSearchParams(search).get('payment');

  const { userSettings, isResponsibleAuthority } = useGetMyUserSettings();

  const getVenueStatusesState = useGetVenueStatuses(siteId);
  const venueStatus = getVenueStatusesState?.currentStatus;

  const shouldPay = !userSettings || (!userSettings.origin && !userSettings.paymentExempt);

  const firstPaymentIsRequired = shouldPay && !venueStatus?.PaymentDateTime;
  const canRenew = Boolean(
    venueStatus?.CanRenewDateTime && isAfter(new Date(), venueStatus.CanRenewDateTime),
  );
  const isRenewal = (getVenueStatusesState?.data?.length ?? 0) > 1;

  const assessmentStarted = Boolean(venueStatus?.AssessmentFormId);

  const percentageComplete = venueStatus?.PercentageComplete ?? 0;
  const disableAssessment =
    !venueStatus?.PremisesInfoFormId || !venueStatus?.EndUserAgreementFormId;
  const disableAdvice = disableAssessment || !assessmentStarted || percentageComplete < 100;
  const disableAccreditation = disableAdvice || venueStatus?.IsCritical;

  const disableComplete = !venueStatus?.AssessmentSubmittedDateTime;

  const handleScrollToTop = () => {
    window.scrollTo(0, 0);
  };

  useLayoutEffect(() => {
    window.scrollTo(0, 0);
    handleScrollToTop();
  }, []);

  if (isResponsibleAuthority) {
    return <Redirect to="/" />;
  }

  if (getVenueStatusesState.error) {
    return (
      <>
        <div className="page">
          <section>
            <ErrorDisplay error="An error occurred, please refresh. If the error persists, try contacting support." />
          </section>
        </div>
      </>
    );
  }

  const handleClosePaymentSuccess = () => {
    if (firstPaymentIsRequired || canRenew) {
      // Maybe we were too fast for the Reports service - refetch
      getVenueStatusesState.fetchData(siteId);
    }

    history.push('/journey');
  };

  return (
    <>
      {paymentStatus === 'success' && (
        <TillrModal title="Payment successful" onOk={handleClosePaymentSuccess}>
          {isRenewal ? (
            <>
              <p>However, you're not done yet!</p>
              <p>Please complete a new assessment so you can re-apply for accreditation.</p>
            </>
          ) : (
            <>
              <p>Thank you for your payment.</p>
              <p>You can now continue on your accreditation journey!</p>
            </>
          )}
        </TillrModal>
      )}

      <UpdateMarketingPreferencesDialogContainer />

      <div className="journey row">
        <div className="col-sm-8 col-xs-12">
          <section>
            <div className="wrapper" style={{ marginBottom: 0 }}>
              <h3>
                <span>
                  {venueStatus ? `${venueStatus.SiteName} - ` : 'Your'} accreditation journey
                </span>{' '}
                {}
                <SignOutButton />
              </h3>
            </div>

            <div className="wrapper">
              <div className="center-line">
                <a onClick={handleScrollToTop} className="scroll-icon">
                  <i className="glyphicon glyphicon-arrow-up"></i>
                </a>
              </div>

              <div className="steps step-1" id="step-1">
                <section>
                  <Registered />
                </section>
              </div>

              <div className="steps step-2" id="step-2">
                <section>
                  <i className="icon fas fa-home"></i>
                  <Checklist
                    isLoading={getVenueStatusesState.loading}
                    isPremisesInfoComplete={Boolean(venueStatus?.PremisesInfoFormId)}
                    isEndUserAgreementComplete={Boolean(venueStatus?.EndUserAgreementFormId)}
                  />
                </section>
              </div>

              <div className={`steps step-1 ${disableAssessment ? 'disabled' : ''}`} id="step3">
                <section>
                  <i className="icon fas fa-home"></i>
                  <Assessment
                    disabled={disableAssessment}
                    isLoading={getVenueStatusesState.loading}
                    requiresFirstPayment={firstPaymentIsRequired}
                    canRenew={canRenew}
                    assessmentStarted={assessmentStarted}
                    starRating={venueStatus?.StarRating ?? 0}
                    isCritical={Boolean(venueStatus?.IsCritical)}
                    percentageComplete={percentageComplete}
                    assessmentSubmitted={Boolean(venueStatus?.AssessmentSubmittedDateTime)}
                    isRenewal={isRenewal}
                  />
                </section>
              </div>

              <div className={`steps step-2 ${disableAdvice ? 'disabled' : ''}`} id="step-4">
                <section>
                  <i className="icon fas fa-home"></i>
                  <Advice
                    disabled={disableAdvice}
                    isLoading={getVenueStatusesState.loading}
                    assessmentStarted={assessmentStarted}
                    reviewStatus={venueStatus?.CurrentReviewStatus}
                  />
                </section>
              </div>

              <div className={`steps step-1 ${disableAccreditation ? 'disabled' : ''}`} id="step-5">
                <section>
                  <i className="icon fas fa-home"></i>
                  <Accreditation
                    disabled={disableAccreditation}
                    assessmentFormId={venueStatus?.AssessmentFormId}
                    isCritical={Boolean(venueStatus?.IsCritical)}
                    percentageComplete={percentageComplete}
                  />
                </section>
              </div>

              <div className={`steps step-2 ${disableComplete ? 'disabled' : ''}`} id="step-6">
                <section>
                  <i className="icon fas fa-home"></i>
                  <Complete
                    reviewStatus={venueStatus?.CurrentReviewStatus}
                    expiryDate={venueStatus?.AccreditationExpiryDateTime}
                  />
                </section>
              </div>
            </div>
          </section>
        </div>
        <div className="col-sm-4 col-xs-12">
          <section>
            <div className="wrapper">
              <h4 className="like-h3">Resources</h4>
              <ResourcesPanel />
            </div>
          </section>
        </div>
      </div>
    </>
  );
}

