import { ApolloError } from '@apollo/client';
import { Box, DialogActions, DialogContentText } from '@material-ui/core';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import ErrorDisplay from 'components/ErrorDisplay';
import { CancelButton } from 'components/Shared/CancelButton';
import { ProgressButton } from 'components/Shared/ProgressButton';
import React, { useState } from 'react';

interface IProps {
  siteName: string;
  onConfirm: () => void;
  loading?: boolean;
  error?: ApolloError;
}

export function RemoveVenue(props: IProps) {
  const { siteName, onConfirm, loading, error } = props;

  const [open, setOpen] = useState(false);

  const handleClick = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleConfirm = () => {
    onConfirm();
  };

  return (
    <>
      <Box style={{ color: 'red' }}>
        <Button color="inherit" onClick={handleClick} variant="outlined" disabled={loading}>
          Remove from chain
        </Button>
      </Box>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="form-dialog-title"
        fullWidth
        maxWidth="xs"
      >
        <DialogTitle id="form-dialog-title">Remove from chain</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Are you sure you want to remove "{siteName}" from this chain?
          </DialogContentText>
          {error && <ErrorDisplay error={error} />}
          <DialogActions>
            <CancelButton onClick={handleClose} />
            <ProgressButton onClick={handleConfirm} label="Yes, remove" loading={loading} />
          </DialogActions>
        </DialogContent>
      </Dialog>
    </>
  );
}
