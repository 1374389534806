import { DocumentModal } from 'components/Documents/DocumentModal';
import { useScrollToTopEffect } from 'hooks/useScrollToTopEffect';
import React from 'react';
import { useHistory } from 'react-router';

export function WelcomeLetterPage() {
  useScrollToTopEffect();

  const history = useHistory();
  const handleClose = () => {
    history.push('/');
  };

  return (
    <DocumentModal
      title="Important Information about your Licensing Security and Vulnerability Initiative licence"
      content={
        <>
          <p>
            Thank-you for purchasing Licensing SAVI, the self-assessment tool which will assist you
            in promoting the licensing objectives. Your licence will be valid for a period of twelve
            months from the date of accreditation and will be highlighted to you at that stage.
          </p>
          <p>
            Please read the following information carefully, as this contains everything you need to
            know about the Licensing SAVI assessment process.
          </p>
          <p>
            Once you have completed the assessment, should you choose to apply for accreditation,
            you will be provided additional information to guide you through the process.
          </p>
          <h4>Assessment key information:</h4>
          <ul>
            <li>
              The Licensing SAVI assessment will guide you through an exploration of your licensed
              premises’ existing policies, procedures and physical security by asking a series of
              in-depth multiple-choice questions. It will indicate areas where you are strong and
              where there may be room for improvement, it may even feature areas you have not
              previously considered.
            </li>
            <li>
              If one or more of your responses has indicated that you may be operating unlawfully a
              Critical Point is highlighted. This will need to be reviewed as soon as possible and
              rectified before a rating is displayed.
            </li>
            <li>
              A personalised report is generated upon completion of the assessment, including a
              final grading, given in the form of a rating from 1-5 Stars (with 5 Stars being the
              highest).
            </li>
            <li>
              Licensing SAVI will evaluate and grade your response to each question and generate
              advice as to how improvements could be made. If you choose to implement any
              enhancements, you can modify your responses and re-submit the assessment to improve
              your rating.
            </li>
            <li>
              When you are satisfied with the rating which you have achieved, you may apply for
              Accreditation. More information on this process can be found under the tab ‘applying
              for accreditation’.
            </li>
            <li>
              The personalised report provides clear recommendations and advice to assist you in
              promoting the licensing objectives, whilst keeping your staff and customers safe and
              secure. We urge you to read this report and consider how these recommendations can be
              utilised at your venue.
            </li>
            <li>
              The recommendations within the final report provide consistent guidance and benchmarks
              which are drawn from the Home Office, Police Service (including the National Counter
              Terrorism Security Office), Local Authorities, Fire and Rescue Service, Health and
              Safety Executive and the Security Industry Authority. The report includes several new
              and improved security standards, which have been developed to address emerging
              criminal activity, such as violent extremism.
            </li>
            <li>
              The information contained in your Self-Assessment remains confidential to you, unless
              you decide to apply for Accreditation or share it with third parties, such as
              Responsible Authorities or companies you commission to help you improve your business.
            </li>
          </ul>
          <p>
            Thank you
            <br />
            <strong>Licensing SAVI administration team</strong>
          </p>
        </>
      }
      onClose={handleClose}
    />
  );
}

