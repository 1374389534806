import { RecommendationMarkdown } from 'components/Shared/RecommendationMarkdown';
import React, { useState } from 'react';

interface IProps {
  adviceContents: string[];
}

export function AdviceBlock(props: IProps) {
  const { adviceContents } = props;

  const [readMore, setReadMore] = useState(false);

  return (
    <div className={`recommendation ${readMore ? 'recommendation-visible' : ''}`}>
      {adviceContents.map((answer: any, answerIndex: any) => (
        <div key={answerIndex}>
          <RecommendationMarkdown content={answer} />
        </div>
      ))}
      {readMore ? (
        <p className="read-less">
          <a
            href="#"
            onClick={(e) => {
              e.preventDefault();
              setReadMore(false);
            }}
          >
            Read Less
          </a>
        </p>
      ) : (
        <p className="read-more">
          <a
            href="#"
            onClick={(e) => {
              e.preventDefault();
              setReadMore(true);
            }}
          >
            Read More
          </a>
        </p>
      )}
    </div>
  );
}

