import React from 'react';

interface IProps {
  checked: boolean;
}

export function CheckedIcon(props: IProps) {
  const { checked } = props;

  return <i className={`glyphicon ${checked ? 'glyphicon-check' : 'glyphicon-unchecked'}`} />;
}

