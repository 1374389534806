import React from 'react';
import { SignInButton } from './SignInButton';

export function Unauthorised() {
  return (
    <div className="page">
      <section>
        <h3>Unauthorised</h3>
        <SignInButton />
      </section>
    </div>
  );
}
