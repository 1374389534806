import { Box, Chip, createStyles, List, ListItem, makeStyles, Theme } from '@material-ui/core';
import PersonIcon from '@material-ui/icons/Person';
import { UpdateUserSettings } from 'components/Shared/UpdateUserSettings';
import React from 'react';
import { PendingInvitationFragment, RegisteredUserFragment } from 'tillr-graphql';

interface IProps {
  users: Array<RegisteredUserFragment>;
  invitations: Array<PendingInvitationFragment>;
  manage?: boolean;
}

const styles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      marginLeft: theme.spacing(3),
      '& svg': {
        marginRight: theme.spacing(1),
      },
      '& li': {
        padding: 0,
      },
    },
  }),
);

export function Users(props: IProps) {
  const { users, invitations, manage } = props;

  if (users.length === 0 && invitations.length === 0) {
    return null;
  }

  const classes = styles();

  return (
    <List className={classes.root}>
      {users.map((user) => (
        <ListItem key={user.email}>
          <PersonIcon />
          {user.name} ({user.email})
          {manage && (
            <Box ml={1}>
              <UpdateUserSettings
                name={user.name}
                userId={user.id}
                customData={user.identity.customData}
              />
            </Box>
          )}
        </ListItem>
      ))}
      {invitations.map(({ email }) => (
        <ListItem key={email}>
          <PersonIcon />
          <Box mr={0.5}>
            <Chip color="secondary" size="small" label="Invitation pending" />
          </Box>
          ({email})
        </ListItem>
      ))}
    </List>
  );
}

