import { ApolloProvider } from '@apollo/client';
import { ThemeProvider } from '@material-ui/core';
import { User } from 'oidc-client';
import React from 'react';
import { Provider } from 'react-redux';
import Layout from './components/Layout/Layout';
import SiteManager from './components/Sites/SiteManager';
import createApolloClient from './createApolloClient';
import { userManager } from './createUserManager';
import { store } from './store';
import { Styles } from './Styles';
import defaultTheme from './themes/default';
import UserManagerContext from './UserManagerContext';
import UserProfile from './UserProfile';
import UserProfileContext from './UserProfileContext';

interface IState {
  loaded: boolean;
  userProfile: UserProfile | null;
}

export default class App extends React.Component<{}, IState> {
  constructor(props: {}) {
    super(props);

    this.state = { loaded: false, userProfile: null };
  }

  public componentDidMount() {
    userManager.events.addUserLoaded((user) => {
      this.handleUserChanged(user);
    });
    userManager.events.addUserUnloaded(() => {
      this.handleUserChanged(null);
    });

    userManager.getUser().then((user) => {
      const userProfile = user && !user.expired ? new UserProfile(user) : null;
      this.setState({ loaded: true, userProfile });
    });
  }

  private handleUserChanged = (user: User | null) => {
    if (!user) {
      this.setState({ userProfile: null });
    } else {
      this.setState((prevState) => {
        const userProfile = new UserProfile(user);
        return { ...prevState, userProfile };
      });
    }
  };

  public render() {
    const { loaded, userProfile } = this.state;
    if (!loaded) {
      return <p>Loading...</p>;
    }

    const apolloClient = createApolloClient(userProfile);

    return (
      <div className="content">
        <Styles>
          <ThemeProvider theme={defaultTheme}>
            {/* TODO: use global userManager instead of context */}
            <UserManagerContext.Provider value={userManager}>
              <UserProfileContext.Provider value={userProfile}>
                <Provider store={store}>
                  <ApolloProvider client={apolloClient}>
                    <SiteManager>
                      <Layout />
                    </SiteManager>
                  </ApolloProvider>
                </Provider>
              </UserProfileContext.Provider>
            </UserManagerContext.Provider>
          </ThemeProvider>
        </Styles>
      </div>
    );
  }
}

