import { Box, Button, Dialog, DialogContent, DialogTitle } from '@material-ui/core';
import { useUpdateUserCustomData } from 'apollo-hooks';
import ErrorDisplay from 'components/ErrorDisplay';
import { CancelButton } from 'components/Shared/CancelButton';
import { FormsButtons } from 'components/Shared/FormsButtons';
import { MarketingPreferencesControl } from 'components/Shared/MarketingPreferencesControl';
import { OriginControl } from 'components/Shared/OriginControl';
import { ProgressButton } from 'components/Shared/ProgressButton';
import React, { useContext, useState } from 'react';
import { UpdateUserCustomDataMutationVariables } from 'tillr-graphql';
import { MarketingPreferences, Origin, UserSettings } from 'types';
import UserProfileContext from 'UserProfileContext';

interface IProps {
  name: string;
  userId: string;
  customData: string | null | undefined;
}

export function UpdateUserSettings(props: IProps) {
  const { name, userId, customData } = props;

  const userProfle = useContext(UserProfileContext)!;
  const isAdmin = userProfle.hasAnyPermission(['Admin', 'Venues.Admin']);

  const [userSettings, setUserSettings] = useState<UserSettings>(
    customData ? JSON.parse(customData) : {},
  );
  const [open, setOpen] = useState(false);

  const [updateUserSettings, updateUserSettingsState] = useUpdateUserCustomData();

  if (!isAdmin) {
    return null;
  }

  const handleSubmit = () => {
    const variables: UpdateUserCustomDataMutationVariables = {
      user: {
        userId,
        customData: JSON.stringify(userSettings),
      },
    };
    updateUserSettings({ variables }).then(() => {
      updateUserSettingsState.reset();
      setOpen(false);
    });
  };

  const handleChangeMarketing = (value: MarketingPreferences) => {
    setUserSettings((prev) => ({ ...prev, marketing: value }));
  };

  const handleChangeOrigin = (value: Origin) => {
    setUserSettings((prev) => ({ ...prev, origin: value }));
  };

  return (
    <>
      <Button variant="outlined" size="small" onClick={() => setOpen(true)}>
        Update settings
      </Button>

      <Dialog open={open} onClose={() => setOpen(false)} fullWidth maxWidth="xs">
        <DialogTitle>Update settings for {name}</DialogTitle>
        <DialogContent>
          {updateUserSettingsState.error && <ErrorDisplay error={updateUserSettingsState.error} />}
          <Box mb={2}>
            <MarketingPreferencesControl
              labelText="Marketing preferences"
              value={userSettings.marketing}
              onChange={handleChangeMarketing}
            />
            <OriginControl value={userSettings.origin} onChange={handleChangeOrigin} />
          </Box>
          <FormsButtons>
            <CancelButton onClick={() => setOpen(false)} />
            <ProgressButton
              label="Update"
              loading={updateUserSettingsState.loading}
              onClick={handleSubmit}
            />
          </FormsButtons>
        </DialogContent>
      </Dialog>
    </>
  );
}
