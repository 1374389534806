import { ActivityIndicator } from 'components/ActivityIndicator';
import ErrorDisplay from 'components/ErrorDisplay';
import { CouncilData, ReviewersData } from 'hooks/useGetReviewersData';
import React, { ChangeEvent, useEffect, useState } from 'react';

export type CouncilControlValue = {
  policeAuthority: string;
  council: CouncilData;
};

interface IProps {
  loading?: boolean;
  data: ReviewersData | undefined;
  error?: string;
  policeAuthority?: string;
  localCouncil?: string;
  onChangeCouncil: (value: CouncilControlValue | undefined) => void;
}

export function CouncilControl(props: IProps) {
  const {
    loading,
    data,
    error,
    policeAuthority: initialPoliceAuthority = '',
    localCouncil: initialLocalCouncil = '',
    onChangeCouncil,
  } = props;

  const [policeAuthority, setPoliceAuthority] = useState(initialPoliceAuthority);
  const [localCouncil, setLocalCouncil] = useState(initialLocalCouncil);

  const handleChangePoliceAuthority = (event: ChangeEvent<{ value: unknown }>) => {
    setPoliceAuthority(event.currentTarget.value as string);
  };

  const handleChangeLocalCouncil = (event: ChangeEvent<{ value: unknown }>) => {
    setLocalCouncil(event.currentTarget.value as string);
  };

  useEffect(() => {
    if (data && policeAuthority && localCouncil) {
      const council = data[policeAuthority].councils.find((x) => x.name === localCouncil)!;
      onChangeCouncil?.({ policeAuthority, council });
    } else {
      onChangeCouncil?.(undefined);
    }
  }, [data, policeAuthority, localCouncil]);

  if (error) {
    return <ErrorDisplay error={error} />;
  }
  if (loading || !data) {
    return <ActivityIndicator showProgressBar />;
  }

  return (
    <div>
      <div className="formly-field">
        <div className="form-group form-group--row form-group--item">
          <div className="form-group__label">
            <label className="control-label" htmlFor="police-authorities">
              Select the Police Authority that your premises falls under
            </label>
          </div>
          <div className="form-group__input">
            <select
              required
              id="police-authorities"
              className="form-control"
              defaultValue={policeAuthority}
              onChange={handleChangePoliceAuthority}
            >
              <option value="">Select Police Authority</option>
              {Object.entries(data).map(([key]) => (
                <option key={key}>{key}</option>
              ))}
            </select>
          </div>
        </div>
      </div>

      <div className="formly-field">
        <div className="form-group form-group--row form-group--item">
          <div className="form-group__label">
            <label className="control-label" htmlFor="local-authorities">
              Select the Local Authority that your premises falls under
            </label>
          </div>
          <div className="form-group__input">
            <select
              required
              id="local-authorities"
              className="form-control"
              defaultValue={localCouncil}
              onChange={handleChangeLocalCouncil}
              disabled={!policeAuthority}
            >
              <option value="">Select Local Authority</option>
              {policeAuthority &&
                data[policeAuthority].councils.map((council) => (
                  <option key={council.name}>{council.name}</option>
                ))}
            </select>
          </div>
        </div>
      </div>
    </div>
  );
}

