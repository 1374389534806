import React, { MouseEventHandler, useContext } from 'react';
import UserManagerContext from 'UserManagerContext';

// This isn't good, but there is styling on ".dashboard section button" which is annoying
export function SignOutLink() {
  const userManager = useContext(UserManagerContext)!;

  const handleClick: MouseEventHandler<HTMLAnchorElement> = (e) => {
    e.preventDefault();
    userManager.signoutRedirect();
  };

  return (
    <a href="#" onClick={handleClick}>
      sign out
    </a>
  );
}

