import { useGetForm, useGetSite } from 'apollo-hooks';
import { MODULE_ID } from 'appGlobals';
import { ActivityIndicator } from 'components/ActivityIndicator';
import { AssessmentPrintView } from 'components/Assessment/AssessmentPrintView';
import ErrorDisplay from 'components/ErrorDisplay';
import { IFormlyConfig } from 'formly/IFormlyConfig';
import { IModel } from 'formly/IModel';
import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router';

export function AssessmentViewPrintPage() {
  const { siteId: siteIdParam, formId } = useParams<{ siteId: string; formId: string }>();

  const siteId = Number(siteIdParam);

  const getSiteState = useGetSite({ id: siteId });

  const getFormState = useGetForm({
    id: formId,
    siteId: Number(siteId),
    module: MODULE_ID,
  });

  const [formlyConfig, setFormlyConfig] = useState<IFormlyConfig>();
  const [formData, setFormData] = useState<IModel>();

  useEffect(() => {
    if (getFormState.data?.form?.formTemplate.template) {
      setFormData(JSON.parse(getFormState.data.form.formDataJson));
      setFormlyConfig(JSON.parse(getFormState.data.form.formTemplate.template));
    }
  }, [getFormState.data?.form]);

  if (getFormState.loading || getFormState.error || !formlyConfig || !formData) {
    return (
      <div className="page">
        <section>
          {getFormState.loading && (
            <div className="assessment-loader">
              <p>Retrieving the form...</p>

              <ActivityIndicator showProgressBar />
            </div>
          )}
          {getFormState.error && <ErrorDisplay error={getFormState.error} />}
        </section>
      </div>
    );
  }

  return (
    <AssessmentPrintView
      siteName={getSiteState.data?.site?.name}
      formlyConfig={formlyConfig}
      formData={formData}
    />
  );
}

