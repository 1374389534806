import { Box } from '@material-ui/core';
import { ActivityIndicator } from 'components/ActivityIndicator';
import React, { useContext, useEffect, useState } from 'react';
import UserManagerContext from 'UserManagerContext';

export function Logout() {
  const userManager = useContext(UserManagerContext)!;

  const [error, setError] = useState<string>();

  useEffect(() => {
    userManager.signoutRedirect().catch((e: Error) => {
      setError(`Error: ${e.message}. Please refresh to try again.`);
    });
  }, [userManager]);

  return (
    <div className="registration-was-modal">
      <div className="tillr-mdl-modal">
        <div className="tillr-mdl-modal__content">
          <div className="mdl-dialog">
            <div className="mdl-dialog__content">
              {error ? (
                <>
                  <h3>An error occurred</h3>
                  <p>{error}</p>
                </>
              ) : (
                <>
                  <h3>One moment please</h3>
                  <Box display="flex" flexDirection="row">
                    <p>Logging out...</p>
                    <Box ml={1}>
                      <ActivityIndicator size={16} showProgressBar />
                    </Box>
                  </Box>
                </>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
