import AccountTreeIcon from '@material-ui/icons/AccountTree';
import HomeIcon from '@material-ui/icons/Home';
import SupervisorAccountIcon from '@material-ui/icons/SupervisorAccount';
import React from 'react';
import { CHAIN_SITE_TYPE, GROUP_SITE_TYPE, SiteType, VENUE_SITE_TYPE } from 'types';

interface IProps {
  siteType: SiteType | null | undefined;
}

export function SiteIcon(props: IProps) {
  const { siteType } = props;

  if (!siteType) {
    return null;
  }

  switch (siteType) {
    case CHAIN_SITE_TYPE: {
      return <SupervisorAccountIcon />;
    }
    case GROUP_SITE_TYPE: {
      return <AccountTreeIcon />;
    }
    case VENUE_SITE_TYPE: {
      return <HomeIcon />;
    }
    default: {
      // @ts-ignore
      const exhaustiveCheck: never = siteType;
      throw new Error(`Unexpected siteType: "${siteType}".`);
    }
  }
}

