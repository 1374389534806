import { IModel } from 'formly/IModel';
import React from 'react';
import { getFormSection, getPercentageComplete } from 'utils';

interface IProps {
  position: 'top' | 'bottom';
  currentStep: number;
  steps: string[][];
  onChangeStepIndex: (stepIndex: number) => void;
  model: IModel | undefined;
}

export function FormProgress(props: IProps) {
  const { position, currentStep, steps, onChangeStepIndex, model } = props;

  const getStepClass = (index: number) => {
    const completePercentage = getPercentageComplete(model, steps, index);
    if (completePercentage === 100) {
      return 'completed';
    }
    if (completePercentage > 0) {
      return 'warning';
    }
  };

  return (
    <>
      <div className="form-progress">
        <h4>Your progress:</h4>
        <ul className="progress-indicator">
          {steps.map((_, index) => (
            <li
              key={index}
              className={`${getStepClass(index)} ${index === currentStep ? 'active' : ''}`}
            >
              <button
                type="button"
                className="link-button"
                onClick={() => onChangeStepIndex(index)}
                id={`progress-section-${position}-${index}"`}
              >
                <span className="bubble"></span>
                {getPercentageComplete(model, steps, index) === 100 ? (
                  <em>
                    <i className="glyphicon glyphicon-ok-sign"></i>
                  </em>
                ) : (
                  <em>{getPercentageComplete(model, steps, index)}%</em>
                )}{' '}
                <strong>Section {getFormSection(index)}</strong>
              </button>
            </li>
          ))}
        </ul>
      </div>
    </>
  );
}

