import { useGetSites } from 'apollo-hooks';
import { ActivityIndicator } from 'components/ActivityIndicator';
import ErrorDisplay from 'components/ErrorDisplay';
import React from 'react';
import { Link } from 'react-router-dom';
import { VENUE_SITE_TYPE } from 'types';
import { AdminPageTitle } from '../Admin/AdminPageTitle';

export function ManageVenuesPage() {
  const getSitesState = useGetSites({ parentSiteId: null, type: VENUE_SITE_TYPE });

  return (
    <>
      <AdminPageTitle title="Manage venues" />
      <div className="page">
        <section>
          {getSitesState.loading && <ActivityIndicator />}
          {getSitesState.error && <ErrorDisplay error={getSitesState.error} />}
          {getSitesState.data?.sites && (
            <ul>
              {getSitesState.data.sites
                .slice()
                .sort((a, b) => a.name.localeCompare(b.name))
                .map(({ id, name }) => (
                  <li key={id}>
                    <Link to={`/admin/venues/${id}`}>{name}</Link>
                  </li>
                ))}
            </ul>
          )}
        </section>
      </div>
    </>
  );
}

