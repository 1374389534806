import React from 'react';

interface IProps {
  value: string;
}

export default function HelpText(props: IProps) {
  const { value } = props;
  return <span className="help-block">{value}</span>;
}
