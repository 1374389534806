import { DocumentModal } from 'components/Documents/DocumentModal';
import { EndUserAgreementContent } from 'components/EndUserAgreement/EndUserAgreementContent';
import { useScrollToTopEffect } from 'hooks/useScrollToTopEffect';
import React from 'react';
import { useHistory } from 'react-router';

export function EndUserAgreementResourcePage() {
  useScrollToTopEffect();

  const history = useHistory();
  const handleClose = () => {
    history.push('/');
  };

  return (
    <DocumentModal
      title="End User Agreement"
      content={<EndUserAgreementContent />}
      onClose={handleClose}
    />
  );
}

