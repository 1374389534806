export const downloadFile = async (url: string, authHeader: string | undefined) => {
  const response = await fetch(url, {
    headers: { Authorization: authHeader ?? '' },
  });

  if (response.ok) {
    // Extract filename from Content-Disposition header
    const contentDisposition = response.headers.get('content-disposition');

    let filename = 'file';
    if (contentDisposition && contentDisposition.includes('filename=')) {
      filename = contentDisposition.split('filename=')[1].replace(/"/g, ''); // Remove quotes if present
    }

    // Download file
    const blob = await response.blob();
    const url = window.URL.createObjectURL(blob);
    const a = document.createElement('a');
    a.href = url;
    a.download = filename;
    document.body.appendChild(a);
    a.click();
    a.remove();
    window.URL.revokeObjectURL(url);
  } else {
    throw new Error('An error occurred.');
  }
};

