import { Box, Typography } from '@material-ui/core';
import OpenInNewIcon from '@material-ui/icons/OpenInNew';
import { format } from 'date-fns';
import { DataColumn, DataRow } from 'hooks/Reports/utils';
import MUIDataTable, { FilterType, MUIDataTableColumnDef } from 'mui-datatables';
import React from 'react';

interface IProps {
  reportName: string;
  columns: DataColumn[];
  data: DataRow[];
  initialFilterOn?: {
    column: string;
    value: string | number | boolean;
  };
}

export function TableView(props: IProps) {
  const { reportName, columns, data, initialFilterOn } = props;
  let filteredData = null;
  let filteredColumns = null;

  if (typeof initialFilterOn !== 'undefined') {
    // eslint-disable-next-line array-callback-return
    filteredData = data.filter((row) => {
      if (row[initialFilterOn.column] === initialFilterOn.value) {
        // eslint-disable-next-line no-param-reassign
        delete row[initialFilterOn.column];
        return row;
      }
    });
    filteredColumns = columns.filter((row) => row.name !== initialFilterOn.column);
  }

  const getFormatter = (type: string, name: string): any => {
    switch (type) {
      case 'boolean':
        return (value: boolean) => (
          <Box mr={2}>
            <Typography variant="body1" align="center">
              {value ? '✔' : '✖'}
            </Typography>
          </Box>
        );
      case 'date':
        return (value?: Date) => (value ? format(value, 'd MMM yy') : '');
      case 'datetime':
        return (value?: Date) => (value ? format(value, 'd MMM yy HH:mm:ss') : '');
      case 'text': {
        if (name.toLowerCase().endsWith('link')) {
          // Render URLs as anchor tags
          return (value: string) => {
            if (value.startsWith('https://') || value.startsWith('/')) {
              const url = value.startsWith('https://') ? value : window.location.origin + value;
              return (
                <a href={url} title="Opens in new tab" rel="noopener noreferrer" target="_blank">
                  <OpenInNewIcon />
                </a>
              );
            }
            return undefined;
          };
        }
        return undefined;
      }
      default: {
        return undefined;
      }
    }
  };

  const getFilterType = (type: string): FilterType => {
    switch (type) {
      case 'boolean':
        return 'checkbox';
      // case 'datetime': return 'custom';
      // case 'text': return 'textField';
      default:
        return 'multiselect';
    }
  };

  const getSearchable = (type: string) => {
    switch (type) {
      case 'boolean':
      case 'date':
      case 'datetime':
      case 'number':
        return false;
      default:
        return true;
    }
  };
  const cols = filteredColumns || columns;
  const muiColumns: MUIDataTableColumnDef[] = cols.map((column) => ({
    name: column.name,
    label: column.name,
    options: {
      customBodyRender: getFormatter(column.type, column.name),
      filter: true,
      filterType: getFilterType(column.type),
      searchable: getSearchable(column.type),
      sort: true,
    },
  }));

  return (
    <MUIDataTable
      title={reportName}
      data={filteredData || data}
      columns={muiColumns}
      options={{
        elevation: 0,
        selectableRows: 'none',
        rowsPerPage: 100,
      }}
    />
  );
}
