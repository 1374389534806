import { Tooltip } from '@material-ui/core';
import ErrorBoundary from 'components/ErrorBoundary';
import Footer from 'components/Footer/Footer';
import Header from 'components/Header/Header';
import OfflineMessage from 'components/Shared/OfflineMessage';
import { NetworkConfig } from 'NetworkStatus';
import NetworkStatusContext from 'NetworkStatusContext';
import React, { useState } from 'react';
import { useNetworkStatus } from 'react-network-status';
import { Link, useLocation } from 'react-router-dom';
import Routes from 'routes';

export default function Layout() {
  const [networkStatus, setNetworkStatus] = useState<boolean>(true);

  useNetworkStatus((networkStatusUpdate) => {
    setNetworkStatus(networkStatusUpdate);
  }, NetworkConfig);

  const { pathname } = useLocation();
  // Don't show button when it's a big modal screen as it obscures the buttons
  const showContactButton =
    !pathname.startsWith('/end-user-agreement') && !pathname.startsWith('/resources');

  return (
    <>
      <NetworkStatusContext.Provider value={networkStatus}>
        <OfflineMessage />
      </NetworkStatusContext.Provider>

      <Header />
      <main>
        <ErrorBoundary>
          <Routes />
        </ErrorBoundary>
      </main>

      {showContactButton && (
        <Link to="/contact">
          <Tooltip title="Contact support">
            <button
              id="contact-button"
              ui-sref="contact"
              className="contact-button mdl-button mdl-js-button mdl-button--fab mdl-button--colored-warn"
            >
              <i className="material-icons">message</i>
            </button>
          </Tooltip>
        </Link>
      )}

      <Footer />
    </>
  );
}

