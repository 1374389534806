import { format } from 'date-fns';
import React from 'react';

export function EndUserAgreementContent() {
  const today = format(new Date(), 'do MMMM yyyy');

  return (
    <>
      <p>
        This Access Licence & End User Agreement is made the <strong>{today}</strong>
      </p>
      <p>
        <strong>BETWEEN</strong>
      </p>
      <p>
        <strong>POLICE CRIME PREVENTION INITIATIVES LIMITED</strong> (Police CPI), registered number
        3816000, of 10 Victoria Street, London, SW1H 0NN, a company limited by guarantee, the owner
        and operator of <strong>Licensing Security &amp; Vulnerability Initiative</strong>,
      </p>
      <p>and</p>
      <p>
        the <strong>End User</strong>
      </p>
      <h4>AUTHORISATION</h4>
      <p>
        Police CPI hereby authorises the End User to complete and utilise the online assessment
        tool, Licensing SAVI, in relation to the licensed premises named above.
      </p>
      <ol className="lower-alpha">
        <li>
          This Access Licence, Accreditation and Star Rating solely relates to the use of Licensing
          SAVI for one premises and that premises is named on page one of this Access Licence. It is
          not capable of being transferred or assigned to any other person or premises.
        </li>
        <li>
          This Access Licence shall be for a period of one year from the date hereof, subject to
          earlier determination as hereinafter provided.
        </li>
        <li>
          The End User shall allow Police CPI, its servants, agents or otherwise, reasonable access
          during general business hours to any premises that have gained Accreditation under the
          Licensing SAVI Award for the purposes of ensuring the requirements of the Award have been,
          and continue to be met.
        </li>
        <li>
          This Access Licence, and any Accreditation awarded, may be revoked with immediate effect
          if the premises specified above ceases to meet the accreditation criteria.{' '}
        </li>
        <li>
          This Access Licence, and any Accreditation awarded, may be revoked with immediate effect
          if the End User is in breach of any material term hereof or becomes subject to any
          insolvency proceedings whatsoever, including administrative receivership, or if Police CPI
          reasonably believes that the End User is likely to become insolvent or if in the
          reasonable opinion of Police CPI the End User has or is likely to bring Police CPI or the
          UK police service into disrepute. Revocation of the Access Licence by Police CPI shall
          take effect on service of a written notice to that effect sent by Recorded Delivery post
          and served on the specified address of the End User, (or such other address as shall have
          been duly notified to Police CPI).
        </li>
        <li>
          The Licensing SAVI logo may only be used in accordance with the brand manual provided at
          the time of accreditation and any modifications of it notified to the End User and any
          other instructions and/or regulations from time to time issued by Police CPI during the
          period of this Licence.
        </li>
        <li>
          Police CPI reserves the right to withdraw this Access Licence, Accreditation and Star
          Rating following a valid request from any one of the Responsible Authorities.
        </li>
        <li>
          Police CPI reserves the right to withdraw this Access Licence, Accreditation and Star
          Rating without assigning any reason and in circumstances where the End User is not in
          breach hereof, in which case a proportion of the fee will be returned to the End User (to
          be apportioned on a time basis).
        </li>
        <li>
          All materials relating to Licensing SAVI, the Accreditation and the Star Rating remain the
          property of Police CPI.
        </li>
        <li>
          In the event of revocation of this Access Licence, the End User shall immediately cease to
          claim Accreditation and shall withdraw (at its own expense) all marketing and advertising
          material relating to the Accreditation, from the premises above.
        </li>
        <li>
          The End User agrees to indemnify and keep indemnified Police CPI against any claims
          arising in relation to the use of the Licensing SAVI name or advertising materials and /
          or Accreditation and / or Star Rating.{' '}
        </li>
        <li>
          The End User shall not use or register a name or trademark which is similar or the same as
          the style and title of Licensing SAVI or Police CPI and neither shall the End User
          challenge the validity of Police CPI’s ownership of the trademarks and other intellectual
          property rights. Furthermore, the End User will not commit any act of infringement or
          suffer or allow any act of infringement in relation to any intellectual property right in
          which Police CPI has any vested interest.
        </li>
        <li>
          Police CPI is subject to the Freedom of Information Act and information and correspondence
          not identified as exempt may be subject to disclosure in the event of a valid request
          under the Act.
        </li>
        <li>
          All discussions and negotiations between Police CPI and the End User are confidential and
          both parties for themselves, their servants, agents or otherwise expressly agree and
          undertake with one another not to divulge or suffer or allow to be divulged the amount or
          calculation of the Licensing SAVI licence fee or the terms of this licence or details of
          any discussions or negotiations or any information in relation to the other party, its
          officers, servants, agents or otherwise.
        </li>
        <li>
          The construction, validity and performance of this Access Licence shall be governed in all
          respects by the law of England & Wales; disputes arising out of Court will be subject to
          the jurisdiction of the Court of England & Wales.
        </li>
        <li>
          This Access Licence shall not constitute or imply any partnership, joint venture, agency,
          fiduciary relationship or other relationship between the parties other than the
          contractual relationship expressly provided for in this Agreement and neither party has,
          nor may it represent that it has, any authority to act or make any commitments on the
          other party’s behalf.
        </li>
        <li>
          This Access Licence and any other documents referred to, which are incorporated into and
          form part of this Access Licence, contain all the terms which the parties have agreed in
          relation to the subject matter of this Access Licence and those documents and supersede
          any prior written, representations or understandings between the parties. Nothing in this
          clause will exclude any liability which one party would otherwise have to the other party
          in respect of any statements made fraudulently.
        </li>
        <li>
          Neither party will be held liable for failure to fulfil its obligations under this
          Agreement if failure is caused by flood, extreme weather, fire or other natural calamity,
          acts of a governmental agency, acts of war or terrorism or similar causes beyond the
          control of such party.
        </li>
      </ol>
      <h4>DEFINITIONS</h4>
      <ol>
        <li>
          <strong>Police CPI</strong>: Refers to Police Crime Prevention Initiatives Limited, a
          not-for-profit company limited by guarantee. Company number 3816000, 10 Victoria Street,
          London, SW1H 0NN.
        </li>
        <li>
          <strong>Police service</strong>: Refers to all UK Police forces, including Wales, Scotland
          and Northern Ireland.
        </li>
        <li>
          <strong>End User</strong>: Refers to the person(s), organisation or group that has entered
          into this agreement with Police Crime Prevention Initiatives Limited.{' '}
        </li>
        <li>
          <strong>Agreement</strong>: Refers to this agreement
        </li>
        <li>
          <strong>Reasonable</strong>: Refers to terms that are just, fair, usual or proper.
        </li>
        <li>
          <strong>Intellectual property rights</strong>: Refer to all patents, copyrights, design
          rights, trade marks, service marks, trade secrets, know-how, database rights and other
          rights in the nature of intellectual property rights (whether registered or unregistered)
          and all applications for the same.
        </li>
      </ol>
    </>
  );
}

