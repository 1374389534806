import { ActivityIndicator } from 'components/ActivityIndicator';
import { CouncilControl } from 'components/Reviewers/CouncilControl';
import { format } from 'date-fns';
import { CouncilData, useGetReviewersData } from 'hooks/useGetReviewersData';
import React, { useState } from 'react';
import { AdminPageTitle } from '../AdminPageTitle';

export function VerifiersPage() {
  const { loading, data, error, lastModifiedDate } = useGetReviewersData();
  const [council, setCouncil] = useState<CouncilData>();

  return (
    <>
      <AdminPageTitle title="Current Verifiers List" />
      <div className="dashboard">
        <section className="verifiers-admin">
          {lastModifiedDate === undefined ? (
            <ActivityIndicator showProgressBar />
          ) : (
            <div>
              <p>
                List last updated on{' '}
                <strong>
                  {format(lastModifiedDate, 'do MMMM yyyy')} at{' '}
                  {format(lastModifiedDate, 'HH:mm:ss')}
                </strong>
                .
              </p>

              <div className="well">
                <h5>Users will be given the following options:</h5>

                <CouncilControl
                  loading={loading}
                  data={data}
                  error={error}
                  onChangeCouncil={(value) => setCouncil(value?.council)}
                />

                {council && (
                  <div>
                    <p>Will send emails to:</p>
                    <ul>
                      <li>
                        Police:{' '}
                        {council.policeAuthorityEmail.length > 0
                          ? council.policeAuthorityEmail
                          : 'accreditation@licensingsavi.com'}
                      </li>
                      <li>
                        Local Authority:{' '}
                        {council.localCouncilEmail.length > 0
                          ? council.localCouncilEmail
                          : 'accreditation@licensingsavi.com'}
                      </li>
                    </ul>
                  </div>
                )}
              </div>
            </div>
          )}
        </section>
      </div>
    </>
  );
}

