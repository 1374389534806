import Grid from '@material-ui/core/Grid';
import { IComponentProps } from 'formly/IComponentProps';
import withComponentPropsMemo from 'formly/withComponentPropsMemo';
import React from 'react';

function Copy(props: IComponentProps) {
  const { field } = props;
  return (
    <>
      {field.className?.match(/clear/) && (
        <hr className="tillr-form-grid-element tillr-form-grid-element--break" />
      )}
      <Grid
        item
        xs={12}
        sm={field.className?.match(/col-xs-6/) ? 6 : 12}
        className={
          field.className?.match(/pull-right/)
            ? 'tillr-form-grid-element tillr-form-grid-element--align-right'
            : 'tillr-form-grid-element'
        }
        style={{ paddingTop: 0, paddingBottom: 0 }}
      >
        <div className="formly-field formly-builder-element formly-field-copy">
          <div
            // eslint-disable-next-line react/no-danger
            dangerouslySetInnerHTML={{ __html: String(field.defaultValue!) }}
            className={field.data?.customClass}
          />
        </div>
      </Grid>
    </>
  );
}

export default withComponentPropsMemo(Copy);
