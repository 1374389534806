import { Box, Button } from '@material-ui/core';
import IconButton from '@material-ui/core/IconButton';
import Menu, { MenuProps } from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import { withStyles } from '@material-ui/core/styles';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import LocationOnIcon from '@material-ui/icons/LocationOn';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import React, { useContext, useState } from 'react';
import { Link } from 'react-router-dom';
import { SiteItemFragment } from 'tillr-graphql';
import { GROUP_SITE_TYPE, SiteType, VENUE_SITE_TYPE } from 'types';
import UserProfileContext from 'UserProfileContext';
import { AddSite } from './AddSite';
import { AddUserButton } from './AddUserButton';
import { AddVenueAdmin } from './AddVenueAdmin';
import { EditSiteName } from './EditSiteName';
import { MoveVenue } from './MoveVenue';

interface IProps {
  chainId: number;
  siteId: number;
  siteType: SiteType;
  sites: Array<SiteItemFragment>;
  refetch: () => void;
}

const StyledMenu = withStyles({
  paper: {
    border: '1px solid #d3d4d5',
  },
})((props: MenuProps) => (
  <Menu
    elevation={0}
    getContentAnchorEl={null}
    anchorOrigin={{
      vertical: 'bottom',
      horizontal: 'center',
    }}
    transformOrigin={{
      vertical: 'top',
      horizontal: 'center',
    }}
    {...props}
  />
));

export function SiteOptions(props: IProps) {
  const { chainId, siteId, siteType, sites, refetch } = props;

  const userProfle = useContext(UserProfileContext)!;
  const isAdmin = userProfle.hasAnyPermission(['Admin']);
  const isVenueAdmin = userProfle.hasAnyPermission(['Venues.Admin']);

  const site = sites.find((x) => x.id === siteId)!;

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

  if (isVenueAdmin) {
    if (site.type === VENUE_SITE_TYPE) {
      return (
        <Button
          startIcon={<LocationOnIcon />}
          variant="contained"
          color="secondary"
          component={Link}
          to={`/chains/${chainId}/venues/${site.id}/premises-info`}
        >
          Edit premises info
        </Button>
      );
    }
    return null;
  }

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.preventDefault();
    setAnchorEl(event.currentTarget);
  };

  const handleClose = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.preventDefault();
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const menuId = `site-options-${siteId}`;

  const options = [
    <EditSiteName onCloseMenu={handleClose} site={site} refetch={refetch} />,
    ...(site.type === VENUE_SITE_TYPE
      ? [
          <MoveVenue onCloseMenu={handleClose} site={site} sites={sites} refetch={refetch} />,
          isAdmin && (
            <Button component={Link} to={`/chains/${chainId}/venues/${site.id}/premises-info`}>
              Edit premises info
            </Button>
          ),
        ]
      : [
          <AddSite
            chainId={chainId}
            parentSiteId={siteId}
            siteType={GROUP_SITE_TYPE}
            onCloseMenu={handleClose}
          />,
          isAdmin ? (
            <AddVenueAdmin
              chainId={chainId}
              parentSiteId={siteId}
              onCloseMenu={handleClose}
              refetch={refetch}
            />
          ) : (
            <AddSite
              chainId={chainId}
              parentSiteId={siteId}
              siteType={VENUE_SITE_TYPE}
              onCloseMenu={handleClose}
            />
          ),
        ]),
    <AddUserButton
      chainId={chainId}
      siteId={siteId}
      siteType={siteType}
      onCloseMenu={handleClose}
    />,
  ];

  return (
    <>
      <Box display={{ xs: 'none', sm: 'block' }}>
        <Button
          id={`${menuId}-button`}
          aria-controls={open ? menuId : undefined}
          aria-haspopup="true"
          aria-expanded={open ? 'true' : undefined}
          variant="outlined"
          size="small"
          disableElevation
          onClick={handleClick}
          endIcon={<KeyboardArrowDownIcon />}
        >
          Actions
        </Button>
      </Box>
      <Box display={{ xs: 'block', sm: 'none' }}>
        <IconButton
          className="action-button"
          aria-label="options"
          size="small"
          aria-controls="site-options"
          aria-haspopup="true"
          onClick={handleClick}
        >
          <MoreVertIcon />
        </IconButton>
      </Box>

      <StyledMenu
        id="customized-menu"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
      >
        {options.map((option, index) => (
          <MenuItem key={index}>{option}</MenuItem>
        ))}
      </StyledMenu>
    </>
  );
}
