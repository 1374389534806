import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { userManager } from 'createUserManager';
import { getUrlPrefix } from 'environment-utils';
import { ContactSupportPayload } from './types';

const setDefaultHeaders = async (headers: Headers) => {
  const user = await userManager.getUser();
  if (user) {
    headers.set('Authorization', `${user.token_type} ${user.access_token}`);
  }
  headers.set('ClientId', 'lsavi.tillr.client');
  return headers;
};

export const emailServiceApi = createApi({
  reducerPath: 'emailServiceApi',
  baseQuery: fetchBaseQuery({
    baseUrl: `https://${getUrlPrefix()}emailservice.tillr.io`,
    prepareHeaders: setDefaultHeaders,
  }),
  endpoints: (builder) => ({
    contactSupport: builder.mutation<void, ContactSupportPayload>({
      query: (payload) => ({ url: 'ContactSupport', method: 'POST', body: payload }),
    }),
  }),
});

export const { useContactSupportMutation } = emailServiceApi;

