import { Box } from '@material-ui/core';
import PrintIcon from '@material-ui/icons/Print';
import { useGetForm, useGetSite } from 'apollo-hooks';
import { MODULE_ID } from 'appGlobals';
import { ActivityIndicator } from 'components/ActivityIndicator';
import ErrorDisplay from 'components/ErrorDisplay';
import LinkButton from 'components/Shared/LinkButton';
import { IFormlyConfig } from 'formly/IFormlyConfig';
import { IModel } from 'formly/IModel';
import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router';
import { AssessmentForm } from '../Assessment/AssessmentForm';

export function AssessmentViewPage() {
  const { siteId: siteIdParam, formId } = useParams<{ siteId: string; formId: string }>();

  const siteId = Number(siteIdParam);

  const getSiteState = useGetSite({ id: siteId });

  const getFormState = useGetForm({
    id: formId,
    siteId: Number(siteId),
    module: MODULE_ID,
  });

  const [formlyConfig, setFormlyConfig] = useState<IFormlyConfig>();
  const [formData, setFormData] = useState<IModel>();

  useEffect(() => {
    if (getFormState.data?.form?.formTemplate.template) {
      setFormData(JSON.parse(getFormState.data.form.formDataJson));
      setFormlyConfig(JSON.parse(getFormState.data.form.formTemplate.template));
    }
  }, [getFormState.data?.form]);

  return (
    <>
      <div className="assessment">
        <section>
          <Box display="flex" justifyContent="space-between">
            <h3>{getSiteState?.data?.site?.name ?? 'Assessment'}</h3>
            <Box>
              <LinkButton startIcon={<PrintIcon />} to={`/assessment/${siteId}/${formId}/print`}>
                Print view
              </LinkButton>
            </Box>
          </Box>

          {getFormState.loading && (
            <div className="assessment-loader">
              <p>Retrieving the form...</p>

              <ActivityIndicator showProgressBar />
            </div>
          )}

          {getFormState.error && <ErrorDisplay error={getFormState.error} />}

          {formlyConfig && getFormState.data?.form?.formTemplate.name && (
            <AssessmentForm
              formId={getFormState.data.form.id}
              formlyConfig={formlyConfig}
              model={formData}
              isSubmitted
              readonly
              templateName={getFormState.data?.form?.formTemplate.name}
            />
          )}
        </section>
      </div>
    </>
  );
}

