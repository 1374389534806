import { useCreateForm, useUpdateForm } from 'apollo-hooks';
import { COMMENTS_FORM_ID, MODULE_ID } from 'appGlobals';
import { ActivityIndicator } from 'components/ActivityIndicator';
import ErrorDisplay from 'components/ErrorDisplay';
import { TillrModal } from 'components/Shared/TillrModal';
import SiteContext from 'components/Sites/SiteContext';
import FormlyForm from 'formly/FormlyForm';
import { IModel } from 'formly/IModel';
import { useGetLatestFormAndTemplate } from 'hooks/useGetLatestFormAndTemplate';
import React, { useContext, useEffect } from 'react';
import { generateUuid } from 'utils';

interface IProps {
  isReviewer: boolean;
}

export function Comments(props: IProps) {
  const { isReviewer } = props;

  const { loading, templateName, formlyConfig, form, formData, error } =
    useGetLatestFormAndTemplate(COMMENTS_FORM_ID);

  const { siteId } = useContext(SiteContext)!;
  const module = MODULE_ID;

  const [createForm, createState] = useCreateForm();
  const [updateForm, updateState] = useUpdateForm({ siteId, module, id: form?.id });

  const mode = isReviewer ? 'readonly' : form ? 'manage' : 'create';

  const handleSubmit = (model: IModel) => {
    if (form) {
      updateForm({
        variables: {
          siteId,
          module,
          form: {
            id: form.id,
            name: templateName,
            formDataJson: JSON.stringify(model),
            isSubmitted: false,
          },
        },
      });
    } else {
      const nextFormId = generateUuid();
      createForm({
        variables: {
          siteId,
          module,
          form: {
            id: nextFormId,
            templateId: COMMENTS_FORM_ID,
            name: templateName,
            formDataJson: JSON.stringify(model),
            isSubmitted: false,
          },
        },
      });
    }
  };

  useEffect(() => {}, [createState.data, updateState.data]);

  const isSubmitting = createState.loading || updateState.loading;
  const isSaved = createState.data || updateState.data;

  const handleOk = () => {
    createState.reset();
    updateState.reset();
  };

  return (
    <>
      {isSaved && (
        <TillrModal title="Thank you" onOk={handleOk} okButtonText="Close">
          Your comments have been saved.
        </TillrModal>
      )}
      <div className="textbox">
        <h4>Additional comments</h4>
        {!isReviewer && (
          <p>
            Use the box below to add any further information or additional comments regarding your
            application.
          </p>
        )}
        {loading && <ActivityIndicator showProgressBar />}
        {error && <ErrorDisplay error={error} />}
        {formlyConfig && (
          <FormlyForm
            config={formlyConfig}
            model={formData}
            mode={mode}
            hideMandatoryFieldMessage
            redSaveButton
            redSaveButtonLabel="Save comments"
            onSubmit={handleSubmit}
            submitting={isSubmitting}
          />
        )}
      </div>
    </>
  );
}

