import { OperationVariables, QueryResult } from '@apollo/client';
import { Box, List, ListItem, Typography } from '@material-ui/core';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import TreeItem from '@material-ui/lab/TreeItem';
import { ExternalLink } from 'components/Shared/ExternalLink';
import { GetVenueStatusesState } from 'hooks/useGetVenueStatuses';
import React from 'react';
import { GetUsersAdminQuery } from 'tillr-graphql';
import { GROUP_SITE_TYPE, SiteType, VENUE_SITE_TYPE } from 'types';
import { SiteIcon } from './SiteIcon';
import siteStyles from './siteStyles';
import { Users } from './Users';
import { getStatusSummary } from './utils';
import { VenueTrafficLights } from './VenueTrafficLights';

interface IProps {
  chainId: number;
  siteId: number;
  getUsersState: QueryResult<GetUsersAdminQuery, OperationVariables>;
  getVenueStatusesState: GetVenueStatusesState;
  bgColor?: string;
  color?: string;
  name: string;
  siteType: SiteType;
  labelInfo?: string;
  labelText: string;
  children: React.ReactNode;
}

export function SummarySiteTree(props: IProps) {
  const {
    chainId,
    siteId,
    getUsersState,
    getVenueStatusesState,
    name,
    siteType,
    labelText,
    labelInfo,
    color,
    bgColor,
    ...other
  } = props;

  const classes = siteStyles();

  const users =
    getUsersState?.data?.usersAdmin?.filter((x) => x.sites.some((s) => s.id === siteId)) ?? [];
  const invitations =
    getUsersState?.data?.pendingInvitations?.filter((x) => x.sites.some((s) => s.id === siteId)) ??
    [];

  const statuses =
    siteType === VENUE_SITE_TYPE ? getVenueStatusesState.getStatusesOfSite(siteId) : [];

  return (
    <TreeItem
      nodeId={siteId.toString()}
      label={
        <>
          <div className={classes.labelRoot}>
            <div className={classes.labelName}>
              <Box mr={1}>
                <SiteIcon siteType={siteType} />
              </Box>
              <Typography variant="body2" className={classes.labelText}>
                {labelText}
              </Typography>
            </div>
          </div>
          <Users users={users} invitations={invitations} />
          {siteType === VENUE_SITE_TYPE && (
            <Box ml={3}>
              <VenueTrafficLights
                loading={getVenueStatusesState.loading}
                error={getVenueStatusesState.error !== null}
                data={statuses?.[statuses.length - 1]}
                showDetail
              />
              {statuses?.some((x) => x.AssessmentFormId) && (
                <>
                  <Typography>Assessment history:</Typography>
                  <List>
                    {statuses.map((status, index) => (
                      <ListItem key={index} style={{ marginBottom: 0, padding: 0 }}>
                        {status.AssessmentFormId && (
                          <ExternalLink href={`/assessment/${siteId}/${status.AssessmentFormId}`}>
                            {getStatusSummary(status)}
                          </ExternalLink>
                        )}
                      </ListItem>
                    ))}
                  </List>
                </>
              )}
            </Box>
          )}
        </>
      }
      icon={siteType === GROUP_SITE_TYPE && !other.children && <ExpandMoreIcon />}
      classes={{
        root: classes.root,
        content: classes.content,
        expanded: classes.expanded,
        selected: classes.selected,
        group: classes.group,
        label: classes.label,
        iconContainer: siteType === VENUE_SITE_TYPE ? classes.venueIconContainer : undefined,
      }}
      {...other}
    />
  );
}

