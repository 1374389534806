import { IFormlyConfig } from './IFormlyConfig';

export default function getStaticTemplate(type: string): IFormlyConfig {
  switch (type) {
    case 'create-chain':
      return {
        fields: [
          {
            id: 'name',
            type: 'input',
            templateOptions: {
              label: 'Chain name',
              required: true,
            },
            key: 'name',
            className: 'formly-builder-element',
          },
          {
            id: 'email',
            type: 'input',
            templateOptions: {
              label: 'Primary email address',
              required: true,
            },
            key: 'email',
            className: 'formly-builder-element',
          },
        ],
      };
    case 'create-site-group':
      return {
        fields: [
          {
            id: 'name',
            type: 'input',
            key: 'name',
            templateOptions: {
              required: true,
              label: 'Group name',
            },
          },
        ],
      };
    case 'create-site-venue':
      return {
        fields: [
          {
            id: 'name',
            type: 'input',
            key: 'name',
            templateOptions: {
              required: true,
              label: 'Venue name',
            },
          },
        ],
      };
    case 'edit-site-name':
      return {
        fields: [
          {
            id: 'name',
            type: 'input',
            key: 'name',
            templateOptions: {
              required: true,
              label: 'Name',
            },
          },
        ],
      };
    default:
      return { fields: [] };
  }
}

