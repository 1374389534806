import { useGetFormTemplate, useGetFormTemplateAndInstances } from 'apollo-hooks';
import { MODULE_ID } from 'appGlobals';
import SiteContext from 'components/Sites/SiteContext';
import { IFormlyConfig } from 'formly/IFormlyConfig';
import { IModel } from 'formly/IModel';
import { useContext, useEffect, useState } from 'react';
import { FormOrderBy, FormType, PaginationOrder } from 'tillr-graphql';

export function useGetLatestFormAndTemplate(
  formTemplateId: string,
  options?: Partial<{ isNewInstance: boolean; includePrevious: boolean; skip: boolean }>,
) {
  const { isNewInstance, includePrevious, skip } = options ?? {
    isNewInstance: false,
    includePrevious: false,
    skip: false,
  };

  const { siteId } = useContext(SiteContext)!;
  const module = MODULE_ID;

  const getFormTemplateState = useGetFormTemplateAndInstances(
    {
      siteId,
      module,
      formTemplateId,
      paginationProps: {
        pageSize: 999,
        orderBy: FormOrderBy.CreatedDateTime,
        order: PaginationOrder.Desc,
      },
    },
    skip,
  );

  const [previousFormTemplateId, setPreviousFormTemplateId] = useState<string>();
  const getPreviousFormTemplateState = useGetFormTemplate({
    id: previousFormTemplateId,
    siteId,
    module,
  });

  const [formlyConfig, setFormlyConfig] = useState<IFormlyConfig>();
  const [form, setForm] = useState<Partial<FormType>>();
  const [formData, setFormData] = useState<IModel>();
  const [previousFormData, setPreviousFormData] = useState<IModel>();

  useEffect(() => {
    // This isn't expected to happen, but guard against it anyway.
    if (!getFormTemplateState.data?.formTemplate?.template) {
      return;
    }

    const mostRecentForm = getFormTemplateState.data?.forms?.items[0];

    if (!mostRecentForm || isNewInstance) {
      setFormlyConfig(JSON.parse(getFormTemplateState.data.formTemplate.template));
    } else {
      if (mostRecentForm) {
        setForm(mostRecentForm);
        setFormData(JSON.parse(mostRecentForm.formDataJson));

        if (mostRecentForm.templateId === formTemplateId) {
          setFormlyConfig(JSON.parse(getFormTemplateState.data.formTemplate.template));
        } else {
          // When most recent form isn't on latest template, load previous template too
          setPreviousFormTemplateId(mostRecentForm.templateId);
        }
      }

      if (includePrevious) {
        const previousForm = getFormTemplateState.data?.forms?.items[1];
        if (previousForm) {
          setPreviousFormData(JSON.parse(previousForm.formDataJson));
        }
      }
    }

    // This only happens when most recent form isn't on latest template
    if (getPreviousFormTemplateState.data?.formTemplate?.template) {
      setFormlyConfig(JSON.parse(getPreviousFormTemplateState.data.formTemplate.template));
    }
  }, [
    getFormTemplateState.data,
    getPreviousFormTemplateState.data,
    isNewInstance,
    includePrevious,
  ]);

  return {
    loading: getFormTemplateState.loading || getPreviousFormTemplateState.loading,
    templateName:
      getPreviousFormTemplateState.data?.formTemplate?.name ??
      getFormTemplateState.data?.formTemplate?.name,
    formlyConfig,
    form,
    formData,
    previousFormData,
    error: getFormTemplateState.error ?? getPreviousFormTemplateState.error,
    refetch: () => getFormTemplateState.refetch(),
  };
}

