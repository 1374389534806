import { Box } from '@material-ui/core';
import { ActivityIndicator } from 'components/ActivityIndicator';
import ErrorDisplay from 'components/ErrorDisplay';
import FormlyForm from 'formly/FormlyForm';
import { IFormlyConfig } from 'formly/IFormlyConfig';
import { IModel } from 'formly/IModel';
import { ReportIdType, useRunReport } from 'hooks/Reports/useRunReport';
import { DataColumn } from 'hooks/Reports/utils';
import React from 'react';
import { IReportConfig } from './IReportConfig';
import { ReportResultsViewer } from './ReportResultsViewer';

interface IProps {
  reportId: string;
  reportConfig: IReportConfig;
  queryDefinition: {
    inputParameters: DataColumn[];
    resultColumns: DataColumn[];
  };
}

export function ReportControl(props: IProps) {
  const {
    reportId,
    reportConfig,
    queryDefinition: { inputParameters: inputParametersDefinition, resultColumns },
  } = props;

  const {
    runReport,
    runReportState: { loading, data, error },
  } = useRunReport();
  const getInputParameterLabel = (value: string) => {
    if (value.startsWith('@p_')) {
      return value.replace('@p_', '').replace('_', ' ');
    }
    return value.replace('@', '').replace(/(\B[A-Z]+?(?=[A-Z][^A-Z])|\B[A-Z]+?(?=[^A-Z]))/g, ' $1');
  };

  const getInputParameterDefaultValue = (type: string, name: string) => {
    switch (type) {
      case 'datepicker': {
        const date = new Date();
        if (name.startsWith('@From') || name.startsWith('@p_From')) {
          date.setMonth(date.getMonth() - 3);
        }
        return date.toDateString();
      }
      default:
        return '';
    }
  };

  const initialModel = Object.fromEntries(
    inputParametersDefinition.map((x) => [x.name, getInputParameterDefaultValue(x.type, x.name)]),
  );

  if (!loading && !error && data == null) {
    runReport(reportId, ReportIdType.Report, initialModel);
  }

  const formlyConfig: IFormlyConfig = {
    settings: {
      manage: {
        save: 'Run',
      },
    },
    fields: inputParametersDefinition.map(({ name, type }) => {
      const options = reportConfig.inputOptions
        ? reportConfig.inputOptions[name]?.map((option) => ({ name: option, value: option }))
        : undefined;
      return {
        key: name,
        type: options ? 'tlrSelect' : type,
        templateOptions: {
          label: getInputParameterLabel(name),
          required: true,
          options,
        },
        className: 'col-xs-6',
      };
    }),
  };

  const handleSubmitInputParameters = (model: IModel) => {
    const newParams: IModel = {};
    inputParametersDefinition.forEach((x) => {
      switch (x.type) {
        case 'datepicker':
          newParams[x.name] = new Date(model[x.name]).toDateString();
          break;
        default:
          newParams[x.name] = model[x.name];
      }
    });
    runReport(reportId, ReportIdType.Report, newParams);
  };

  return (
    <>
      {inputParametersDefinition.length > 0 && (
        <Box marginY={2}>
          <FormlyForm
            config={formlyConfig}
            model={initialModel}
            onSubmit={handleSubmitInputParameters}
            submitting={loading}
            hideMandatoryFieldMessage
            redSaveButton
            redSaveButtonLabel="Run"
            ignoreDirty
          />
        </Box>
      )}
      {loading && <ActivityIndicator />}
      {error && <ErrorDisplay errorMessage={error} />}
      {data && (
        <ReportResultsViewer
          reportConfig={reportConfig}
          data={data}
          resultColumns={resultColumns}
        />
      )}
    </>
  );
}
