import Grid from '@material-ui/core/Grid';
import TextField from '@material-ui/core/TextField';
import { IComponentProps } from 'formly/IComponentProps';
import withComponentPropsMemo from 'formly/withComponentPropsMemo';
import React, { useEffect } from 'react';
import HelpText from './shared/HelpText';
import { FieldValidationMessage, getRuleMessage } from './shared/Validation';

function Number(props: IComponentProps) {
  const {
    field,
    readonly,
    modelValue,
    onValueUpdate,
    register,
    errors,
    setValue,
    clearErrors,
    setError,
    getValues,
  } = props;

  const customTrigger = () => {
    const values = getValues();
    if (field.templateOptions?.required && field.key && !values[field.key]?.length) {
      setError(field.key, {
        type: 'manual',
        message: getRuleMessage('required'),
      });
      return;
    }
    if (field.key && values[field.key]?.length) {
      if (
        field.data?.minValue &&
        parseInt(values[field.key], 10) < parseInt(field.data?.minValue, 10)
      ) {
        setError(field.key, {
          type: 'manual',
          message: `You must enter a minimum value of ${field.data.minValue}`,
        });
        return;
      }
      if (
        field.data?.maxValue &&
        parseInt(values[field.key], 10) > parseInt(field.data?.maxValue, 10)
      ) {
        setError(field.key, {
          type: 'manual',
          message: `You must enter a maximum value of ${field.data.maxValue}`,
        });
        return;
      }
      clearErrors(field.key);
    }
    clearErrors(field.key);
  };

  useEffect(() => {
    register({ name: field.key });
    setValue(field.key, modelValue != null ? modelValue.toString() : '');
    customTrigger();
    // TODO: If I remove the empty array, useEffect keeps getting called. Isn't the
    // idea that an empty array will only call useEffect once?
    // eslint-disable-next-line
  }, []);

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (field.key) {
      onValueUpdate(field.key, event.currentTarget.value);
      setValue(field.key, event.currentTarget.value);
      customTrigger();
    }
  };

  const inputProps: { min?: string; max?: string } = {};

  if (field.data?.minValue) {
    inputProps.min = field.data.minValue;
  }
  if (field.data?.maxValue) {
    inputProps.max = field.data.maxValue;
  }

  return (
    <>
      {field.className?.match(/clear/) && (
        <hr className="tillr-form-grid-element tillr-form-grid-element--break" />
      )}
      <Grid
        item
        xs={12}
        sm={field.className?.match(/col-xs-6/) ? 6 : 12}
        className={
          field.className?.match(/pull-right/)
            ? 'tillr-form-grid-element tillr-form-grid-element--align-right'
            : 'tillr-form-grid-element'
        }
      >
        <TextField
          id={field.key}
          name={field.key}
          type="number"
          value={modelValue != null ? modelValue.toString() : ''}
          label={field.templateOptions?.label}
          variant="outlined"
          placeholder={field.templateOptions?.placeholder}
          onChange={handleChange}
          disabled={readonly || field.templateOptions?.disabled}
          inputProps={inputProps}
          InputLabelProps={{
            shrink: !!(
              modelValue != null ||
              field.templateOptions?.placeholder?.length ||
              field.defaultValue?.length
            ),
          }}
          required={!readonly && field.templateOptions?.required}
          fullWidth
          error={!!errors[field.key!]}
        />
        {errors[field.key!] && <FieldValidationMessage message={errors[field.key!].message} />}
        {field.data?.help && <HelpText value={field.data?.help} />}
      </Grid>
    </>
  );
}

export default withComponentPropsMemo(Number);

