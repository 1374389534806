import React, { ReactNode } from 'react';

interface IProps {
  title: string;
  content: ReactNode;
  actions?: ReactNode;
  onClose?: () => void;
}

export function DocumentModal(props: IProps) {
  const { title, content, actions, onClose } = props;

  return (
    <div id="tillr-mdl-modal" className="tillr-mdl-modal tillr-mdl-modal--large document-modal">
      <div className="tillr-mdl-modal__content">
        <div className="mdl-dialog">
          <h3 className="mdl-dialog__title">{title}</h3>
          <div className="mdl-dialog__content">{content}</div>
          {actions ?? (
            <div className="confirmation">
              <div className="buttons">
                <button
                  id="submit-button"
                  className="mdl-button mdl-js-button mdl-button--raised mdl-js-ripple-effect tlr-btn-submit"
                  type="button"
                  onClick={() => (onClose ? onClose() : undefined)}
                >
                  close
                </button>
              </div>
            </div>
          )}
        </div>
      </div>
      <div className="tillr-mdl-modal__backdrop"></div>
    </div>
  );
}

