import React from 'react';

interface IProps {
  value: number;
}

export function PercentageCompleteDisplay(props: IProps) {
  const { value } = props;

  return (
    <div className="svg-item">
      <svg width="75%" height="75%" viewBox="0 0 40 40" className="donut">
        <circle className="donut-hole" cx="20" cy="20" r="15.91549430918954" fill="#fff"></circle>
        <circle
          className="donut-ring"
          cx="20"
          cy="20"
          r="15.91549430918954"
          fill="transparent"
          strokeWidth="3.5"
        ></circle>
        <circle
          className="donut-segment donut-segment"
          cx="20"
          cy="20"
          r="15.91549430918954"
          fill="transparent"
          strokeWidth="3.5"
          strokeDasharray={`${value} ${100 - value}`}
          strokeDashoffset="25"
        ></circle>
        <g className="donut-text donut-text">
          <text y="50%" transform="translate(0, 2)">
            <tspan x="50%" textAnchor="middle" className="donut-percent">
              {value}%
            </tspan>
          </text>
          <text y="60%" transform="translate(0, 2)">
            <tspan x="50%" textAnchor="middle" className="donut-data">
              saved progress
            </tspan>
          </text>
        </g>
      </svg>
    </div>
  );
}

