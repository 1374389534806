import { ReturnLink } from 'components/Shared/ReturnLink';
import React, { useContext } from 'react';
import UserProfileContext from 'UserProfileContext';
import { Comments } from './Comments';
import { Reviewers } from './Reviewers';

export function AccreditationPage() {
  const userProfile = useContext(UserProfileContext)!;
  const isReviewer = userProfile.hasAnyPermission(['Reviewer', 'Admin']);

  return (
    <>
      <div className="accreditation">
        <section>
          <h3>
            {isReviewer ? 'Reviewers and Supporting Evidence' : 'Applying for Accreditation'}{' '}
            <ReturnLink />
          </h3>
          <div>
            <div className="alert alert--info alert--info-small">
              <ul>
                <li>
                  You can apply <strong>once</strong> within the licence period, for the Licensing
                  SAVI Accreditation. As such, we recommend that you do not apply for Accreditation
                  until you are satisfied you have achieved the maximum rating possible.
                </li>
                <li>
                  Once you have applied for Accreditation, and it has been approved and awarded,
                  your assessment responses cannot be amended for the duration of that Licensing
                  SAVI licence period. This is why we recommend that you do not apply for
                  Accreditation until you are satisfied you have achieved the maximum rating
                  possible.
                </li>
                <li>
                  You do not need to apply for Accreditation, but the annual licence fee to use
                  Licensing SAVI includes the cost of an application for Accreditation.
                </li>
                <li>
                  The Accreditation will be valid for twelve months following the date of
                  accreditation which will be notified to you.
                </li>
              </ul>
            </div>
          </div>

          {/* <Evidence /> */}
          <Comments isReviewer={isReviewer} />
          <Reviewers isReviewer={isReviewer} />
        </section>
      </div>
    </>
  );
}

