import { Box, List, ListItem, Tooltip, Typography } from '@material-ui/core';
import ErrorIcon from '@material-ui/icons/Error';
import HelpIcon from '@material-ui/icons/Help';
import { ActivityIndicator } from 'components/ActivityIndicator';
import { IVenueStatusData } from 'hooks/useGetVenueStatuses';
import React, { Fragment } from 'react';
import { getHtmlColor, getTrafficLights } from './utils';

interface IProps {
  loading: boolean;
  error: boolean;
  data: IVenueStatusData | undefined;
  showDetail?: boolean;
}

export function VenueTrafficLights(props: IProps) {
  const { loading, error, data, showDetail } = props;

  if (loading) {
    return <ActivityIndicator size={16} />;
  }
  if (error) {
    return (
      <Tooltip title="An error occurred. Try reloading the page.">
        <ErrorIcon color="disabled" />
      </Tooltip>
    );
  }

  if (data === undefined) {
    return (
      <Tooltip title="Venue status not found.">
        <HelpIcon color="disabled" />
      </Tooltip>
    );
  }

  const trafficLights = getTrafficLights(data);

  return (
    <>
      <Box display="flex" flexDirection="row" alignItems="center">
        {showDetail && (
          <Box mr={1}>
            <Typography>Current status:</Typography>
          </Box>
        )}
        {trafficLights.map(({ summaries, status }, index) => (
          <Box key={index} color={getHtmlColor(status)} mr={0.5}>
            {summaries.length > 0 ? (
              <Tooltip
                color={getHtmlColor(status)}
                title={<div style={{ whiteSpace: 'pre-line' }}>{summaries.join('\n')}</div>}
              >
                <span>⬤</span>
              </Tooltip>
            ) : (
              <span>⬤</span>
            )}
          </Box>
        ))}
      </Box>
      {showDetail && (
        <List>
          {trafficLights.map(({ summaries }, trafficLightIndex) => (
            <Fragment key={trafficLightIndex}>
              {summaries.map((summary, summaryIndex) => (
                <ListItem key={summaryIndex} style={{ marginBottom: 0, padding: 0 }}>
                  {summary}
                </ListItem>
              ))}
            </Fragment>
          ))}
        </List>
      )}
    </>
  );
}

