import { useGetMyCustomData } from 'apollo-hooks';
import { useEffect, useState } from 'react';
import { UserSettings } from 'types';

export function useGetMyUserSettings(skip?: boolean) {
  const getMyCustomDataState = useGetMyCustomData(skip);

  const [userSettings, setUserSettings] = useState<UserSettings>();
  const [isResponsibleAuthority, setIsResponsibleAuthority] = useState<boolean>();

  useEffect(() => {
    if (getMyCustomDataState.data) {
      const customData = getMyCustomDataState.data.myIdentity?.customData;
      if (customData) {
        const settings = JSON.parse(customData) as UserSettings;
        setUserSettings(settings);
        setIsResponsibleAuthority(settings.origin?.toLowerCase() === 'ra');
      } else {
        setUserSettings({});
        setIsResponsibleAuthority(false);
      }
    }
  }, [getMyCustomDataState]);

  return {
    ...getMyCustomDataState,
    userSettings,
    isResponsibleAuthority,
  };
}

