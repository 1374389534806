import { Dialog } from '@material-ui/core';
import React, { PropsWithChildren } from 'react';

interface IProps {
  mediumWidth?: boolean;
  title: string;
  okButtonText?: string;
  okDisabled?: boolean;
  onOk: () => void;
  cancelButtonText?: string;
  onCancel?: () => void;
  disabled?: boolean;
}

export function TillrModal(props: PropsWithChildren<IProps>) {
  const {
    mediumWidth,
    title,
    okButtonText = 'OK',
    okDisabled,
    onOk,
    cancelButtonText = 'Cancel',
    onCancel,
    disabled,
    children,
  } = props;

  return (
    <Dialog open className={`tillr-mdl-modal ${mediumWidth ? 'tillr-mdl-modal--medium' : ''}`}>
      <div className="tillr-mdl-modal__content">
        <div className="mdl-dialog">
          <h3 className="mdl-dialog__title">{title}</h3>
          <div className="mdl-dialog__content">{children}</div>
          <div className="mdl-dialog__actions">
            <button
              type="button"
              disabled={disabled || okDisabled}
              className="mdl-button mdl-js-button mdl-js-ripple-effect"
              onClick={onOk}
            >
              {okButtonText}
            </button>
            {onCancel && (
              <button
                type="button"
                disabled={disabled}
                className={`mdl-button mdl-js-button mdl-js-ripple-effect ${
                  okDisabled === undefined ? 'mdl-button--colored-minor' : ''
                }`}
                onClick={onCancel}
              >
                {cancelButtonText}
              </button>
            )}
          </div>
        </div>
      </div>
    </Dialog>
  );
}

