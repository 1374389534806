import { Button, Tooltip } from '@material-ui/core';
import React from 'react';
import { Link } from 'react-router-dom';

interface IProps {
  to: string;
  color?: 'inherit' | 'primary' | 'secondary' | 'default' | undefined;
  'aria-label'?: string;
  startIcon?: React.ReactNode;
  tooltip?: string;
}

export default function LinkButton(props: React.PropsWithChildren<IProps>) {
  const { to, color = 'secondary', 'aria-label': ariaLabel, startIcon, tooltip, children } = props;

  const button = (
    <Button
      className="no-wrap"
      color={color}
      variant="contained"
      component={Link}
      to={to}
      aria-label={ariaLabel}
      startIcon={startIcon}
    >
      {children}
    </Button>
  );

  return tooltip ? <Tooltip title={tooltip}>{button}</Tooltip> : button;
}
