import Typography from '@material-ui/core/Typography';
import { createStyles, makeStyles } from '@material-ui/core/styles';
import ErrorOutlineIcon from '@material-ui/icons/ErrorOutline';
import React from 'react';
import { siteCSSVars } from 'themes/default';

export const validationStyles = makeStyles((theme) =>
  createStyles({
    fieldValidationMessage: {
      color: siteCSSVars.mandatory.color,
      margin: theme.spacing(0.6, 0.6, 0, 0.6),
      display: 'flex',
      alignItems: 'center',
      flexWrap: 'wrap',
    },
    fieldValidationMessageIcon: {
      paddingRight: '4px',
      fontSize: '1.8rem',
    },
    asterisk: {
      color: siteCSSVars.mandatory.color,
    },
    'non-input': {
      marginLeft: '-2px',
    },
    nullClass: {},
    'as-body1': {
      fontSize: '1rem',
      lineHeight: '1.5',
    },
  }),
);

interface IProps {
  message: string;
  className?: 'non-input' | 'as-body1';
}

const messages: { [key: string]: string } = {
  required: 'This field is required.',
};

export function getRuleMessage(key: string) {
  return messages[key];
}

export function requiredValidation(required: boolean | undefined) {
  return required ? { required: messages.required } : {};
}

export function hasFieldError(
  key: string | undefined,
  errors: { [key: string]: any },
  formState: any,
) {
  if (!key) return false;
  if (!formState.isDirty) return false;
  if (!formState.dirtyFields[key]) return false;
  return errors[key] !== undefined;
}

export function FieldValidationMessage(props: IProps) {
  const classes = validationStyles();
  const { message, className } = props;

  let extendedClass = classes.nullClass;
  if (className && typeof className === 'string') {
    extendedClass = classes[className];
  }

  return (
    <>
      <Typography
        className={`${classes.fieldValidationMessage} ${extendedClass}`}
        variant="body1"
        display="block"
      >
        <ErrorOutlineIcon
          color="error"
          classes={{ colorError: classes.fieldValidationMessageIcon }}
        />
        {message}
      </Typography>
    </>
  );
}

export function NestedFieldValidationMessage(props: IProps) {
  const classes = validationStyles();
  const { message, className } = props;

  let extendedClass = classes.nullClass;
  if (className && typeof className === 'string') {
    extendedClass = classes[className];
  }

  return (
    <>
      <span className={`${classes.fieldValidationMessage} ${extendedClass}`}>
        <ErrorOutlineIcon
          color="error"
          classes={{ colorError: classes.fieldValidationMessageIcon }}
        />
        {message}
      </span>
    </>
  );
}
