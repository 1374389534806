import { ASSESSMENT_FORM_TEMPLATE_ID } from 'appGlobals';
import { ActivityIndicator } from 'components/ActivityIndicator';
import ErrorDisplay from 'components/ErrorDisplay';
import { useGetLatestFormAndTemplate } from 'hooks/useGetLatestFormAndTemplate';
import React from 'react';
import { AssessmentPrintView } from './AssessmentPrintView';

export function AssessmentPrintPage() {
  const { loading, formlyConfig, formData, error } = useGetLatestFormAndTemplate(
    ASSESSMENT_FORM_TEMPLATE_ID,
  );

  if (loading || error || !formlyConfig || !formData) {
    return (
      <div className="page">
        <section>
          {loading && (
            <div className="assessment-loader">
              <p>Retrieving the form...</p>

              <ActivityIndicator showProgressBar />
            </div>
          )}
          {error && <ErrorDisplay error={error} />}
        </section>
      </div>
    );
  }

  return <AssessmentPrintView formlyConfig={formlyConfig} formData={formData} />;
}

