import Markdown, { MarkdownToJSX } from 'markdown-to-jsx';
import React from 'react';
import { ExternalLink } from './ExternalLink';

const options: MarkdownToJSX.Options = {
  overrides: {
    a: {
      component: ExternalLink,
    },
  },
};

interface IProps {
  content: string;
}

export function RecommendationMarkdown(props: IProps) {
  const { content } = props;

  return <Markdown options={options}>{content}</Markdown>;
}

