import { Box, Button } from '@material-ui/core';
import { ActivityIndicator } from 'components/ActivityIndicator';
import { ExternalLink } from 'components/Shared/ExternalLink';
import React from 'react';
import { useHistory } from 'react-router';

interface IProps {
  state: 'redirecting' | 'validating' | 'error';
  message?: string;
}

export function AuthScreen(props: IProps) {
  const { state, message = '' } = props;
  const history = useHistory();

  const handleTryAgain = () => {
    history.push('/');
  };

  return (
    <div className="registration-was-modal">
      <div className="tillr-mdl-modal">
        <div className="tillr-mdl-modal__content">
          <div className="mdl-dialog">
            <div className="mdl-dialog__content">
              {state === 'error' ? (
                <>
                  <Box mb={2}>
                    <h3>Unable to log in</h3>
                    <p>{message}</p>
                    <Button variant="contained" color="primary" onClick={handleTryAgain}>
                      Try again
                    </Button>
                  </Box>
                  <p>
                    Need some help?{' '}
                    <ExternalLink
                      href="mailto:support@tillr.io"
                      title="Email support@tillr.io (opens in a new tab/window)"
                    >
                      Contact support
                    </ExternalLink>
                    .
                  </p>
                </>
              ) : (
                <>
                  <h3>One moment please</h3>
                  <Box display="flex" flexDirection="row">
                    {state === 'redirecting' && <p>Redirecting you to authentication...</p>}
                    {state === 'validating' && <p>Validating your credentials...</p>}
                    <Box ml={1}>
                      <ActivityIndicator size={16} showProgressBar />
                    </Box>
                  </Box>
                </>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

