import { Grid } from '@material-ui/core';
import { IComponentProps } from 'formly/IComponentProps';
import withComponentPropsMemo from 'formly/withComponentPropsMemo';
import React from 'react';
import HelpText from './shared/HelpText';
import { FieldValidationMessage, requiredValidation } from './shared/Validation';

function Input(props: IComponentProps) {
  const { field, readonly, modelValue, onValueUpdate, errors, register } = props;

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (field.key) {
      onValueUpdate(field.key, event.currentTarget.value);
    }
  };

  return (
    <>
      {field.className?.match(/clear/) && (
        <hr className="tillr-form-grid-element tillr-form-grid-element--break" />
      )}
      <Grid
        item
        xs={12}
        sm={field.className?.match(/col-xs-6/) ? 6 : 12}
        className={
          field.className?.match(/pull-right/)
            ? 'tillr-form-grid-element tillr-form-grid-element--align-right'
            : 'tillr-form-grid-element'
        }
      >
        <label
          htmlFor={field.key}
          className={`control-label ${readonly ? 'friendly-readonly' : ''}`}
        >
          {field.templateOptions?.required && !readonly && (
            <>
              <span className="required-field">*</span>{' '}
            </>
          )}

          {field.templateOptions?.label}
        </label>
        <input
          id={field.key}
          name={field.key}
          type="text"
          className={`form-control ${readonly ? 'friendly-readonly' : ''}`}
          value={modelValue != null ? modelValue.toString() : field.defaultValue || ''}
          disabled={readonly || field.templateOptions?.disabled}
          onChange={handleChange}
          required={!readonly && field.templateOptions?.required}
          ref={register(requiredValidation(field.templateOptions?.required))}
        />
        {errors[field.key!] && <FieldValidationMessage message={errors[field.key!].message} />}
        {field.data?.help && <HelpText value={field.data?.help} />}
      </Grid>
    </>
  );
}

export default withComponentPropsMemo(Input);

