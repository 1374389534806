import { useCreateForm, useUpdateForm } from 'apollo-hooks';
import { ASSESSMENT_FORM_TEMPLATE_ID, MODULE_ID } from 'appGlobals';
import SiteContext from 'components/Sites/SiteContext';
import FormlyForm from 'formly/FormlyForm';
import { IFormlyConfig } from 'formly/IFormlyConfig';
import { IModel } from 'formly/IModel';
import React, { useContext, useLayoutEffect, useState } from 'react';
import { useHistory, useLocation } from 'react-router';
import UserProfileContext from 'UserProfileContext';
import { generateUuid, getStepIndexFromQuestion, scrollToQuestion } from 'utils';
import { ActionButtons } from './ActionButtons';
import { FormProgress } from './FormProgress';
import { InstructionsMessage } from './InstructionsMessage';

interface IProps {
  formId: string | undefined;
  isSubmitted: boolean;
  formlyConfig: IFormlyConfig;
  model: IModel | undefined;
  templateName: string;
  readonly?: boolean;
  refetch?: () => void;
}

export function AssessmentForm(props: IProps) {
  const {
    formId: initialFormId,
    isSubmitted,
    formlyConfig,
    model: initialModel,
    templateName,
    readonly,
    refetch,
  } = props;

  const history = useHistory();
  const { search } = useLocation();
  const question = new URLSearchParams(search).get('question')?.toUpperCase();

  const [formId, setFormId] = useState(initialFormId);
  const userProfile = useContext(UserProfileContext)!;

  const initialStepIndex = question ? getStepIndexFromQuestion(question) : 0;
  const [stepIndex, setStepIndex] = useState(initialStepIndex);

  useLayoutEffect(() => {
    if (question) {
      const assessmentForm = document.getElementById('assessment-form');
      if (assessmentForm) {
        const labels = assessmentForm.getElementsByTagName('label');
        const label = Array.from(labels).find((l) => l.textContent?.indexOf(question + ':') !== -1);
        if (label) {
          const group = label.closest('div.formly-form-collapsable-groups__group');
          if (group && typeof group.classList === 'object') {
            group.classList.add('formly-form-collapsable-groups__group--visible');
            scrollToQuestion(label);
          }
        }
      }
    }
  }, []);

  const [model, setModel] = useState(initialModel ?? {});

  const { siteId } = useContext(SiteContext)!;
  const module = MODULE_ID;

  const [createForm, createMutationState] = useCreateForm();
  const [updateForm, updateMutationState] = useUpdateForm({ siteId, module, id: initialFormId });

  const isReviewer = userProfile.hasAnyPermission(['Reviewer', 'Admin']);
  const mode = isReviewer || readonly ? 'readonly' : formId ? 'manage' : 'create';

  const handleSaved = (andContinue: boolean) => {
    if (andContinue) {
      setStepIndex((prevStepIndex) => prevStepIndex + 1);
    } else {
      history.push('/');
    }
  };

  const handleSave = (andContinue: boolean) => {
    if (formId) {
      updateForm({
        variables: {
          siteId,
          module,
          form: {
            id: formId,
            name: templateName,
            formDataJson: JSON.stringify(model),
            isSubmitted: false,
          },
        },
      }).then(() => {
        handleSaved(andContinue);
      });
    } else {
      const nextFormId = generateUuid();
      setFormId(nextFormId);
      createForm({
        variables: {
          siteId,
          module,
          form: {
            id: nextFormId,
            templateId: ASSESSMENT_FORM_TEMPLATE_ID,
            name: templateName,
            formDataJson: JSON.stringify(model),
            isSubmitted: false,
          },
        },
      }).then(() => {
        refetch?.();
        handleSaved(andContinue);
      });
    }
  };

  const isSubmitting = createMutationState.loading || updateMutationState.loading;

  return (
    <div>
      {!isSubmitted && <InstructionsMessage />}

      <div className="form-paper">
        <div className="form-group">
          <div className="form-paper-status">
            <FormProgress
              position="top"
              currentStep={stepIndex}
              steps={formlyConfig.data!.steps}
              onChangeStepIndex={setStepIndex}
              model={model}
            />
          </div>
          <ActionButtons
            position="top"
            locked={isSubmitted}
            loading={createMutationState.loading || updateMutationState.loading}
            cancelButton={formlyConfig.settings?.create?.cancel ?? 'Cancel'}
            step={stepIndex}
            totalSteps={formlyConfig.data?.steps.length ?? 0}
            onNextStep={() => setStepIndex((prevStepIndex) => prevStepIndex + 1)}
            onPreviousStep={() => setStepIndex((prevStepIndex) => prevStepIndex - 1)}
            onSave={handleSave}
          />
          <div className="steps">
            <section id="assessment-form" className="step current">
              <FormlyForm
                model={model}
                config={formlyConfig}
                mode={mode}
                submitting={isSubmitting}
                customButtons
                step={stepIndex}
                onChange={setModel}
              />
            </section>
          </div>
          <ActionButtons
            position="top"
            locked={isSubmitted}
            loading={createMutationState.loading || updateMutationState.loading}
            cancelButton={formlyConfig.settings?.create?.cancel ?? 'Cancel'}
            step={stepIndex}
            totalSteps={formlyConfig.data?.steps.length ?? 0}
            onNextStep={() => setStepIndex((prevStepIndex) => prevStepIndex + 1)}
            onPreviousStep={() => setStepIndex((prevStepIndex) => prevStepIndex - 1)}
            onSave={handleSave}
          />
          <div className="form-paper-status">
            <FormProgress
              position="bottom"
              currentStep={stepIndex}
              steps={formlyConfig.data!.steps}
              onChangeStepIndex={setStepIndex}
              model={model}
            />
          </div>
        </div>
      </div>
    </div>
  );
}

