import { ExternalLink } from 'components/Shared/ExternalLink';
import React from 'react';
import { scrollToSmoothly } from 'utils';

export default function Footer() {
  const handleBackToTopClick = () => {
    scrollToSmoothly(0, 1000);
  };

  return (
    <footer className="mdl-mega-footer">
      <div className="mdl-mega-footer--top-section">
        <div className="mdl-mega-footer--left-section">
          <p>
            Powered by {}
            <ExternalLink href="https://www.tillr.io" title="Visit Tillr (opens in a new tab)">
              <strong>Tillr.io</strong>
            </ExternalLink>
            .
          </p>
          <p>&copy; Telamon Software Ltd. All rights reserved.</p>
        </div>
        <div className="mdl-mega-footer--right-section">
          <button type="button" className="link-button back-to-top" onClick={handleBackToTopClick}>
            Back to Top
            <i className="material-icons">expand_less</i>
          </button>
        </div>
      </div>
    </footer>
  );
}
