import React from 'react';
import { Section } from './Section';

interface IProps {
  data: string[][];
}

export function Report(props: IProps) {
  const { data } = props;

  let currentQuestionTitle: string | null = null;
  let currentQuestionIndex = -1;

  function formattedQuestions(question: any[], index: number, master: any[][]) {
    if (currentQuestionTitle === question[0]) {
      question[0] = null;
      if (question[3] === '1') {
        if (
          master[currentQuestionIndex] &&
          master[currentQuestionIndex].constructor === Array &&
          master[currentQuestionIndex][6] &&
          master[currentQuestionIndex][6].constructor === Array
        ) {
          master[currentQuestionIndex][6] = master[currentQuestionIndex][6].concat([question[2]]);
        }
      } else {
        if (
          master[currentQuestionIndex] &&
          master[currentQuestionIndex].constructor === Array &&
          master[currentQuestionIndex][7] &&
          master[currentQuestionIndex][7].constructor === Array
        ) {
          master[currentQuestionIndex][7] = master[currentQuestionIndex][7].concat([question[2]]);
        }
      }
      if (
        question[4] &&
        master[currentQuestionIndex][8] &&
        master[currentQuestionIndex][8].constructor === Array &&
        master[currentQuestionIndex][8].indexOf(question[4]) < 0
      ) {
        // Aggregate distinct recommendations
        master[currentQuestionIndex][8].push(question[4]);
      }
    } else {
      question[0] = question[0];
      currentQuestionTitle = question[0];
      currentQuestionIndex = index;
      question[5] = parseFloat(question[5]);
      question[6] = [];
      question[7] = [];
      if (question[3] === '1') {
        question[6] = question[6].concat([question[2]]);
      } else {
        question[7] = question[6].concat([question[2]]);
      }
      question[8] = [];
      if (question[4]) {
        question[8].push(question[4]);
      }
    }
    if (question[0] && question[0].indexOf(':') !== -1) {
      question[question.length] = question[0].substring(0, question[0].indexOf(':'));
    }
    return question;
  }

  function parseScoreForStarRating(score: string) {
    return parseInt(score);
    // Halve it and round to 1 decimal place
    // return Math.round((parseFloat(score) / 2) * 10) / 10;
  }

  // critical -> -1000000
  let criticalPoints = data.filter((section) => {
    const score = parseInt(section[5], 10);
    if (score === -1000000) {
      return section;
    }
  });
  criticalPoints = criticalPoints.map((question, i) => {
    return formattedQuestions(question, i, criticalPoints);
  });
  // 0 stars -> less than or equal to 0.3
  let noStars = data.filter((section) => {
    const score = parseScoreForStarRating(section[5]);
    if (!isNaN(score) && score > -1000 && score <= 0.3) {
      return section;
    }
  });
  noStars = noStars.map((question, i) => {
    return formattedQuestions(question, i, noStars);
  });
  // 1 star -> 0.4 to 2
  let oneStar = data.filter((section) => {
    const score = parseScoreForStarRating(section[5]);
    if (score && score >= 0.4 && score <= 2) {
      return section;
    }
  });
  oneStar = oneStar.map((question, i) => {
    return formattedQuestions(question, i, oneStar);
  });
  // 2 stars -> 2.1 to 4
  let twoStars = data.filter((section) => {
    const score = parseScoreForStarRating(section[5]);
    if (score && score >= 2.1 && score <= 4) {
      return section;
    }
  });
  twoStars = twoStars.map((question, i) => {
    return formattedQuestions(question, i, twoStars);
  });
  // 3 stars -> 4.1 to 6
  let threeStars = data.filter((section) => {
    const score = parseScoreForStarRating(section[5]);
    if (score && score >= 4.1 && score <= 6) {
      return section;
    }
  });
  threeStars = threeStars.map((question, i) => {
    return formattedQuestions(question, i, threeStars);
  });
  // 4 stars -> 6.1 to 8
  let fourStars = data.filter((section) => {
    const score = parseScoreForStarRating(section[5]);
    if (score && score >= 6.1 && score <= 8) {
      return section;
    }
  });
  fourStars = fourStars.map((question, i) => {
    return formattedQuestions(question, i, fourStars);
  });
  // 5 stars -> 8.1 and above
  let fiveStars = data.filter((section) => {
    const score = parseScoreForStarRating(section[5]);
    if (score && score >= 8.1) {
      return section;
    }
  });
  fiveStars = fiveStars.map((question, i) => {
    return formattedQuestions(question, i, fiveStars);
  });

  const isCritical = !!criticalPoints.length;

  const sections: any[][] = isCritical
    ? [criticalPoints]
    : [noStars, oneStar, twoStars, threeStars, fourStars, fiveStars];

  return (
    <div className="collapsable collapsable-groups">
      {sections.map((section, sectionIndex) => (
        <Section
          key={sectionIndex}
          isCritical={isCritical}
          index={sectionIndex}
          section={section.filter((question) => question[0])}
        />
      ))}
    </div>
  );
}

