import FormControl from '@material-ui/core/FormControl';
import FormGroup from '@material-ui/core/FormGroup';
import Grid from '@material-ui/core/Grid';
import { IComponentProps } from 'formly/IComponentProps';
import withComponentPropsMemo from 'formly/withComponentPropsMemo';
import React, { useEffect, useState } from 'react';
import HelpText from './shared/HelpText';

function ScoredCheckboxes(props: IComponentProps) {
  const {
    field,
    readonly,
    modelValue,
    onValueUpdate,
    register,
    errors,
    setValue,
    getValues,
    clearErrors,
    setError,
  } = props;

  const [disabledIndexes, setDisabledIndexes] = useState<Array<number>>([]);

  let valueArray = (modelValue.model as Array<number | null>) || [];

  const noneTriggerMasterIndex =
    field.data?.toggleItems?.triggerType === 'none' ? field.data.toggleItems.masterIndex : null;

  const customTrigger = () => {
    const values = getValues();

    const error = () => {
      setError(field.key, {
        type: 'manual',
        message: 'You must select at least one option.',
      });
    };

    if (field.key && field.templateOptions?.required) {
      if (!values[field.key] || !values[field.key].length) {
        error();
        return;
      }
      const numericValues = values[field.key].filter((v: number | null) => v !== null);
      if (!numericValues.length) {
        error();
        return;
      }
    }

    clearErrors(field.key!);
  };

  useEffect(() => {
    register({ name: field.key });
    setValue(field.key, valueArray);
    customTrigger();
    // TODO: If I remove the empty array, useEffect keeps getting called. Isn't the
    // idea that an empty array will only call useEffect once?
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (noneTriggerMasterIndex !== null) {
      if (valueArray.some((option) => option !== null)) {
        if (valueArray[noneTriggerMasterIndex] !== null) {
          // "None" is selected, so disable other options
          setDisabledIndexes(
            valueArray.map((_, i) => i).filter((i) => i !== noneTriggerMasterIndex),
          );
        } else {
          // Other option is selected, so disabled "None"
          setDisabledIndexes([noneTriggerMasterIndex]);
        }
      } else {
        // Nothing selected, so don't disable any options
        setDisabledIndexes([]);
      }
    }
  }, [valueArray, noneTriggerMasterIndex]);

  if (!field.templateOptions?.options?.length) {
    // eslint-disable-next-line no-console
    console.warn(`FORMLY: ScoredCheckboxes without options: ${field.key}`);
    return null;
  }

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (!field.key) {
      return;
    }
    const { checked, value, alt } = event.currentTarget;

    const index = Number(alt);
    valueArray = valueArray.map((optionValue, i) => {
      if (i === index) {
        // This option is the one we just toggled, so either value or null
        return checked ? Number(value) : null;
      }
      if (index === noneTriggerMasterIndex && checked) {
        // The "None" trigger was just checked, so this option is null
        return null;
      }
      // Otherwise, keep value the same
      return optionValue;
    });

    const total = valueArray.map((x) => x || 0).reduce((a, b) => a + b);
    onValueUpdate(field.key, { model: valueArray, total });
    setValue(field.key, valueArray, { shouldDirty: true });
    customTrigger();
  };

  const disabled = readonly || field.templateOptions?.disabled;

  return (
    <>
      {field.className?.match(/clear/) && (
        <hr className="tillr-form-grid-element tillr-form-grid-element--break" />
      )}
      <Grid
        item
        xs={12}
        sm={field.className?.match(/col-xs-6/) ? 6 : 12}
        className={
          field.className?.match(/pull-right/)
            ? 'tillr-form-grid-element tillr-form-grid-element--align-right'
            : 'tillr-form-grid-element'
        }
      >
        <FormControl component="fieldset" disabled={readonly} error={!!errors[field.key!]}>
          <label className={`control-label ${readonly ? 'friendly-readonly' : ''}`}>
            {field.templateOptions?.required && !readonly && (
              <>
                <span className="required-field">*</span>{' '}
              </>
            )}
            {field.templateOptions?.label}
          </label>

          <FormGroup>
            <div className="radio-group">
              {field.templateOptions.options.map((option, i) => {
                const htmlId = `${field.key}_${i}`;
                return (
                  <div key={htmlId} className="checkbox">
                    <label htmlFor={htmlId}>
                      <input
                        type="checkbox"
                        id={htmlId}
                        className={`magic-checkbox ${disabled ? 'friendly-readonly' : ''}`}
                        disabled={disabled || disabledIndexes.includes(i)}
                        value={option.value}
                        checked={valueArray[i] != null}
                        onChange={handleChange}
                        alt={i.toString()}
                      />
                      <label htmlFor={htmlId} />
                      <span className="MuiTypography-root MuiFormControlLabel-label MuiTypography-body1">
                        {option.name}
                      </span>
                    </label>
                  </div>
                );
              })}
            </div>
          </FormGroup>
          {/* {errors[field.key!] && (
            <FieldValidationMessage className="non-input" message={errors[field.key!].message} />
          )} */}
          {field.data?.help && <HelpText value={field.data?.help} />}
        </FormControl>
      </Grid>
    </>
  );
}

export default withComponentPropsMemo(ScoredCheckboxes);
