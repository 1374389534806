import { ApolloError } from '@apollo/client';
import { useGetMyReport } from 'apollo-hooks';
import { MODULE_ID } from 'appGlobals';
import { useEffect, useState } from 'react';
import { ReportIdType, useRunReport } from './useRunReport';
import { parseData } from './utils';

export interface IRunReportToJsonState<T = Record<string, any>> {
  loading: boolean;
  data: Array<T> | undefined;
  error: string | ApolloError | null;
}

export function useRunReportToJson<T>(reportId: string): {
  runReport: (inputParameters: Record<string, string>) => void;
  runReportState: IRunReportToJsonState<T>;
} {
  const module = MODULE_ID;

  const getMyReportState = useGetMyReport({ module, id: reportId });
  const { runReport, runReportState } = useRunReport();

  const [jsonData, setJsonData] = useState<Array<T>>();

  useEffect(() => {
    if (getMyReportState.data?.myReport?.queryDefinition?.resultColumns && runReportState.data) {
      setJsonData(
        parseData(
          runReportState.data,
          getMyReportState.data.myReport.queryDefinition.resultColumns,
        ).data.map((row) => row as T),
      );
    }
  }, [getMyReportState.data, runReportState.data]);

  const runReportToJson = (inputParameters: Record<string, string>) => {
    runReport(reportId, ReportIdType.Report, inputParameters);
  };

  const state: IRunReportToJsonState<T> = {
    loading: getMyReportState.loading || runReportState.loading,
    data: jsonData,
    error: getMyReportState.error || runReportState.error || null,
  };

  return { runReport: runReportToJson, runReportState: state };
}

