import React, { useContext } from 'react';
import { Redirect, Route, RouteProps } from 'react-router';
import UserProfileContext from 'UserProfileContext';
import { setStartUpRoute } from 'utils';

interface IProps {
  permissions?: string[];
  requireAll?: boolean;
}

export function AuthenticatedRoute(props: IProps & RouteProps) {
  const userProfile = useContext(UserProfileContext);

  const { permissions, requireAll = false, ...routeProps } = props;

  if (userProfile && (!permissions || userProfile.hasPermission(permissions, requireAll))) {
    return <Route {...routeProps} />;
  }

  if (!userProfile) {
    setStartUpRoute(window.location.pathname);
    return <Redirect to="/" />;
  }

  return <Redirect to="/unauthorised" />;
}
