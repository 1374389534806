import { Grid } from '@material-ui/core';
import { CLIENT_ID } from 'appGlobals';
import { SignInButton } from 'auth/SignInButton';
import { MarketingPreferencesControl } from 'components/Shared/MarketingPreferencesControl';
import { getAccountsApiUrl } from 'environment-utils';
import { FieldValidationMessage } from 'formly/components/shared/Validation';
import React, { ChangeEvent, useEffect, useState } from 'react';
import { MarketingPreferences, Origin } from 'types';
import { generateUuid } from 'utils';

type State =
  | 'Register'
  | 'Registering...'
  | 'Recognised'
  | 'ClientError'
  | 'ServerError'
  | 'Offline'
  | 'Unauthorised'
  | 'Success';

type RegisterModel = {
  email: string;
  confirmEmail: string;
  marketing: {
    primary: boolean;
    parent: boolean;
  };
};
type ErrorModel = { email?: string; confirmEmail?: string };

const validateForm = (model: RegisterModel): ErrorModel | null => {
  if (!model.email) {
    return { email: 'Email is required.' };
  }
  if (!model.confirmEmail) {
    return { confirmEmail: 'Email confirmation is required.' };
  }
  if (model.confirmEmail !== model.email) {
    return { confirmEmail: `${model.confirmEmail} does not match ${model.email}` };
  }
  return null;
};

interface IProps {
  origin: Origin;
}

export function RegisterForm(props: IProps) {
  const { origin } = props;

  const [state, setState] = useState<State>('Register');
  const [model, setModel] = useState<RegisterModel>({
    email: '',
    confirmEmail: '',
    marketing: {
      primary: true,
      parent: true,
    },
  });
  const [errorModel, setErrorModel] = useState<ErrorModel | null>();
  const [showValidationErrors, setShowValidationErrors] = useState(false);
  const [error, setError] = useState<string>();

  const handleChangeInput = (e: ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    setModel((prevModel) => ({ ...prevModel, [name]: value }));
    if (name === 'email' || name === 'confirmEmail') {
      setShowValidationErrors(false);
      setErrorModel(null);
    }
  };

  const handleChangeMarketing = (value: MarketingPreferences) => {
    setModel((prevModel) => ({ ...prevModel, marketing: value }));
  };

  useEffect(() => {
    if (showValidationErrors) {
      setErrorModel(validateForm(model));
    }
  }, [model, showValidationErrors]);

  const handleRegister = () => {
    setShowValidationErrors(true);

    const validateResult = validateForm(model);
    if (validateResult) {
      setErrorModel(validateResult);
      return;
    }

    if (origin === 'RA') {
      if (
        !model.email.match(/\.police\.uk$/) &&
        !model.email.match(/\.police\.co\.uk$/) &&
        !model.email.match(/\.gov\.uk$/)
      ) {
        // Only police/gov can sign up via RA
        setState('Unauthorised');
        return;
      }
    }

    const data = {
      ClientId: CLIENT_ID,
      Email: model.email,
      UserId: generateUuid(),
      CustomData: JSON.stringify({
        marketing: model.marketing,
        origin,
      }),
    };

    setState('Registering...');

    fetch(getAccountsApiUrl('api/SignUps'), {
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
      method: 'POST',
      body: JSON.stringify(data),
    })
      .then((response) => {
        if (response.ok) {
          setState('Success');
        } else {
          response.json().then((json) => {
            if (json.Message === 'user with that email already exists') {
              setState('Recognised');
            } else {
              setError(json.Message);
              setState('Register');
              setState(response.status < 500 ? 'ClientError' : 'ServerError');
            }
          });
        }
      })
      .catch(() => {
        setState('Offline');
      });
  };

  const handleTryAgain = () => {
    setError(undefined);
    setState('Register');
  };

  return (
    <>
      <h3 className="mdl-dialog__title">
        Register with Licensing SAVI to begin your self assessment
      </h3>
      {(state === 'Register' || state === 'Registering...') && (
        <div>
          <p className="registration-modal__p--sm-margin">
            Please provide your email address to start your Licensing SAVI journey.
          </p>
          <div className="alert alert--error">
            <p className="registration-modal__p--no-margin">
              <strong>Please note:</strong> We strongly advise that you use a general premises/venue
              email address - such as those that begin with info@ or enquiries@ - rather than a
              personal or individual's email address. In this way, the LSAVI account created will
              always belong to the premises/venue.
            </p>
          </div>
          <form noValidate autoComplete="off">
            <Grid container spacing={3}>
              <Grid item xs={12} className="tillr-form-grid-element">
                <label htmlFor="email" className="control-label">
                  <span className="required-field">*</span> Email address
                </label>
                <input
                  id="email"
                  name="email"
                  type="text"
                  className="form-control"
                  value={model.email}
                  onChange={handleChangeInput}
                  required
                />
                {errorModel?.email && <FieldValidationMessage message={errorModel.email} />}
              </Grid>
              <Grid item xs={12} className="tillr-form-grid-element">
                <label htmlFor="confirmEmail" className="control-label">
                  <span className="required-field">*</span> Confirm your email address
                </label>
                <input
                  id="confirmEmail"
                  name="confirmEmail"
                  type="text"
                  className="form-control"
                  value={model.confirmEmail}
                  onChange={handleChangeInput}
                  required
                />
                {errorModel?.confirmEmail && (
                  <FieldValidationMessage message={errorModel.confirmEmail} />
                )}
              </Grid>

              <Grid item xs={12} className="tillr-form-grid-element">
                <MarketingPreferencesControl
                  value={model.marketing}
                  onChange={handleChangeMarketing}
                />
              </Grid>
            </Grid>

            <div className="hidden-print clear">
              <button
                id="register-button"
                className="mdl-button mdl-js-button mdl-button--raised mdl-js-ripple-effect"
                type="button"
                onClick={handleRegister}
                disabled={state === 'Registering...'}
              >
                {state}
              </button>
            </div>
          </form>
        </div>
      )}
      {state === 'Recognised' && (
        <div>
          <span className="paper-clip hidden-print"></span>
          <p>
            <strong>There has been a problem.</strong>
          </p>
          <p>
            It seems that you have already registered. Please sign in using your registered details.
          </p>
          <SignInButton />
          <button
            id="try-again-button"
            className="mdl-button mdl-js-button mdl-js-ripple-effect mdl-button--raised mdl-button--colored-secondary"
            onClick={handleTryAgain}
          >
            Try again
          </button>
        </div>
      )}
      {state === 'ClientError' && (
        <div>
          <span className="paper-clip hidden-print"></span>
          <p>
            <strong>There has been a problem.</strong>
          </p>
          <p>{error}</p>
          <button
            id="try-again-button"
            className="mdl-button mdl-js-button mdl-js-ripple-effect mdl-button--raised mdl-button--colored-secondary"
            onClick={handleTryAgain}
          >
            Try again
          </button>
        </div>
      )}
      {state === 'ServerError' && (
        <div>
          <span className="paper-clip hidden-print"></span>
          <p>
            <strong>There has been a problem.</strong>
          </p>
          <p>
            It's not you, it's us. We were unable to register you at this time. Please try again or
            contact support.
          </p>
          <button
            id="try-again-button"
            className="mdl-button mdl-js-button mdl-js-ripple-effect mdl-button--raised mdl-button--colored-secondary"
            onClick={handleTryAgain}
          >
            Try again
          </button>
        </div>
      )}
      {state === 'Offline' && (
        <div>
          <span className="paper-clip hidden-print"></span>
          <p>
            <strong>There has been a problem.</strong>
          </p>
          <p>
            We were unable to contact the server. Please check your connection and try again, or
            contact support.
          </p>
          <button
            id="try-again-button"
            className="mdl-button mdl-js-button mdl-js-ripple-effect mdl-button--raised mdl-button--colored-secondary"
            onClick={handleTryAgain}
          >
            Try again
          </button>
        </div>
      )}
      {state === 'Unauthorised' && (
        <div>
          <span className="paper-clip hidden-print"></span>
          <p>
            <strong>There has been a problem.</strong>
          </p>
          <p>
            The email address <strong>{model.email}</strong> is not authorised to access this area
            of the LSAVI application. Please try another email address or contact support.
          </p>
          <button
            id="try-again-button"
            className="mdl-button mdl-js-button mdl-js-ripple-effect mdl-button--raised mdl-button--colored-secondary"
            onClick={handleTryAgain}
          >
            Try again
          </button>
        </div>
      )}
      {state === 'Success' && (
        <div>
          <span className="paper-clip hidden-print"></span>
          <p>
            <strong>Thank you.</strong>
          </p>
          <p>
            Your registration was successful. {}
            <span>
              An email has been sent to <strong>{model.email}</strong>.
            </span>
            Please check your inbox and/or junk mail for further instructions.
          </p>
        </div>
      )}
    </>
  );
}

