import { Button, Dialog, DialogContent, DialogTitle } from '@material-ui/core';
import { useCreateInvitation, useCreateSite } from 'apollo-hooks';
import { CHAIN_MANAGER_ROLE_ID, MODULE_ID } from 'appGlobals';
import ErrorDisplay from 'components/ErrorDisplay';
import { TillrModal } from 'components/Shared/TillrModal';
import FormlyForm from 'formly/FormlyForm';
import getStaticTemplate from 'formly/staticTemplates';
import React, { useState } from 'react';
import { CreateInvitationInputType, CreateSiteMutationVariables } from 'tillr-graphql';
import { CHAIN_SITE_TYPE } from 'types';

export function AddChain() {
  const [open, setOpen] = useState(false);

  const [createSite, createSiteState] = useCreateSite({
    getSitesQueryVariables: { parentSiteId: null, type: CHAIN_SITE_TYPE },
  });
  const [createInvitation, createInvitationState] = useCreateInvitation();

  const handleSubmit = (chain: { name: string; email: string }) => {
    const variables: CreateSiteMutationVariables = {
      parentSiteId: null,
      site: {
        name: chain.name,
        type: CHAIN_SITE_TYPE,
      },
    };
    createSite({ variables }).then((createSiteResponse) => {
      if (createSiteResponse.data?.createSite) {
        const invitation: CreateInvitationInputType = {
          email: chain.email,
          roleId: CHAIN_MANAGER_ROLE_ID,
          modules: [MODULE_ID],
          siteIds: [createSiteResponse.data.createSite.id],
        };

        createInvitation({ variables: { invitation } }).then((createInvitationResponse) => {
          if (!createInvitationResponse.errors) {
            setOpen(false);
          }
        });
      }
    });
  };

  const config = getStaticTemplate('create-chain');

  const submitting = createSiteState.loading || createInvitationState.loading;

  const handleClose = () => {
    createSiteState.reset();
    createInvitationState.reset();
    setOpen(false);
  };

  return (
    <>
      {createInvitationState.data && (
        <TillrModal title="Success" onOk={handleClose}>
          Chain added successfully.
        </TillrModal>
      )}

      <Button color="secondary" variant="contained" onClick={() => setOpen(true)}>
        Add chain
      </Button>

      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="form-dialog-title"
        fullWidth
        maxWidth="xs"
      >
        <DialogTitle id="form-dialog-title">Create chain</DialogTitle>
        <DialogContent>
          {createSiteState.error && <ErrorDisplay error={createSiteState.error} />}
          {createInvitationState.error && <ErrorDisplay error={createInvitationState.error} />}
          <FormlyForm
            config={config}
            onCancel={handleClose}
            onSubmit={handleSubmit}
            submitting={submitting}
            redSaveButton
          />
        </DialogContent>
      </Dialog>
    </>
  );
}
