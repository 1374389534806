import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { userManager } from 'createUserManager';
import { getUrlPrefix } from 'environment-utils';
import { CheckoutSessionPayload, CheckoutSessionResponse, GetPaymentsResponse } from './types';

const setDefaultHeaders = async (headers: Headers) => {
  const user = await userManager.getUser();
  if (user) {
    headers.set('Authorization', `${user.token_type} ${user.access_token}`);
  }
  return headers;
};

export const accountsApi = createApi({
  reducerPath: 'accountsApi',
  baseQuery: fetchBaseQuery({
    baseUrl: `https://${getUrlPrefix()}accounts.tillr.io/api`,
    prepareHeaders: setDefaultHeaders,
  }),
  endpoints: (builder) => ({
    getPayments: builder.query<GetPaymentsResponse, { siteId: number }>({
      query: ({ siteId }) => ({ url: `Payments?siteId=${siteId}`, method: 'GET' }),
    }),
    createCheckoutSession: builder.mutation<CheckoutSessionResponse, CheckoutSessionPayload>({
      query: (payload) => ({ url: 'Payments/CheckoutSessions', method: 'POST', body: payload }),
    }),
  }),
});

export const { useGetPaymentsQuery, useCreateCheckoutSessionMutation } = accountsApi;

