import { SignOutButton } from 'auth/SignOutButton';
import { SignOutLink } from 'auth/SignOutLink';
import { ActivityIndicator } from 'components/ActivityIndicator';
import ErrorDisplay from 'components/ErrorDisplay';
import { ExternalLink } from 'components/Shared/ExternalLink';
import { TillrModal } from 'components/Shared/TillrModal';
import SiteContext from 'components/Sites/SiteContext';
import { useGetReviewsData } from 'hooks/useGetReviewsData';
import { useGetVenueStatuses } from 'hooks/useGetVenueStatuses';
import React, { useContext, useState } from 'react';
import { Link } from 'react-router-dom';
import { useUpdateReviewStatusMutation } from 'store/api2/api2Api';
import { GetReviewsResponse, Review } from 'store/api2/types';
import UserProfileContext from 'UserProfileContext';
import { formatReviewDate, getErrorMessage, getGreeting } from 'utils';

function getStatusText(status: string): string {
  switch (status) {
    case 'Pause':
    case 'Paused':
      return 'Pend';
    default:
      return status;
  }
}

function getMyReview(
  data: GetReviewsResponse | undefined,
  reviewerEmail: string | undefined,
): Review | undefined {
  return data?.find((review) => review.ReviewerEmail === reviewerEmail);
}

function getMyDecision(myReview: Review | undefined) {
  if (!myReview || myReview.Status === 'Requested') {
    return 'Awaiting decision';
  }
  const statusLabel = getStatusText(myReview.Status).toUpperCase();
  const date = formatReviewDate(myReview.ActionedAt);
  return `You set the status to ${statusLabel} on ${date}.`;
}

export function ReviewPage() {
  const userProfile = useContext(UserProfileContext)!;
  const { siteId } = useContext(SiteContext)!;

  const getVenueStatusesState = useGetVenueStatuses(siteId);
  const venueStatus = getVenueStatusesState?.currentStatus;

  const getReviewsState = useGetReviewsData(venueStatus?.AssessmentFormId);
  const { reviewStatus, data, requestedDate, expiryDate } = getReviewsState;
  const approvalsCount = data?.filter((status) => status.Status === 'Approved').length;

  const myReview = getMyReview(data, userProfile.reviewerEmail);
  const myDecision = getMyDecision(myReview);

  const [nextStatus, setNextStatus] = useState<'Pause' | 'Approve' | null>();
  const [reason, setReason] = useState<string>('');
  const [confirmUpdateStatus, setConfirmUpdateStatus] = useState(false);
  const [updateReviewStatus, updateReviewStatusState] = useUpdateReviewStatusMutation();

  const handleUpdateStatus = (next: 'Pause' | 'Approve') => {
    setNextStatus(next);
  };

  const handleSubmitUpdateStatus = () => {
    if (venueStatus?.AssessmentFormId && nextStatus) {
      updateReviewStatus({
        FormId: venueStatus.AssessmentFormId,
        Status: nextStatus,
        Reason: reason,
      });
    }
  };

  const handleClose = () => {
    setNextStatus(null);
    setConfirmUpdateStatus(false);
    updateReviewStatusState.reset();
  };

  // TODO: check is not responsible authority?
  return (
    <>
      {nextStatus && updateReviewStatusState.isUninitialized && (
        <>
          {!confirmUpdateStatus ? (
            <TillrModal
              title={getStatusText(nextStatus)}
              okButtonText="Yes"
              onOk={() => setConfirmUpdateStatus(true)}
              onCancel={() => setNextStatus(null)}
            >
              Are you certain you wish to {getStatusText(nextStatus).toLowerCase()} this
              application?
            </TillrModal>
          ) : (
            <TillrModal
              mediumWidth
              title={`${getStatusText(nextStatus)} application`}
              okButtonText={`${getStatusText(nextStatus)} application`}
              okDisabled={reason.length === 0}
              onOk={handleSubmitUpdateStatus}
              onCancel={handleClose}
            >
              <p>Please give a brief outline of the reason behind your decision.</p>
              <textarea
                className="form-control"
                maxLength={2000}
                value={reason}
                onChange={(e) => setReason(e.target.value)}
              />
              <small className="form-text text-muted">Maximum length 2000 characters.</small>
            </TillrModal>
          )}
        </>
      )}

      {updateReviewStatusState.isSuccess && (
        <TillrModal title="Thank you" onOk={handleClose} okButtonText="Close">
          Your decision has been noted.
        </TillrModal>
      )}

      {updateReviewStatusState.error && (
        <TillrModal title="There was a problem" onOk={handleClose} okButtonText="Close">
          We were unable to save your decision at this time. Please try again later.
        </TillrModal>
      )}

      <div className="dashboard">
        <section>
          <h3>
            <span>{getGreeting()}</span> <SignOutButton />
          </h3>

          {userProfile.reviewerEmail && (
            <p>
              This page belongs to <strong>{userProfile.reviewerEmail}</strong>. If this is not you,
              please <SignOutLink />.
            </p>
          )}

          {getVenueStatusesState.loading || getReviewsState.isLoading ? (
            <ActivityIndicator showProgressBar />
          ) : (
            <>
              {getVenueStatusesState.error && <ErrorDisplay error={getVenueStatusesState.error} />}
              {getReviewsState.error && (
                <ErrorDisplay error={getErrorMessage(getReviewsState.error)} />
              )}

              {!venueStatus || !reviewStatus ? (
                <div>
                  <p>This application is no longer active.</p>
                </div>
              ) : (
                <div>
                  <p>
                    You are currently reviewing the Licensing SAVI Award application for the
                    licensee detailed below. If this looks incorrect, please <SignOutLink />.
                  </p>

                  <p>
                    Please note that only applications that have a current status of{' '}
                    <strong>PENDING</strong> can be <strong>APPROVED</strong> or marked as{' '}
                    <strong>PEND</strong>.
                  </p>

                  <table className="mdl-data-table mdl-js-data-table">
                    <thead>
                      <tr>
                        <th className="mdl-data-table__cell--non-numeric">Application details</th>
                        <th>&nbsp;</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td className="mdl-data-table__cell--non-numeric">Current status</td>
                        <td className="mdl-data-table__cell--non-numeric">
                          <strong>{reviewStatus}</strong>
                        </td>
                      </tr>
                      {reviewStatus !== 'PEND' && (
                        <tr>
                          <td className="mdl-data-table__cell--non-numeric">
                            Current approval status
                          </td>
                          <td className="mdl-data-table__cell--non-numeric">
                            <strong>{approvalsCount}/3</strong>
                          </td>
                        </tr>
                      )}
                      <tr>
                        <td className="mdl-data-table__cell--non-numeric">Your decision</td>
                        <td className="mdl-data-table__cell--non-numeric">
                          <strong>{myDecision}</strong>
                        </td>
                      </tr>
                      <tr>
                        <td className="mdl-data-table__cell--non-numeric">Applicant name</td>
                        <td className="mdl-data-table__cell--non-numeric">{userProfile.name}</td>
                      </tr>
                      <tr>
                        <td className="mdl-data-table__cell--non-numeric">Applicant email</td>
                        <td className="mdl-data-table__cell--non-numeric">
                          <ExternalLink href={`mailto:${userProfile.email}`}>
                            {userProfile.email}
                          </ExternalLink>
                        </td>
                      </tr>
                      <tr>
                        <td className="mdl-data-table__cell--non-numeric">
                          Licensing SAVI Award rating
                        </td>
                        <td className="mdl-data-table__cell--non-numeric">
                          {venueStatus.StarRating} / 5
                        </td>
                      </tr>
                      <tr>
                        <td className="mdl-data-table__cell--non-numeric">Application date</td>
                        <td className="mdl-data-table__cell--non-numeric">
                          {reviewStatus === 'PEND' ? 'N/A' : formatReviewDate(requestedDate)}
                        </td>
                      </tr>
                      <tr>
                        <td className="mdl-data-table__cell--non-numeric">Application ends</td>
                        <td className="mdl-data-table__cell--non-numeric">
                          {reviewStatus === 'PEND' ? 'N/A' : formatReviewDate(expiryDate)}
                        </td>
                      </tr>
                      <tr>
                        <td className="mdl-data-table__cell--non-numeric">Applicant details</td>
                        <td className="mdl-data-table__cell--non-numeric">
                          <Link to="/premises-info">Premises information</Link>
                        </td>
                      </tr>
                      <tr>
                        <td className="mdl-data-table__cell--non-numeric">&nbsp;</td>
                        <td className="mdl-data-table__cell--non-numeric">
                          <Link to="/assessment">Completed assessment</Link>
                        </td>
                      </tr>
                      <tr>
                        <td className="mdl-data-table__cell--non-numeric">&nbsp;</td>
                        <td className="mdl-data-table__cell--non-numeric">
                          <Link to="/assessment/print">Completed assessment (print view)</Link>
                        </td>
                      </tr>
                      <tr>
                        <td className="mdl-data-table__cell--non-numeric">&nbsp;</td>
                        <td className="mdl-data-table__cell--non-numeric">
                          <Link to="/accreditation">
                            Reviewer's information and supporting evidence
                          </Link>
                        </td>
                      </tr>
                    </tbody>
                  </table>

                  <hr />

                  <ul className="dashboard-list">
                    <li>
                      <button
                        type="button"
                        disabled={reviewStatus !== 'PENDING' || myReview?.Status === 'Approved'}
                        onClick={() => handleUpdateStatus('Pause')}
                        className="button-secondary"
                      >
                        Pend application <i className="glyphicon glyphicon-stopwatch"></i>
                      </button>
                    </li>
                    <li>
                      <button
                        type="button"
                        disabled={reviewStatus !== 'PENDING' || myReview?.Status === 'Approved'}
                        onClick={() => handleUpdateStatus('Approve')}
                        className="button-secondary"
                      >
                        Approve this application <i className="glyphicon glyphicon-check"></i>
                      </button>
                    </li>
                  </ul>
                </div>
              )}
            </>
          )}
        </section>
      </div>
    </>
  );
}

