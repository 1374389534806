import { User, UserSettings } from 'oidc-client';
import React, { useContext, useState } from 'react';
import { Redirect } from 'react-router';
import UserManagerContext from 'UserManagerContext';
import { AuthScreen } from './AuthScreen';

export function Authenticated() {
  const [isAuthenticated, setAuthenticated] = useState(false);
  const [authenticationError, setAuthenticationError] = useState<null | string>(null);
  const userManager = useContext(UserManagerContext)!;

  if (isAuthenticated) {
    return <Redirect to="/" />;
  }

  if (authenticationError) {
    if (authenticationError.match(/future/)) {
      return (
        <AuthScreen
          state="error"
          message="Please check that your device's time and date are correct."
        />
      );
    }
    return (
      <AuthScreen
        state="error"
        message="Sorry, there has been a problem. Please try later or contact support."
      />
    );
  }

  userManager
    .processSigninResponse()
    .then((response) => {
      // TODO: find out why expires_at isn't on Response type but it is there?
      const user = new User(response as unknown as UserSettings);
      userManager.storeUser(user);
      userManager.events.load(user);
      setAuthenticated(true);
    })
    .catch((e: Error) => {
      console.error(e);
      if (e.message === 'No matching state found in storage') {
        // TODO: find out why this happens
        // Everything still succeeds in handling the response, but this error is also thrown.
      } else {
        // eslint-disable-next-line no-console
        console.error(e);
        setAuthenticationError(e.message);
      }
    });

  return <AuthScreen state="validating" />;
}
