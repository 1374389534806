import Grid from '@material-ui/core/Grid';
import { IComponentProps } from 'formly/IComponentProps';
import withComponentPropsMemo from 'formly/withComponentPropsMemo';
import React from 'react';

function Heading(props: IComponentProps) {
  const { field } = props;
  return (
    <>
      {field.className?.match(/clear/) && (
        <hr className="tillr-form-grid-element tillr-form-grid-element--break" />
      )}
      <Grid item xs={12} style={{ paddingBottom: 0 }}>
        <div className="formly-field formly-builder-element formly-field-heading">
          <h2>{field.defaultValue}</h2>
        </div>
      </Grid>
    </>
  );
}

export default withComponentPropsMemo(Heading);
