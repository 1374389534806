import {
  useGetFormTemplateAndInstances,
  useGetSiteAdmin,
  useUpdateForm,
  useUpdateSite,
} from 'apollo-hooks';
import {
  MODULE_ID,
  PREMISES_INFO_POSTCODE_FIELD_ID,
  PREMISES_INFO_PREMISES_NAME_FIELD_ID,
  PREMISES_INFO_TEMPLATE_ID,
} from 'appGlobals';
import { ActivityIndicator } from 'components/ActivityIndicator';
import ErrorDisplay from 'components/ErrorDisplay';
import { TillrModal } from 'components/Shared/TillrModal';
import FormlyForm from 'formly/FormlyForm';
import { IFormlyConfig } from 'formly/IFormlyConfig';
import { IModel } from 'formly/IModel';
import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { FormOrderBy, FormType, PaginationOrder, UpdateSiteMutationVariables } from 'tillr-graphql';
import { VENUE_SITE_TYPE } from 'types';

interface IProps {
  venueId: number;
  returnButtonLabel: string;
  returnUrl: string;
}

export function PremisesInfoAdminContainer(props: IProps) {
  const { venueId, returnButtonLabel, returnUrl } = props;

  const history = useHistory();

  const module = MODULE_ID;

  const getSiteState = useGetSiteAdmin({ id: venueId });

  const getFormTemplateState = useGetFormTemplateAndInstances({
    siteId: venueId,
    module,
    formTemplateId: PREMISES_INFO_TEMPLATE_ID,
    paginationProps: {
      pageSize: 1,
      orderBy: FormOrderBy.CreatedDateTime,
      order: PaginationOrder.Desc,
    },
  });

  const [formlyConfig, setFormlyConfig] = useState<IFormlyConfig>();
  const [form, setForm] = useState<Partial<FormType>>();
  const [formData, setFormData] = useState<IModel>();

  useEffect(() => {
    // This isn't expected to happen, but guard against it anyway.
    if (!getFormTemplateState.data?.formTemplate?.template) {
      return;
    }

    const mostRecentForm = getFormTemplateState.data?.forms?.items[0];

    if (mostRecentForm) {
      setForm(mostRecentForm);
      setFormData(JSON.parse(mostRecentForm.formDataJson));
      setFormlyConfig(JSON.parse(getFormTemplateState.data.formTemplate.template));
    }
  }, [getFormTemplateState.data]);

  const [updateForm, updateFormState] = useUpdateForm({ siteId: venueId, module, id: form?.id });

  const [updateSite, updateSiteState] = useUpdateSite();

  const site = getSiteState.data?.siteAdmin;

  const handleClose = () => {
    history.push(returnUrl);
  };

  const handleSubmit = (submittedModel: IModel) => {
    if (form && getFormTemplateState.data?.formTemplate) {
      updateForm({
        variables: {
          siteId: venueId,
          module,
          form: {
            id: form.id,
            name: getFormTemplateState.data?.formTemplate.name,
            formDataJson: JSON.stringify(submittedModel),
            isSubmitted: false,
          },
        },
      });

      const name = submittedModel[PREMISES_INFO_PREMISES_NAME_FIELD_ID];
      const postcode = submittedModel[PREMISES_INFO_POSTCODE_FIELD_ID];
      const siteName = `${name} (${postcode})`;
      // Only update if site is not part of a chain (no parent)
      if (site && site.name !== siteName && !site.parentSiteId) {
        const variables: UpdateSiteMutationVariables = {
          site: {
            id: venueId,
            name: siteName,
            type: VENUE_SITE_TYPE,
          },
        };
        updateSite({ variables });
      }
    }
  };

  const savedSuccessfully =
    updateFormState.data?.updateForm && (!updateSiteState.called || updateSiteState.data);

  return (
    <>
      {savedSuccessfully && (
        <TillrModal title="Success" okButtonText={returnButtonLabel} onOk={handleClose}>
          Premises info updated successfully.
        </TillrModal>
      )}

      {(getSiteState.loading || getFormTemplateState.loading) && <ActivityIndicator />}
      {getSiteState.error && <ErrorDisplay error={getSiteState.error} />}
      {getFormTemplateState.error && <ErrorDisplay error={getFormTemplateState.error} />}
      {!getSiteState.loading && !getFormTemplateState.loading && (
        <>
          {site ? (
            <>
              {site.type === VENUE_SITE_TYPE ? (
                <>
                  {!formData || !formlyConfig ? (
                    <div className="alert alert--info">
                      {site.name} hasn't submitted their premises info yet.
                    </div>
                  ) : (
                    <FormlyForm
                      model={formData}
                      config={formlyConfig}
                      mode="manage"
                      onCancel={handleClose}
                      onSubmit={handleSubmit}
                      extraRequiredKeys={[
                        'ee08cc04-c721-4a9a-9843-93fa3019db61',
                        '2c64012c-e51b-469f-a96e-26547f340648',
                      ]}
                      submitting={updateFormState.loading || updateSiteState.loading}
                    />
                  )}
                </>
              ) : (
                <ErrorDisplay errorMessage={`${site.name} is not a venue.`} />
              )}
            </>
          ) : (
            <ErrorDisplay errorMessage={`Unknown siteId: ${venueId}.`} />
          )}
        </>
      )}
    </>
  );
}

