import React, { PropsWithChildren } from 'react';
import { Link } from 'react-router-dom';

interface IProps {
  to: string;
  className?: string;
  disabled?: boolean;
}

export function JourneyLink(props: PropsWithChildren<IProps>) {
  const { to, className, disabled, children } = props;

  return (
    <Link to={to} style={{ textDecoration: 'none' }}>
      <button type="button" className={className} disabled={disabled}>
        {children}
      </button>
    </Link>
  );
}

