import React from 'react';

export function AnimatedCheck() {
  return (
    <svg className="animated-check" viewBox="0 0 24 24">
      <path d="M4.1 12.7L9 17.6 20.3 6.3" fill="none" />
    </svg>
  );
}

