import { Paper, Tab, Tabs } from '@material-ui/core';
import ErrorBoundary from 'components/ErrorBoundary';
import { DataColumn } from 'hooks/Reports/utils';
import React, { useState } from 'react';
import { IReportConfig } from './IReportConfig';
import { ReportDataView } from './ReportDataView';

interface IProps {
  reportConfig: IReportConfig;
  data: string[][];
  resultColumns: DataColumn[];
}

export function ReportResultsViewer(props: IProps) {
  const { reportConfig, data, resultColumns } = props;

  const [viewIndex, setViewIndex] = useState(0);

  const handleChangeViewIndex = (event: React.ChangeEvent<{}>, newValue: string) => {
    setViewIndex(Number(newValue));
  };

  return (
    <Paper>
      {reportConfig.views.length > 1 && (
        <Tabs
          value={viewIndex}
          indicatorColor="primary"
          textColor="inherit"
          onChange={handleChangeViewIndex}
          aria-label="Tabs for each Report view"
        >
          {reportConfig.views.map((x, i) => (
            <Tab key={x.title} label={x.title} value={i} />
          ))}
        </Tabs>
      )}
      <ErrorBoundary>
        <ReportDataView
          reportConfig={reportConfig}
          viewIndex={viewIndex}
          data={data}
          resultColumns={resultColumns}
        />
      </ErrorBoundary>
    </Paper>
  );
}
