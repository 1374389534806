import { format, isBefore } from 'date-fns';
import { IVenueStatusData } from 'hooks/useGetVenueStatuses';
import { pluralise } from 'utils';

type TrafficLightStatus = 'red' | 'amber' | 'green';

interface ITrafficLight {
  summaries: Array<string>;
  status: TrafficLightStatus;
}

export function formatDate(date: Date): string {
  return format(date, 'd MMM yyyy');
}

function createTrafficLight(
  status: TrafficLightStatus,
  ...summaries: Array<string>
): ITrafficLight {
  return { summaries, status };
}

export function getTrafficLights(
  data: Partial<IVenueStatusData>,
  todaysDate?: Date,
): Array<ITrafficLight> {
  if (!data.AssessmentStartedDateTime) {
    return [
      createTrafficLight('red', 'Assessment not yet started'),
      createTrafficLight('red'),
      createTrafficLight('red'),
    ];
  }

  if (!data.AssessmentCompletedDateTime) {
    return [
      createTrafficLight(
        'amber',
        `${formatDate(data.AssessmentStartedDateTime)}: Assessment started`,
      ),
      createTrafficLight('red'),
      createTrafficLight('red'),
    ];
  }

  const assessmentTrafficLight = createTrafficLight(
    'green',
    `${formatDate(data.AssessmentStartedDateTime)}: Assessment started`,
    `${formatDate(data.AssessmentCompletedDateTime)}: Assessment completed`,
  );

  if (!data.AssessmentSubmittedDateTime) {
    return [
      assessmentTrafficLight,
      createTrafficLight('red', 'Not yet submitted for review'),
      createTrafficLight('red'),
    ];
  }

  if (data.CurrentReviewStatus === 'PENDING') {
    return [
      assessmentTrafficLight,
      createTrafficLight(
        'green',
        `${formatDate(data.AssessmentSubmittedDateTime)}: Assessment submitted`,
      ),
      createTrafficLight('amber', 'Awaiting review'),
    ];
  }

  if (data.CurrentReviewStatus === 'PEND') {
    return [
      assessmentTrafficLight,
      createTrafficLight(
        'amber',
        `${formatDate(data.AssessmentSubmittedDateTime)}: Assessment submitted`,
      ),
      createTrafficLight('amber', 'Review pended'),
    ];
  }

  if (data.CurrentReviewStatus === 'REJECTED') {
    return [
      assessmentTrafficLight,
      createTrafficLight(
        'amber',
        `${formatDate(data.AssessmentSubmittedDateTime)}: Assessment submitted`,
      ),
      createTrafficLight('red', 'Review rejected'),
    ];
  }

  if (
    data.CurrentReviewStatus === 'APPROVED' &&
    data.AssessmentApprovedDateTime &&
    data.AccreditationExpiryDateTime
  ) {
    const submittedTrafficLight = createTrafficLight(
      'green',
      `${formatDate(data.AssessmentSubmittedDateTime)}: Assessment submitted`,
    );

    const isExpired = isBefore(
      new Date(data.AccreditationExpiryDateTime),
      todaysDate ?? new Date(),
    );

    return [
      assessmentTrafficLight,
      submittedTrafficLight,
      isExpired
        ? createTrafficLight(
            'red',
            `${formatDate(data.AssessmentApprovedDateTime)}: Approved`,
            `Star rating: ${data.StarRating}`,
            `Accreditation expiry date: ${formatDate(data.AccreditationExpiryDateTime)}`,
            'Accreditation has expired',
          )
        : createTrafficLight(
            'green',
            `${formatDate(data.AssessmentApprovedDateTime)}: Approved`,
            `Star rating: ${data.StarRating}`,
            `Accreditation expiry date: ${formatDate(data.AccreditationExpiryDateTime)}`,
          ),
    ];
  }

  // If we're still here, log?
  return [];
}

export function getHtmlColor(status: TrafficLightStatus): string {
  switch (status) {
    case 'red':
      return 'red';
    case 'amber':
      return '#ffbf00';
    case 'green':
      return 'green';
    default: {
      const exhaustiveCheck: never = status;
      throw new Error(`Unexpected status: "${status}".`);
    }
  }
}

export function getStatusSummary(status: IVenueStatusData): string {
  const date = status.AssessmentApprovedDateTime
    ? formatDate(status.AssessmentApprovedDateTime)
    : 'Current';
  const stars = status.StarRating
    ? ` (${status.StarRating} ${pluralise('star', status.StarRating)})`
    : '';
  return date + stars;
}

