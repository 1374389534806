import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import { useCreateSite } from 'apollo-hooks';
import ErrorDisplay from 'components/ErrorDisplay';
import FormlyForm from 'formly/FormlyForm';
import getStaticTemplate from 'formly/staticTemplates';
import React, { useEffect } from 'react';
import { CreateSiteInputType, CreateSiteMutationVariables } from 'tillr-graphql';
import { SiteType } from 'types';

interface IProps {
  chainId: number;
  parentSiteId: number;
  siteType: SiteType;
  onCloseMenu?: (event: React.MouseEvent<HTMLButtonElement>) => void;
  inline?: boolean | undefined;
}

export function AddSite(props: IProps) {
  const { chainId, parentSiteId, siteType, onCloseMenu, inline } = props;

  const [open, setOpen] = React.useState(false);

  const [createSite, { loading, data, error }] = useCreateSite({
    getSiteAndChildrenQueryVariables: { id: chainId },
  });

  useEffect(() => {
    if (data) {
      setOpen(false);
    }
  }, [data]);

  const handleSubmit = (site: CreateSiteInputType) => {
    const variables: CreateSiteMutationVariables = {
      parentSiteId: parentSiteId,
      site: {
        name: site.name,
        type: siteType,
      },
    };
    createSite({ variables });
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleClickOpen = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.preventDefault();
    setOpen(true);
    onCloseMenu?.(event);
  };

  const siteTypeLowerCase = siteType.toLowerCase();
  const config = getStaticTemplate(`create-site-${siteTypeLowerCase}`);

  return (
    <>
      <Button
        onClick={handleClickOpen}
        variant={inline ? 'outlined' : 'text'}
        size={inline ? 'small' : 'medium'}
      >
        Add {siteTypeLowerCase}
      </Button>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="form-dialog-title"
        fullWidth
        maxWidth="xs"
      >
        <DialogTitle id="form-dialog-title">Add {siteTypeLowerCase}</DialogTitle>
        <DialogContent>
          {error && <ErrorDisplay error={error} />}
          <FormlyForm
            config={config}
            onCancel={handleClose}
            onSubmit={handleSubmit}
            submitting={loading}
            hideMandatoryFieldMessage
            redSaveButton
          />
        </DialogContent>
      </Dialog>
    </>
  );
}
