import { Dialog, DialogContent, DialogTitle } from '@material-ui/core';
import { useUpdateMyCustomData } from 'apollo-hooks';
import { useGetMyUserSettings } from 'hooks/useGetMyUserSettings';
import React, { useEffect, useState } from 'react';
import { UpdateMyCustomDataMutationVariables } from 'tillr-graphql';
import { MarketingPreferences } from 'types';
import { FormsButtons } from './FormsButtons';
import { MarketingPreferencesControl } from './MarketingPreferencesControl';
import { ProgressButton } from './ProgressButton';

export function UpdateMarketingPreferencesDialogContainer() {
  const { userSettings } = useGetMyUserSettings();

  const [open, setOpen] = useState(false);

  useEffect(() => {
    if (userSettings) {
      setOpen(!userSettings.marketing);
    }
  }, [userSettings]);

  const [updateMyUserSettings, updateMyUserSettingsState] = useUpdateMyCustomData();

  // This dialog only shows if initial value is undefined. Default to true.
  const [marketing, setMarketing] = useState<MarketingPreferences>({
    primary: true,
    parent: true,
  });

  const handleSubmit = () => {
    const variables: UpdateMyCustomDataMutationVariables = {
      customData: JSON.stringify({ ...userSettings, marketing }),
    };
    updateMyUserSettings({ variables });
  };

  return (
    <Dialog open={open} fullWidth maxWidth="xs">
      <DialogTitle>Update marketing preferences</DialogTitle>
      <DialogContent>
        <MarketingPreferencesControl value={marketing} onChange={setMarketing} />
        <FormsButtons>
          <ProgressButton
            label="Submit"
            loading={updateMyUserSettingsState.loading}
            onClick={handleSubmit}
          />
        </FormsButtons>
      </DialogContent>
    </Dialog>
  );
}

