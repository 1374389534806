import { ActivityIndicator } from 'components/ActivityIndicator';
import { ReviewStatus } from 'hooks/useGetReviewsData';
import React from 'react';
import { AnimatedCheck } from './AnimatedCheck';
import { JourneyLink } from './JourneyLink';

interface IProps {
  disabled: boolean;
  isLoading: boolean;
  assessmentStarted: boolean;
  reviewStatus: ReviewStatus | '' | undefined;
}

export function Advice(props: IProps) {
  const { disabled, isLoading, assessmentStarted, reviewStatus } = props;

  const hasSentForReview = reviewStatus !== '' && reviewStatus !== undefined;
  const isComplete = reviewStatus === 'APPROVED' || reviewStatus === 'PENDING';

  return (
    <div className={isComplete ? 'success' : 'pending'}>
      <i className="icon">{isComplete ? <AnimatedCheck /> : <i>4</i>}</i>
      <div className="details">
        <h4>View your recommendations</h4>
      </div>
      {isLoading ? (
        <ActivityIndicator />
      ) : (
        <div>
          {assessmentStarted && !hasSentForReview && (
            <p>
              You can now view your advice and recommendations to see how you might improve your
              score before applying for accreditation. Of course, you can still apply for
              accreditation with your current score.
            </p>
          )}
          <ul className="dashboard-list">
            <li>
              <JourneyLink to="/recommendations" disabled={disabled}>
                View your advice and recommendations
              </JourneyLink>
            </li>
            <li>
              <JourneyLink to="/assessment" disabled={disabled}>
                {!hasSentForReview ? 'Review or Update your assessment' : 'Review your assessment'}
              </JourneyLink>
            </li>
          </ul>
        </div>
      )}
      <div className="foot"></div>
    </div>
  );
}

