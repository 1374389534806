import React, { useContext, useState } from 'react';
import UserProfileContext from 'UserProfileContext';

export function InstructionsMessage() {
  const userProfile = useContext(UserProfileContext)!;
  const hideMessageKey = `lsavi-${userProfile.getUserId()}-assessment-info-hide`;

  const [isMessageHidden, setMessageHidden] = useState(
    localStorage.getItem(hideMessageKey) === 'true',
  );

  const hideMessage = () => {
    localStorage.setItem(hideMessageKey, 'true');
    setMessageHidden(true);
  };

  if (isMessageHidden) {
    return null;
  }

  return (
    <div className="alert alert--info alert--info-small">
      <p>
        You can complete your assessment sections in any order - jump to a different section by
        clicking on one of the sections in the progress bar below, or by clicking{' '}
        <strong>save and continue</strong> or <strong>previous</strong>. You can save your progress
        as many times as you like. Once saved, you can resume your assessment at any time.
      </p>
      <p className="align-right">
        <button type="button" className="link-button" onClick={hideMessage}>
          don't show this message again
        </button>
      </p>
    </div>
  );
}

