import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import { CHAIN_MANAGER_ROLE_ID, STANDARD_USER_ROLE_ID } from 'appGlobals';
import { AddUserFormContainer } from 'components/Shared/AddUserFormContainer';
import React from 'react';
import { SiteType, VENUE_SITE_TYPE } from 'types';

interface IProps {
  chainId: number;
  siteId: number;
  siteType: SiteType;
  onCloseMenu?: (event: React.MouseEvent<HTMLButtonElement>) => void;
  inline?: boolean | undefined;
}

export function AddUserButton(props: IProps) {
  const { chainId, siteId, siteType, onCloseMenu, inline } = props;

  const [open, setOpen] = React.useState(false);

  const handleOpen = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.preventDefault();
    setOpen(true);
    onCloseMenu?.(event);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <>
      <Button
        onClick={handleOpen}
        variant={inline ? 'outlined' : 'text'}
        size={inline ? 'small' : 'medium'}
      >
        Add user
      </Button>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="form-dialog-title"
        fullWidth
        maxWidth="xs"
      >
        <DialogTitle id="form-dialog-title">Add user</DialogTitle>
        <DialogContent>
          <AddUserFormContainer
            getUsersAdminQueryVariables={{ siteId: chainId }}
            siteId={siteId}
            roleId={siteType === VENUE_SITE_TYPE ? STANDARD_USER_ROLE_ID : CHAIN_MANAGER_ROLE_ID}
            onCancel={handleClose}
            onSuccess={handleClose}
          />
        </DialogContent>
      </Dialog>
    </>
  );
}
