import { List, ListItem, Typography } from '@material-ui/core';
import { IFormlyField } from 'formly/IFormlyConfig';
import React from 'react';
import { Link } from 'react-router-dom';

interface IProps {
  field: IFormlyField | undefined;
  isTopLevel?: boolean;
}

export function Section(props: IProps) {
  const { field, isTopLevel } = props;

  if (!field) {
    return null;
  }

  if (field.fieldGroup) {
    return (
      <List>
        {field.fieldGroup.map((question, index) => (
          <ListItem key={index}>
            <Section field={question} />
          </ListItem>
        ))}
      </List>
    );
  }

  switch (field.type) {
    case 'heading': {
      if (isTopLevel) {
        return <Typography component="h3">{field.templateOptions?.label}</Typography>;
      }
      return (
        <Typography component="h4" style={{ fontWeight: 'bold' }}>
          {field.templateOptions?.label}
        </Typography>
      );
    }
    case 'radio':
    case 'tlrScoredCheckboxes':
      return <Link to={`/admin/recommendations/${field.key}`}>{field.templateOptions?.label}</Link>;
    default:
      return null;
  }
}

