import { Typography } from '@material-ui/core';
import Alert from 'components/Shared/Alert';
import { ProgressButton } from 'components/Shared/ProgressButton';
import { downloadFile } from 'download-utils';
import { getApiBaseUrl } from 'environment-utils';
import React, { useContext, useState } from 'react';
import UserProfileContext from 'UserProfileContext';

export function DownloadKmz() {
  const userProfile = useContext(UserProfileContext);

  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<string | null>();

  const handleClick = () => {
    setError(null);
    setLoading(true);
    const url = `${getApiBaseUrl()}/downloadkmz`;
    downloadFile(url, userProfile?.authHeaderValue)
      .catch((e) => setError(e?.message ?? 'An error occurred.'))
      .finally(() => setLoading(false));
  };

  return (
    <>
      <ProgressButton onClick={handleClick} label="Download KMZ" loading={loading} normal />
      {error && (
        <Alert type="warning">
          <Typography style={{ margin: 0 }}>{error}</Typography>
        </Alert>
      )}
    </>
  );
}

