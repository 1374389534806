import { configureStore } from '@reduxjs/toolkit';
import { accountsApi } from './accounts/accountsApi';
import { api2Api } from './api2/api2Api';
import { emailServiceApi } from './emailService/emailServiceApi';

export const store = configureStore({
  reducer: {
    [accountsApi.reducerPath]: accountsApi.reducer,
    [api2Api.reducerPath]: api2Api.reducer,
    [emailServiceApi.reducerPath]: emailServiceApi.reducer,
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware().concat([
      accountsApi.middleware,
      api2Api.middleware,
      emailServiceApi.middleware,
    ]),
});

