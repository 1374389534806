import React, { useContext } from 'react';
import { Redirect } from 'react-router';
import UserManagerContext from 'UserManagerContext';

export function AuthenticatedSilentRenew() {
  const userManager = useContext(UserManagerContext)!;
  userManager.signinSilentCallback();
  // User shouldn't see this page, redirect home if they do
  return <Redirect to="/" />;
}
