import Typography from '@material-ui/core/Typography';
import { useGetMyReport } from 'apollo-hooks';
import { getReportIdBySlug, MODULE_ID } from 'appGlobals';
import { ActivityIndicator } from 'components/ActivityIndicator';
import ErrorBoundary from 'components/ErrorBoundary';
import ErrorDisplay from 'components/ErrorDisplay';
import { ReturnLink } from 'components/Shared/ReturnLink';
import React from 'react';
import { useParams } from 'react-router';
import { getReportConfig } from './IReportConfig';
import { ReportControl } from './ReportControl';

export function ReportPage() {
  const { reportSlug } = useParams<{ reportSlug: string }>();

  const reportId = getReportIdBySlug(reportSlug);

  const { loading, error, data } = useGetMyReport({ module: MODULE_ID, id: reportId });

  if (!reportId) {
    return (
      <div className="page">
        <section>
          <h3>
            Not found <ReturnLink />
          </h3>
          <p>Sorry, we can't find what you're looking for.</p>
        </section>
      </div>
    );
  }

  return (
    <div className="page">
      <section>
        {loading && <ActivityIndicator />}
        {data &&
          (data.myReport ? (
            <>
              <Typography variant="h3">
                {data.myReport.name} <ReturnLink />
              </Typography>
              {data.myReport.queryDefinition && (
                <ErrorBoundary>
                  <ReportControl
                    reportId={reportId}
                    reportConfig={getReportConfig(data.myReport.uiData)}
                    queryDefinition={data.myReport.queryDefinition}
                  />
                </ErrorBoundary>
              )}
            </>
          ) : (
            !error && (
              <p>
                <i>Not found</i>
              </p>
            )
          ))}
        {error && <ErrorDisplay error={error} />}
      </section>
    </div>
  );
}
