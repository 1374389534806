import { Grid } from '@material-ui/core';
import {
  PREMISES_INFO_LAT_FIELD_ID,
  PREMISES_INFO_LONG_FIELD_ID,
  PREMISES_INFO_POSTCODE_FIELD_ID,
  PREMISES_INFO_PREMISES_NAME_FIELD_ID,
} from 'appGlobals';
import { IComponentProps } from 'formly/IComponentProps';
import { usePostcodeLookup } from 'hooks/usePostcodeLookup';
import React, { ChangeEvent, KeyboardEventHandler, useState } from 'react';
import { Link } from 'react-router-dom';

export function PostcodeLookup(props: IComponentProps) {
  const { field, readonly, onValueUpdate } = props;

  const { search, searching, results, error } = usePostcodeLookup();

  const [haveSearched, setHaveSearched] = useState(false);

  // This is hardcoded specific to the LSAVI premises form
  const keys = {
    trigger: 'd0a91057-2aad-4d3e-a74a-977e209538bd',
    buildingName: PREMISES_INFO_PREMISES_NAME_FIELD_ID,
    line1: '8129b632-50ac-4eb3-8f28-5bbf50d80fc9',
    line2: 'b189408c-aee4-44c0-aee8-f8bf83666581',
    town: 'e1ea30a0-38d7-4b21-8da8-43fe7489eac9',
    postcode: PREMISES_INFO_POSTCODE_FIELD_ID,
    latitude: PREMISES_INFO_LAT_FIELD_ID,
    longitude: PREMISES_INFO_LONG_FIELD_ID,
  };

  const [inputPostcode, setInputPostcode] = useState('');

  const handleChangePostcode = (e: ChangeEvent<HTMLInputElement>) => {
    setInputPostcode(e.currentTarget.value);
  };

  const handleSearch = () => {
    setHaveSearched(true);
    search(inputPostcode);
  };

  const handleKeyUp: KeyboardEventHandler<HTMLInputElement> = (e) => {
    if (e.key === 'Enter') {
      handleSearch();
    }
  };

  const handleManualEntry = () => {
    onValueUpdate(keys.trigger, 'manual');
  };

  const handleChangeAddress = (e: ChangeEvent<HTMLSelectElement>) => {
    const address = results!.addresses[Number(e.currentTarget.value)];
    if (address.buildingName) {
      onValueUpdate(keys.buildingName, address.buildingName);
    }
    onValueUpdate(keys.line1, address.line1);
    onValueUpdate(keys.line2, address.line2);
    onValueUpdate(keys.town, address.town);
    onValueUpdate(keys.postcode, address.postcode);
    onValueUpdate(keys.latitude, address.latitude);
    onValueUpdate(keys.longitude, address.longitude);
    onValueUpdate(keys.trigger, address.postcode);
  };

  return (
    <Grid
      item
      xs={12}
      sm={field.className?.match(/col-xs-6/) ? 6 : 12}
      className={
        field.className?.match(/pull-right/)
          ? 'tillr-form-grid-element tillr-form-grid-element--align-right'
          : 'tillr-form-grid-element'
      }
    >
      {readonly ? (
        <p>
          You are not able to edit your premises address once it has been submitted. If you need to
          make a change, please{' '}
          <Link to="/contact" style={{ color: '#00a0e4', display: 'inline', paddingLeft: 0 }}>
            contact support
          </Link>
          .
        </p>
      ) : (
        <div className="lsavi-postcode-lookup">
          <div className="lsavi-postcode-lookup__search">
            <div className="formly-field formly-builder-element col-xs-12 col-sm-12 formly-field-input">
              <div className="form-group">
                <div>
                  <label
                    htmlFor="lsavi-postcode-entry"
                    className={`control-label ${readonly ? 'friendly-readonly' : ''}`}
                  >
                    {field.templateOptions?.label}
                  </label>
                  <input
                    id="lsavi-postcode-entry"
                    name="lsavi-postcode-entry"
                    type="text"
                    className={`form-control ${readonly ? 'friendly-readonly' : ''}`}
                    value={inputPostcode}
                    disabled={readonly}
                    onChange={handleChangePostcode}
                    onKeyUp={handleKeyUp}
                  />
                </div>
              </div>
            </div>

            <div>
              {inputPostcode && (
                <button
                  type="button"
                  onClick={handleSearch}
                  className="mdl-button mdl-js-button mdl-js-ripple-effect"
                >
                  {searching ? 'Searching...' : 'Find my premises'}
                </button>
              )}
            </div>
          </div>
          {error && <p>{error}</p>}

          {!searching && (
            <>
              {haveSearched ? (
                <div>
                  {results && results.totalHits > 0 ? (
                    <>
                      <p>
                        <span>{results.totalHits}</span> premises found. Please select your address
                        below.
                      </p>
                      <select className="form-control" onChange={handleChangeAddress}>
                        <option value="">Please select address</option>
                        {results.addresses.map(({ name }, index) => (
                          <option key={name} value={index}>
                            {name}
                          </option>
                        ))}
                      </select>
                    </>
                  ) : (
                    <p>
                      No premises found.{' '}
                      <button type="button" className="link-button" onClick={handleManualEntry}>
                        Please enter your address manually
                      </button>
                      .
                    </p>
                  )}
                </div>
              ) : (
                <div>
                  <p>
                    <button type="button" className="link-button" onClick={handleManualEntry}>
                      Or enter your address manually.
                    </button>
                  </p>
                </div>
              )}
            </>
          )}
        </div>
      )}
    </Grid>
  );
}

