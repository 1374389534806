import { PageTitle } from 'components/Shared/PageTitle';
import React from 'react';

interface IProps {
  title: string;
  crumbs?: Array<{ name: string; to: string }>;
}

export function AdminPageTitle(props: IProps) {
  const { title, crumbs } = props;

  return (
    <PageTitle
      title={title}
      crumbs={[{ name: 'Admin Dashboard', to: '/admin' }].concat(crumbs ?? [])}
    />
  );
}

