import { useCreateForm, useGetFormTemplateAndInstances } from 'apollo-hooks';
import { END_USER_AGREEMENT_TEMPLATE_ID, MODULE_ID } from 'appGlobals';
import { ActivityIndicator } from 'components/ActivityIndicator';
import { DocumentModal } from 'components/Documents/DocumentModal';
import SiteContext from 'components/Sites/SiteContext';
import FormlyForm from 'formly/FormlyForm';
import { IFormlyConfig } from 'formly/IFormlyConfig';
import { IModel } from 'formly/IModel';
import React, { useContext, useEffect, useState } from 'react';
import { useHistory } from 'react-router';
import { FormOrderBy, PaginationOrder } from 'tillr-graphql';
import { generateUuid } from 'utils';
import { EndUserAgreementContent } from './EndUserAgreementContent';

export function EndUserAgreementPage() {
  const history = useHistory();

  const { siteId } = useContext(SiteContext)!;
  const module = MODULE_ID;

  const { loading, data, refetch } = useGetFormTemplateAndInstances({
    siteId,
    module,
    formTemplateId: END_USER_AGREEMENT_TEMPLATE_ID,
    paginationProps: {
      pageSize: 999,
      orderBy: FormOrderBy.CreatedDateTime,
      order: PaginationOrder.Desc,
    },
  });

  const [formlyConfig, setFormlyConfig] = useState<IFormlyConfig>();
  const [formId, setFormId] = useState<string>();
  const [model, setModel] = useState<IModel>();

  useEffect(() => {
    if (data?.formTemplate) {
      setFormlyConfig(JSON.parse(data.formTemplate.template));
    }
    const mostRecentForm = data?.forms?.items[0];
    if (mostRecentForm) {
      setFormId(mostRecentForm.id);
      setModel(JSON.parse(mostRecentForm.formDataJson));
    }
  }, [data]);

  const [createForm, createMutationState] = useCreateForm();

  const mode = formId ? 'readonly' : 'create';

  const handleClose = () => {
    if (mode === 'create' && model) {
      const entries = Object.entries(model);
      if (entries.length > 0) {
        if (entries.length > 1) {
          console.warn('Unexpected model', model);
        }

        if (entries[0][1] === true) {
          // Accepted the agreement - save and return so we don't redirect yet
          createForm({
            variables: {
              siteId,
              module,
              form: {
                id: generateUuid(),
                templateId: END_USER_AGREEMENT_TEMPLATE_ID,
                name: data?.formTemplate?.name,
                formDataJson: JSON.stringify(model),
                isSubmitted: true,
              },
            },
          }).then(() => {
            refetch();
          });
          return;
        }
      }
    }

    history.push('/journey');
  };

  useEffect(() => {
    if (createMutationState.data?.createForm) {
      // Redirect on successful save
      history.push('/journey');
    }
  }, [createMutationState.data, history]);

  return (
    <DocumentModal
      title="End User Agreement"
      content={
        <>
          {loading && <ActivityIndicator showProgressBar />}
          {formlyConfig && <EndUserAgreementContent />}
        </>
      }
      actions={
        formlyConfig && (
          <div className={`confirmation ${formId ? 'confirmation--disabled' : ''}`}>
            <FormlyForm
              model={model}
              config={formlyConfig}
              mode={mode}
              customButtons
              onChange={setModel}
              hideMandatoryFieldMessage
            />
            <div className="buttons">
              <button
                id="submit-button"
                className="mdl-button mdl-js-button mdl-button--raised mdl-js-ripple-effect tlr-btn-submit"
                disabled={createMutationState.loading}
                type="button"
                onClick={handleClose}
              >
                close
              </button>
            </div>
          </div>
        )
      }
    />
  );
}

