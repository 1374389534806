import { SignOutButton } from 'auth/SignOutButton';
import { FacebookIcon } from 'components/Icons/FacebookIcon';
import { InstagramIcon } from 'components/Icons/InstagramIcon';
import { LinkedInIcon } from 'components/Icons/LinkedInIcon';
import { TwitterIcon } from 'components/Icons/TwitterIcon';
import { ExternalLink } from 'components/Shared/ExternalLink';
import React, { useContext } from 'react';
import { Link } from 'react-router-dom';
import UserProfileContext from 'UserProfileContext';

export default function Header() {
  const userProfile = useContext(UserProfileContext);

  return (
    <header role="banner">
      {userProfile && (
        <div className="signed-in-as hide-print">
          <div className="signed-in-as__inner">
            <div>
              <p className="hide-print">
                <span>Signed in as&nbsp;</span>
                {userProfile.email} - <SignOutButton />
              </p>
            </div>
            <ul className="socials">
              <li>
                <ExternalLink href="https://www.facebook.com/LicensingSAVI/" ariaLabel="Facebook">
                  <FacebookIcon />
                </ExternalLink>
              </li>
              <li>
                <ExternalLink
                  href="https://www.linkedin.com/company/licensing-savi/"
                  ariaLabel="LinkedIn"
                >
                  <LinkedInIcon />
                </ExternalLink>
              </li>
              <li>
                <ExternalLink href="https://twitter.com/LicensingSAVI" ariaLabel="Twitter">
                  <TwitterIcon />
                </ExternalLink>
              </li>
              <li>
                <ExternalLink href="https://www.instagram.com/licensingsavi/" ariaLabel="Instagram">
                  <InstagramIcon />
                </ExternalLink>
              </li>
            </ul>
          </div>
        </div>
      )}

      <div className="main">
        <div>
          <h1>
            <Link to="/">
              <img
                src="/app/images/lsavi-logo.svg"
                alt="Licensing SAVI - Security &amp; Vulnerability Initiative"
              />
            </Link>
          </h1>
          {/* <h6>
            <a href="https://tillr.io" target="_blank" title="Visit Tillr (opens in a new tab)">
              <span>Powered by</span>
              <img src="/app/images/tillr-powered.svg" alt="Powered by Tillr" />
            </a>
          </h6> */}
        </div>
      </div>
    </header>
  );
}

