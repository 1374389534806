import { useGetLibraryFolder } from 'apollo-hooks';
import { VERIFIERS_FOLDER_ID } from 'appGlobals';
import { useEffect, useState } from 'react';
import { extractErrors, getAttachmentItemUrl } from 'utils';

export type CouncilData = {
  name: string;
  policeAuthorityEmail: string;
  localCouncilEmail: string;
};

export type ReviewersData = {
  [policeAuthority: string]: {
    councils: Array<CouncilData>;
  };
};

export function useGetReviewersData() {
  const getLibraryFolderState = useGetLibraryFolder({ folderId: VERIFIERS_FOLDER_ID });

  const [downloading, setDownloading] = useState(false);
  const [lastModifiedDate, setLastModifiedDate] = useState<Date>();
  const [data, setData] = useState<ReviewersData>();
  const [downloadError, setDownloadError] = useState<string>();

  useEffect(() => {
    if (getLibraryFolderState.data?.libraryFolder) {
      setDownloading(true);

      const { libraryFolder } = getLibraryFolderState.data;
      const latestFile = libraryFolder.files
        .slice()
        .sort((a, b) => b.createdDateTime.localeCompare(a.createdDateTime))[0];

      const fileUrl = getAttachmentItemUrl(
        libraryFolder.downloadFileUrlTemplate,
        latestFile.id,
        latestFile.name,
      );

      setLastModifiedDate(new Date(latestFile.createdDateTime));

      fetch(fileUrl)
        .then((response) => response.json())
        .then((data) => {
          setData(data);
        })
        .catch((reason: Error) => {
          setDownloadError(
            'Sorry but we are unable to display this form at the moment. Please try again later.',
          );
        })
        .finally(() => {
          setDownloading(false);
        });
    }
  }, [getLibraryFolderState]);

  return {
    loading: getLibraryFolderState.loading || downloading,
    lastModifiedDate,
    data,
    error:
      (getLibraryFolderState.error && extractErrors(getLibraryFolderState.error)[0]) ??
      downloadError,
  };
}

