import { useGetMySites } from 'apollo-hooks';
import { ActivityIndicator } from 'components/ActivityIndicator';
import ErrorDisplay from 'components/ErrorDisplay';
import React, { useContext } from 'react';
import { Redirect } from 'react-router';
import { CHAIN_SITE_TYPE, GROUP_SITE_TYPE } from 'types';
import UserProfileContext from 'UserProfileContext';

export function ChainsHomePage() {
  const userProfile = useContext(UserProfileContext)!;

  const { loading, error, data } = useGetMySites();

  if (userProfile.siteIds.length === 0) {
    return <ErrorDisplay error="User has no chains." />;
  }

  if (loading) {
    return (
      <div className="page">
        <section>
          <ActivityIndicator />
        </section>
      </div>
    );
  }
  if (error) {
    return (
      <div className="page">
        <section>
          <ErrorDisplay error={error} />
        </section>
      </div>
    );
  }

  if (!data?.mySites || data.mySites.length === 0) {
    return (
      <div className="page">
        <section>
          <ErrorDisplay error="User has no chains." />
        </section>
      </div>
    );
  }

  const topLevel = data.mySites.filter(
    (x) =>
      (x.type === CHAIN_SITE_TYPE || x.type === GROUP_SITE_TYPE) &&
      (x.parentSiteId == null || !userProfile.siteIds.includes(x.parentSiteId)),
  );

  if (topLevel.length === 0) {
    return (
      <div className="page">
        <section>
          <ErrorDisplay error="User has no chains." />
        </section>
      </div>
    );
  }

  return <Redirect to={`/chains/${topLevel[0].id}`} />;
}

