import React from 'react';

export default function MandatoryFieldMessage() {
  return (
    <div className="required-fields-info">
      <p>
        <span>*</span> Indicates required field
      </p>
    </div>
  );
}

