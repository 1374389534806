import { Grid } from '@material-ui/core';
import { IComponentProps } from 'formly/IComponentProps';
import React, { useEffect } from 'react';
import HelpText from './shared/HelpText';
import { getRuleMessage } from './shared/Validation';

export default function PickList(props: IComponentProps) {
  const {
    field,
    readonly,
    modelValue,
    onValueUpdate,
    register,
    errors,
    setValue,
    getValues,
    setError,
    clearErrors,
    formState,
    customFunctions,
  } = props;

  const customTrigger = () => {
    const values = getValues();
    if (
      field.templateOptions?.required &&
      field.key &&
      (values[field.key] === undefined || values[field.key] === '')
    ) {
      setError(field.key, {
        type: 'manual',
        message: getRuleMessage('required'),
      });
      return;
    }
    clearErrors(field.key);
  };

  useEffect(() => {
    register({ name: field.key });
    setValue(field.key, modelValue);
    customTrigger();
    // TODO: If I remove the empty array, useEffect keeps getting called. Isn't the
    // idea that an empty array will only call useEffect once?
    // eslint-disable-next-line
  }, []);

  if (!field.templateOptions?.options?.length) {
    // eslint-disable-next-line no-console
    console.warn(`FORMLY: Select without options: ${field.key}`);
    return null;
  }

  const handleChange = (event: React.ChangeEvent<{ value: unknown }>) => {
    if (field.key) {
      onValueUpdate(field.key, event.target.value);
      setValue(field.key!, event.target.value !== 'undefined' ? event.target.value : '', {
        shouldDirty: true,
      });
      customTrigger();
      // TODO: Review - This was NOT how it was done originally
      // but there is a package on UAT (and perhaps prod) that
      // is stopping the original, better way from working
      if (field.data?.editsInstanceName === true && customFunctions && customFunctions.length) {
        if (typeof customFunctions[0] === 'function') {
          customFunctions[0](
            'editNameViaForm',
            (event.target.value !== 'undefined' ? event.target.value : '') as string,
          );
        }
      }
    }
  };

  return (
    <>
      {field.className?.match(/clear/) && (
        <hr className="tillr-form-grid-element tillr-form-grid-element--break" />
      )}
      <Grid
        item
        xs={12}
        sm={field.className?.match(/col-xs-6/) ? 6 : 12}
        className={
          field.className?.match(/pull-right/)
            ? 'tillr-form-grid-element tillr-form-grid-element--align-right'
            : 'tillr-form-grid-element'
        }
      >
        <label
          htmlFor={field.key}
          className={`control-label ${readonly ? 'friendly-readonly' : ''}`}
        >
          {field.templateOptions?.required && !readonly && (
            <>
              <span className="required-field">*</span>{' '}
            </>
          )}
          {field.templateOptions?.label}
        </label>

        <select
          id={field.key}
          className="form-control"
          defaultValue={modelValue}
          onChange={handleChange}
          disabled={readonly}
        >
          <option value="">{field.data?.prompt}</option>
          {field.templateOptions.options.map(({ value, name }, index) => {
            const optionValue = typeof value !== 'undefined' ? value : name;
            return (
              <option key={index} value={optionValue}>
                {name}
              </option>
            );
          })}
        </select>

        {/* {hasFieldError(field.key!, errors, formState) && (
          <FieldValidationMessage message={errors[field.key!].message} />
        )} */}
        {field.data?.help && <HelpText value={field.data?.help} />}
      </Grid>
    </>
  );
}

