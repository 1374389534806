import { TillrModal } from 'components/Shared/TillrModal';
import React, { useState } from 'react';
import { useHistory } from 'react-router';

interface IProps {
  position: 'top' | 'bottom';
  locked: boolean;
  step: number;
  totalSteps: number;
  formIsPristine?: boolean;
  loading: boolean;
  cancelButton: string;
  onPreviousStep: () => void;
  onNextStep: () => void;
  onSave: (andContinue: boolean) => void;
}

export function ActionButtons(props: IProps) {
  const {
    position,
    locked,
    step,
    totalSteps,
    formIsPristine,
    loading,
    cancelButton,
    onPreviousStep,
    onNextStep,
    onSave,
  } = props;

  const history = useHistory();
  const [confirmCancel, setConfirmCancel] = useState(false);

  const handleConfirmCancel = () => {
    history.push('/');
  };

  return (
    <>
      <div className="form-button-container">
        <div className="form-button-container--body">
          <div className="form-button-container--left">
            <button
              id={`${position}-previous-button`}
              type="button"
              className="mdl-button mdl-js-button mdl-js-ripple-effect"
              disabled={step === 0 || loading}
              onClick={() => onPreviousStep()}
            >
              Previous
            </button>
          </div>
          {!locked ? (
            <div className="form-button-container--right">
              <button
                id={`${position}-cancel-button"`}
                type="button"
                className="mdl-button mdl-js-button mdl-js-ripple-effect"
                onClick={() => setConfirmCancel(true)}
              >
                {cancelButton}
              </button>
              <button
                id={`${position}-save-return-button`}
                type="button"
                className="mdl-button mdl-js-button mdl-button--raised mdl-js-ripple-effect"
                disabled={formIsPristine || loading}
                onClick={() => onSave(false)}
              >
                Save and return to your journey
              </button>
              <button
                id={`${position}-save-continue-button`}
                className="mdl-button mdl-js-button mdl-button--raised mdl-js-ripple-effect"
                type="button"
                disabled={formIsPristine || step === totalSteps - 1 || loading}
                onClick={() => onSave(true)}
              >
                Save and continue
              </button>
            </div>
          ) : (
            <div className="form-button-container--right">
              <button
                id={`${position}-next-button"`}
                type="button"
                className="mdl-button mdl-js-button mdl-js-ripple-effect"
                disabled={step === totalSteps - 1}
                onClick={() => onNextStep()}
              >
                Next
              </button>
            </div>
          )}
        </div>
      </div>
      {confirmCancel && (
        <TillrModal
          title="Are you sure?"
          okButtonText="Yes"
          onOk={handleConfirmCancel}
          cancelButtonText="No"
          onCancel={() => setConfirmCancel(false)}
        >
          Any changes you have made will be lost. Select Yes to return to the your journey without
          saving. Select No to close this message and continue with your Assessment.
        </TillrModal>
      )}
    </>
  );
}

