import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { pluralise } from 'utils';
import { AdviceBlock } from './AdviceBlock';

interface IProps {
  isCritical: boolean;
  index: number;
  section: any[];
}

export function Section(props: IProps) {
  const { isCritical, index, section } = props;

  const [collapsed, setCollapsed] = useState(true);
  const toggleCollapsed = () => setCollapsed((prevCollapsed) => !prevCollapsed);

  const handlePrint = () => {
    const groups = document.querySelectorAll('.collapsable-groups__group');
    if (groups && groups[index]) {
      Array.from(groups).forEach((group) => {
        group.classList.remove('print-friendly-temp');
      });
      groups[index].classList.add('print-friendly-temp');
    }
    window.print();
  };

  const numberOfQuestions = section.length;

  return (
    <div
      className={`collapsable-groups__group ${
        isCritical || !collapsed ? 'collapsable-groups__group--visible' : ''
      }`}
    >
      <div className={`collapsable-groups__head stars${index}`} id={`group-header-${index}`}>
        {!isCritical ? (
          <div className="collapsable-groups__head-title">
            <h3>
              <span className="text">
                <span>{numberOfQuestions}</span>
                {pluralise(' response', numberOfQuestions)} scored
              </span>
              <span className="stars">
                <i className={`glyphicon glyphicon-star ${index > 0 ? 'star-on' : ''}`} />
                <i className={`glyphicon glyphicon-star ${index > 1 ? 'star-on' : ''}`} />
                <i className={`glyphicon glyphicon-star ${index > 2 ? 'star-on' : ''}`} />
                <i className={`glyphicon glyphicon-star ${index > 3 ? 'star-on' : ''}`} />
                <i className={`glyphicon glyphicon-star ${index > 4 ? 'star-on' : ''}`} />
              </span>
              <span className="text print-friendly">
                <span>{` ${index}`}</span>
                {pluralise(' star', index)}
              </span>
            </h3>
          </div>
        ) : (
          <div className="collapsable-groups__head-title">
            <h3>
              You have <span>{numberOfQuestions}</span>
              {pluralise(' point', numberOfQuestions)} requiring your attention
            </h3>
          </div>
        )}

        <div className="collapsable-groups__head-status">
          {!isCritical && (
            <button
              disabled={numberOfQuestions < 1}
              type="button"
              className="print mdl-button mdl-js-button mdl-button--raised mdl-js-ripple-effect hide-print"
              onClick={handlePrint}
            >
              print
            </button>
          )}
        </div>
        <div className="collapsable-groups__head-toggle">
          {!isCritical ? (
            <div>
              <button
                disabled={numberOfQuestions < 1}
                type="button"
                className="mdl-button mdl-js-button mdl-button--raised mdl-js-ripple-effect"
                onClick={toggleCollapsed}
              >
                {collapsed ? 'view' : 'hide'}
              </button>
            </div>
          ) : (
            <button
              disabled={numberOfQuestions < 1}
              type="button"
              className="print mdl-button mdl-js-button mdl-button--raised mdl-js-ripple-effect hide-print"
              onClick={handlePrint}
            >
              print
            </button>
          )}
        </div>
      </div>
      <div className="collapsable-groups__body">
        {section.map((question, questionIndex) => (
          <div key={questionIndex}>
            <div className="recommendations-checkboxes">
              <h5>
                <span>Question:</span> {question[0]}
              </h5>

              {question[6].length > 0 && (
                <div>
                  <h6>You selected:</h6>
                  <ul>
                    {question[6].map((answer: any, answerIndex: any) => (
                      <li key={answerIndex}>{answer}</li>
                    ))}
                  </ul>
                </div>
              )}
              {question[7].length > 0 && (
                <div>
                  <h6>You did not select:</h6>
                  <ul>
                    {question[7].map((answer: any, answerIndex: any) => (
                      <li key={answerIndex}>{answer}</li>
                    ))}
                  </ul>
                </div>
              )}

              {question[5] >= 10 && (
                <div>
                  <p className="max-score">You have achieved the maximum score for this question</p>
                </div>
              )}

              {question[8].length > 0 && <AdviceBlock adviceContents={question[8]} />}

              <p>
                <Link
                  to={`/assessment?question=${question[question.length - 1]}`}
                  className={`go-to-link ${isCritical ? 'critical-link' : ''}`}
                >
                  {isCritical
                    ? 'Update your answer in the assessment'
                    : 'View question in assessment'}
                </Link>
              </p>

              <hr />
            </div>
          </div>
        ))}
      </div>
    </div>
  );
}

