import React from 'react';
import { AnimatedCheck } from './AnimatedCheck';

export function Registered() {
  return (
    <div className="success">
      <i className="icon">
        <AnimatedCheck />
      </i>
      <div className="details">
        <h4>Welcome!</h4>
      </div>
      <p>
        Welcome to Licensing SAVI. You have taken the first step on your journey, in joining a
        rapidly growing number of Licensed Premises across the country, proudly displaying their
        Star rating, showing to their customers &amp; staff, how seriously they take their Safety
        &amp; Vulnerability.
      </p>
      <div className="foot"></div>
    </div>
  );
}

