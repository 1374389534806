import { useGetSiteAdmin } from 'apollo-hooks';
import React from 'react';
import { useParams } from 'react-router-dom';
import { AdminPageTitle } from '../Admin/AdminPageTitle';
import { PremisesInfoAdminContainer } from './PremisesInfoAdminContainer';

export function PremisesInfoAdminPage() {
  const { venueId } = useParams<{ venueId: string }>();
  const siteId = Number(venueId);

  const getSiteState = useGetSiteAdmin({ id: siteId });

  return (
    <>
      <AdminPageTitle
        title="Premises info"
        crumbs={[
          { name: 'Manage venues', to: '/admin/venues' },
          {
            name: getSiteState.data?.siteAdmin?.name ?? 'Manage venue',
            to: `/admin/venues/${siteId}`,
          },
        ]}
      />
      <div className="page">
        <section>
          <PremisesInfoAdminContainer
            venueId={siteId}
            returnButtonLabel="Return to venue"
            returnUrl={`/admin/venues/${venueId}`}
          />
        </section>
      </div>
    </>
  );
}

