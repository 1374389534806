import React from 'react';
import { Origin } from 'types';

interface IProps {
  origin: Origin;
}

export function SupportedBy(props: IProps) {
  const { origin } = props;

  return (
    <>
      <p>Supported by:</p>
      {origin === 'westyorkshire' && (
        <div>
          <div className="lsavi-image">
            <img src="/app/images/wyvru.png" alt="West Yorkshire Violence Reduction Unit" />
          </div>
          <div className="lsavi-image">
            <img
              src="/app/images/wystp.png"
              alt="West Yorkshire and Harrogate Sustainability Transformation Plan"
            />
          </div>
        </div>
      )}
      <div className="lsavi-image">
        <img src="/app/images/npcc.png" alt="National Police Chiefs' Council" />
      </div>
      <div className="lsavi-image">
        <img src="/app/images/servator.png" alt="Project Servator" />
      </div>
      <div className="lsavi-image">
        <img src="/app/images/scan-see-check-notify.png" alt="Scan - Sea, Check and Notify" />
      </div>
    </>
  );
}

