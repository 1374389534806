import { createStyles, makeStyles } from '@material-ui/core/styles';
import { ManageFieldRecommendationsPage } from 'components/Admin/Recommendations/ManageFieldRecommendationsPage';
import { ManageRecommendationsPage } from 'components/Admin/Recommendations/ManageRecommendationsPage';
import { AssessmentViewPage } from 'components/AssessmentView/AssessmentViewPage';
import { AssessmentViewPrintPage } from 'components/AssessmentView/AssessmentViewPrintPage';
import { RecommendationsPage } from 'components/Recommendations/RecommendationsPage';
import { ReportPage } from 'components/Reports/ReportPage';
import { PremisesInfoAdminPage } from 'components/Venues/PremisesInfoAdminPage';
import React, { useEffect, useState } from 'react';
import { Route, Switch, useLocation } from 'react-router';
import { Authenticate } from './auth/Authenticate';
import { Authenticated } from './auth/Authenticated';
import { AuthenticatedRoute } from './auth/AuthenticatedRoute';
import { AuthenticatedSilentRenew } from './auth/AuthenticatedSilentRenew';
import { Logout } from './auth/Logout';
import { Unauthorised } from './auth/Unauthorised';
import { AccreditationPage } from './components/Accreditation/AccreditationPage';
import { AdminPage } from './components/Admin/AdminPage';
import { ManageChainsPage } from './components/Admin/Chains/ManageChainsPage';
import { UpdateVerifiersPage } from './components/Admin/Verifiers/UpdateVerifiersPage';
import { VerifiersPage } from './components/Admin/Verifiers/VerifiersPage';
import { AssessmentPage } from './components/Assessment/AssessmentPage';
import { AssessmentPrintPage } from './components/Assessment/AssessmentPrintPage';
import BuildNumbersDisplay from './components/BuildNumbers/BuildNumbersDisplay';
import { ChainRoutes } from './components/Chains/ChainRoutes';
import { ChainsHomePage } from './components/Chains/ChainsHomePage';
import { ContactUsPage } from './components/ContactUs/ContactUsPage';
import { EndUserAgreementPage } from './components/EndUserAgreement/EndUserAgreementPage';
import { JourneyPage } from './components/Journey/JourneyPage';
import { LandingPage } from './components/LandingPage';
import { PremisesInfoPage } from './components/PremisesInfo/PremisesInfoPage';
import { CounterTerrorismPage } from './components/Resources/CounterTerrorismPage';
import { EndUserAgreementResourcePage } from './components/Resources/EndUserAgreementResourcePage';
import { WelcomeLetterPage } from './components/Resources/WelcomeLetterPage';
import { ResponsibleAuthorityPage } from './components/ResponsibleAuthority/ResponsibleAuthorityPage';
import { ReviewPage } from './components/Review/ReviewPage';
import { ManageVenuePage } from './components/Venues/ManageVenuePage';
import { ManageVenuesPage } from './components/Venues/ManageVenuesPage';

const useStyles = makeStyles(() =>
  createStyles({
    root: {
      position: 'fixed',
      overflow: 'hidden',
      top: 0,
      left: 0,
      width: '100%',
      height: '100%',
      backgroundColor: '#dedede',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      zIndex: 2147483647,
    },
  }),
);

const Routes = () => {
  const loadingTimer: number = 0;
  const location = useLocation();
  const [loaded, setLoaded] = useState(false);
  const classes = useStyles();

  useEffect(() => {
    let timer: ReturnType<typeof setTimeout> | null = null;
    if (timer) {
      clearTimeout(timer);
    }
    if (!loaded) {
      timer = setTimeout(() => {
        setLoaded(true);
      }, loadingTimer);
    }
    return () => {
      if (timer) clearTimeout(timer);
    };
  }, [location, loaded]);

  function LoadingCloak() {
    return (
      <div className={classes.root}>
        <div className="tillr-loading">
          <div />
          <div />
          <div />
          <div />
        </div>
      </div>
    );
  }

  return (
    <>
      {!loaded && <LoadingCloak />}
      <Switch>
        <Route exact path="/" component={LandingPage} />
        <Route exact path="/partners" component={LandingPage} />
        <Route exact path="/RA" component={LandingPage} />
        <Route exact path="/westyorkshire" component={LandingPage} />

        <Route exact path="/status" component={BuildNumbersDisplay} />
        <Route exact path="/authenticate" component={Authenticate} />
        <Route exact path="/authenticated" component={Authenticated} />
        <Route exact path="/authenticated/silent-renew" component={AuthenticatedSilentRenew} />
        <Route path="/unauthorised" component={Unauthorised} />
        <Route path="/register" component={LandingPage} />
        <Route path="/logout" component={Logout} />

        <Route path="/contact" component={ContactUsPage} />

        <AuthenticatedRoute exact path="/journey" component={JourneyPage} />
        <AuthenticatedRoute exact path="/premises-info" component={PremisesInfoPage} />
        <AuthenticatedRoute exact path="/end-user-agreement" component={EndUserAgreementPage} />
        <AuthenticatedRoute exact path="/assessment" component={AssessmentPage} />
        <AuthenticatedRoute exact path="/assessment/print" component={AssessmentPrintPage} />
        <AuthenticatedRoute exact path="/recommendations" component={RecommendationsPage} />
        <AuthenticatedRoute exact path="/accreditation" component={AccreditationPage} />

        <AuthenticatedRoute
          exact
          path="/responsible-authority"
          component={ResponsibleAuthorityPage}
        />

        <AuthenticatedRoute
          exact
          path="/resources/counter-terrorism"
          component={CounterTerrorismPage}
        />

        <AuthenticatedRoute
          exact
          path="/resources/end-user-agreement"
          component={EndUserAgreementResourcePage}
        />
        <AuthenticatedRoute exact path="/resources/welcome-letter" component={WelcomeLetterPage} />

        <AuthenticatedRoute
          exact
          permissions={['Admin', 'Venues.Admin']}
          path="/admin"
          component={AdminPage}
        />

        <AuthenticatedRoute
          exact
          permissions={['Admin', 'Chain.Manage']}
          path="/assessment/:siteId/:formId"
          component={AssessmentViewPage}
        />

        <AuthenticatedRoute
          exact
          permissions={['Admin', 'Chain.Manage']}
          path="/assessment/:siteId/:formId/print"
          component={AssessmentViewPrintPage}
        />

        <AuthenticatedRoute
          exact
          permissions={['Admin', 'Venues.Admin']}
          path="/admin/chains"
          component={ManageChainsPage}
        />

        <AuthenticatedRoute
          exact
          permissions={['Admin']}
          path="/admin/recommendations"
          component={ManageRecommendationsPage}
        />

        <AuthenticatedRoute
          exact
          permissions={['Admin']}
          path="/admin/recommendations/:fieldId"
          component={ManageFieldRecommendationsPage}
        />

        <AuthenticatedRoute
          exact
          permissions={['Admin', 'Venues.Admin']}
          path="/admin/venues"
          component={ManageVenuesPage}
        />

        <AuthenticatedRoute
          exact
          permissions={['Admin', 'Venues.Admin']}
          path="/admin/venues/:venueId"
          component={ManageVenuePage}
        />

        <AuthenticatedRoute
          exact
          permissions={['Admin', 'Venues.Admin']}
          path="/admin/venues/:venueId/premises-info"
          component={PremisesInfoAdminPage}
        />

        <AuthenticatedRoute
          exact
          permissions={['Admin']}
          path="/admin/verifiers"
          component={VerifiersPage}
        />
        <AuthenticatedRoute
          exact
          permissions={['Admin']}
          path="/admin/verifiers/update"
          component={UpdateVerifiersPage}
        />

        <AuthenticatedRoute
          permissions={['Admin', 'Venues.Admin']}
          path="/admin/reports/:reportSlug"
          component={ReportPage}
        />

        <AuthenticatedRoute
          exact
          permissions={['Chain.Manage']}
          path="/chains"
          component={ChainsHomePage}
        />

        <AuthenticatedRoute path="/chains/:chainId/:action?" component={ChainRoutes} />

        <AuthenticatedRoute
          exact
          permissions={['Reviewer']}
          path="/review"
          component={ReviewPage}
        />
      </Switch>
    </>
  );
};

export default Routes;
