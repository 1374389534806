import React, { useContext, useState } from 'react';
import UserManagerContext from 'UserManagerContext';

export function SignInButton() {
  const userManager = useContext(UserManagerContext)!;

  const [isRedirecting, setRedirecting] = useState(false);

  const handleClick = () => {
    userManager.signinRedirect();
    setRedirecting(true);
  };

  return (
    <button
      onClick={handleClick}
      className="mdl-button mdl-js-button mdl-js-ripple-effect"
      type="button"
      id="start-here-1"
      disabled={isRedirecting}
    >
      {isRedirecting ? 'Redirecting...' : 'Sign in'}
    </button>
  );
}

