import React from 'react';

export function FacebookIcon() {
  return (
    <span uk-icon="icon: facebook;" className="uk-icon">
      <svg width="20" height="20" viewBox="0 0 20 20">
        <path d="M11,10h2.6l0.4-3H11V5.3c0-0.9,0.2-1.5,1.5-1.5H14V1.1c-0.3,0-1-0.1-2.1-0.1C9.6,1,8,2.4,8,5v2H5.5v3H8v8h3V10z"></path>
      </svg>
    </span>
  );
}

