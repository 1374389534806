import { ReviewersData } from 'hooks/useGetReviewersData';

export function convertCsvToReviewersData(
  csv: string,
): { json: string; data: ReviewersData } | null {
  try {
    // Convert to JS array
    const a = csv.split(/\n/);
    a.shift();

    if (!a.length) {
      throw new Error();
    }

    const js: any = {};
    a.forEach((row, j) => {
      const items = row.split(',');
      const pa = items[0];

      if (!pa.length) return;

      if (!js[pa]) {
        js[pa] = {
          councils: [],
        };
      }
      js[pa].councils = js[pa].councils.concat([
        {
          name: items[2],
          policeAuthorityEmail: items[1],
          localCouncilEmail: items[3],
        },
      ]);
    });

    const json = JSON.stringify(js)
      .replace(/\[/g, '[\n\t\t\t')
      .replace(/},{/g, '\n\t\t\t},\n\t\t\t{')
      .replace(/"name"/g, '\t\t\t"name"')
      .replace(/"councils"/g, '\t"councils"')
      .replace(/"policeAuthorityEmail"/g, '\n\t\t\t\t"policeAuthorityEmail"')
      .replace(/"localCouncilEmail"/g, '\n\t\t\t\t"localCouncilEmail"')
      .replace(/\{/g, '{\n\t')
      .replace(/\: {/g, ': {\n\t\t\t')
      .replace(/,"/g, ',\n\t"')
      .replace(/:/g, ' : ')
      .replace(/}]},/g, '\n\t\t\t}\n\t\t]\n\t},')
      .replace(/"}]}}/g, '"\n\t\t\t}\n\t\t]\n\t}\n}')
      .replace(/\\r/g, '');

    return { json, data: JSON.parse(json) };
  } catch {
    return null;
  }
}

