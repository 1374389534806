import { SignOutButton } from 'auth/SignOutButton';
import { ActivityIndicator } from 'components/ActivityIndicator';
import ErrorDisplay from 'components/ErrorDisplay';
import { JourneyLink } from 'components/Journey/JourneyLink';
import { PercentageCompleteDisplay } from 'components/Journey/PercentageCompleteDisplay';
import { StarRating } from 'components/Journey/StarRating';
import { ResourcesPanel } from 'components/Resources/ResourcesPanel';
import SiteContext from 'components/Sites/SiteContext';
import { useGetMyUserSettings } from 'hooks/useGetMyUserSettings';
import { useGetVenueStatuses } from 'hooks/useGetVenueStatuses';
import React, { useContext } from 'react';
import { useHistory } from 'react-router';
import UserProfileContext from 'UserProfileContext';
import { getGreeting } from 'utils';

export function ResponsibleAuthorityPage() {
  const history = useHistory();

  const { isResponsibleAuthority } = useGetMyUserSettings();
  const userProfile = useContext(UserProfileContext);
  const { siteId } = useContext(SiteContext)!;

  const getVenueStatusesState = useGetVenueStatuses(siteId);
  const venueStatus = getVenueStatusesState?.currentStatus;

  if (!userProfile || isResponsibleAuthority === false) {
    history.push('/');
    return null;
  }

  return (
    <div className="dashboard">
      <section>
        <h3>
          <span>{`${getGreeting()} ${userProfile.firstName}`}</span> <SignOutButton />
        </h3>

        {getVenueStatusesState.loading ? (
          <ActivityIndicator showProgressBar />
        ) : (
          <div>
            {getVenueStatusesState.error && <ErrorDisplay error={getVenueStatusesState.error} />}
            {!venueStatus?.AssessmentFormId ? (
              <div>
                <p>Start the assessment below.</p>
                <JourneyLink to="/assessment">Begin Assessment</JourneyLink>
              </div>
            ) : (
              <div>
                {venueStatus.PercentageComplete !== 100 ? (
                  <div>
                    <p>Current assessment progress:</p>
                    <div className="dashboard-continue">
                      <PercentageCompleteDisplay value={venueStatus.PercentageComplete} />
                      <div>
                        <JourneyLink to="/assessment">Continue Assessment</JourneyLink>
                      </div>
                    </div>
                  </div>
                ) : (
                  <div>
                    {venueStatus.IsCritical ? (
                      <div>
                        <div className="alert alert--error">
                          <h4>Warning</h4>
                          <p>
                            Your response to one or more of the questions has indicated that a
                            policy or practice within this business is unlawful. As a consequence of
                            your response to one or more of these CRITICAL POINTS (CP) this business
                            has an overall star rating of 0.
                          </p>
                        </div>
                        <hr />
                      </div>
                    ) : (
                      <div>
                        <p>
                          The assessment is <strong>100% complete</strong> and would produce the
                          following score.
                        </p>
                        <p>
                          <strong>Licensing SAVI Award rating:</strong>
                        </p>
                        <StarRating value={venueStatus.StarRating ?? 0} />
                        <hr />
                      </div>
                    )}

                    <ul className="dashboard-list">
                      <li>
                        <JourneyLink to="/recommendations" className="button-secondary">
                          View the advice and recommendations
                        </JourneyLink>
                      </li>
                      <li>
                        <JourneyLink to="/assessment" className="button-secondary">
                          Edit the assessment
                        </JourneyLink>
                      </li>
                    </ul>
                  </div>
                )}
              </div>
            )}
          </div>
        )}

        <hr />

        <div className="alert alert--resources">
          <h4>Available resources</h4>
          <ResourcesPanel />
        </div>
        <hr />
      </section>
    </div>
  );
}

