import { SignInButton } from 'auth/SignInButton';
import React from 'react';
import { getOrigin } from 'utils';
import { RegisterForm } from './RegisterForm';
import { SupportedBy } from './SupportedBy';

export function RegisterPage() {
  const origin = getOrigin();

  return (
    <div className="registration-was-modal">
      <div className="tillr-mdl-modal">
        <div className="tillr-mdl-modal__content">
          <div className="mdl-dialog">
            <div className="mdl-dialog__content">
              <div className="registration-modal__form">
                <section>
                  <RegisterForm origin={origin} />
                </section>

                <section>
                  <hr />
                  <div className="already-registered">
                    <h4>Already registered with us?</h4>
                    <SignInButton />
                  </div>
                  <hr />
                  <SupportedBy origin={origin} />
                </section>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

