import { DialogActions, TextField } from '@material-ui/core';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Autocomplete, { createFilterOptions } from '@material-ui/lab/Autocomplete';
import { useCreateSite, useGetSites, useUpdateSite } from 'apollo-hooks';
import ErrorDisplay from 'components/ErrorDisplay';
import { CancelButton } from 'components/Shared/CancelButton';
import React, { useEffect } from 'react';
import { CreateSiteMutationVariables, UpdateSiteMutationVariables } from 'tillr-graphql';
import { VENUE_SITE_TYPE } from 'types';

interface IProps {
  chainId: number;
  parentSiteId: number;
  onCloseMenu?: (event: React.MouseEvent<HTMLButtonElement>) => void;
  refetch: () => void;
  inline?: boolean | undefined;
}

interface SiteOptionType {
  inputValue?: string;
  name: string;
  id?: number;
}

const filter = createFilterOptions<SiteOptionType>();

export function AddVenueAdmin(props: IProps) {
  const { chainId, parentSiteId, onCloseMenu, refetch, inline } = props;

  const getSitesState = useGetSites({ parentSiteId: null, type: VENUE_SITE_TYPE });

  const [open, setOpen] = React.useState(false);

  const [value, setValue] = React.useState<SiteOptionType | null>(null);

  const [createSite, createSiteState] = useCreateSite({
    getSiteAndChildrenQueryVariables: { id: chainId },
  });
  const [updateSite, updateSiteState] = useUpdateSite();

  useEffect(() => {
    if (createSiteState.data || updateSiteState.data) {
      if (createSiteState.data) {
        createSiteState.reset();
      }
      if (updateSiteState.data) {
        updateSiteState.reset();
        refetch();
        getSitesState.refetch();
      }
      setOpen(false);
    }
  }, [createSiteState, updateSiteState]);

  const handleSubmit = () => {
    if (!value) {
      return;
    }
    if (value.id) {
      const site = getSitesState.data!.sites!.find((x) => x.id === value.id)!;

      const variables: UpdateSiteMutationVariables = {
        site: {
          id: site.id,
          name: site.name,
          type: site.type,
          parentSiteId,
        },
      };
      updateSite({ variables });
    } else {
      const variables: CreateSiteMutationVariables = {
        parentSiteId,
        site: {
          name: value.name,
          type: VENUE_SITE_TYPE,
        },
      };
      createSite({ variables });
    }
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleClickOpen = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.preventDefault();
    setOpen(true);
    onCloseMenu?.(event);
  };

  return (
    <>
      <Button
        onClick={handleClickOpen}
        variant={inline ? 'outlined' : 'text'}
        size={inline ? 'small' : 'medium'}
      >
        Add venue
      </Button>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="form-dialog-title"
        fullWidth
        maxWidth="xs"
      >
        <DialogTitle id="form-dialog-title">Add venue</DialogTitle>
        <DialogContent>
          {createSiteState.error && <ErrorDisplay error={createSiteState.error} />}
          {updateSiteState.error && <ErrorDisplay error={updateSiteState.error} />}

          {getSitesState.data?.sites && (
            <form
              noValidate
              autoComplete="off"
              onSubmit={(e) => {
                e.preventDefault();
              }}
            >
              <Autocomplete
                value={value}
                onChange={(event, newValue) => {
                  if (typeof newValue === 'string') {
                    setValue({
                      name: newValue,
                    });
                  } else if (newValue && newValue.inputValue) {
                    // Create a new value from the user input
                    setValue({
                      name: newValue.inputValue,
                    });
                  } else {
                    setValue(newValue);
                  }
                }}
                filterOptions={(options, params) => {
                  const filtered = filter(options, params);

                  // Suggest the creation of a new value
                  if (params.inputValue !== '') {
                    filtered.push({
                      inputValue: params.inputValue,
                      name: `Create new venue "${params.inputValue}"`,
                    });
                  }

                  return filtered;
                }}
                selectOnFocus
                clearOnBlur
                handleHomeEndKeys
                options={getSitesState.data.sites.map((site) => ({
                  inputValue: undefined,
                  name: site.name,
                  id: site.id,
                }))}
                getOptionLabel={(option) => {
                  // Value selected with enter, right from the input
                  if (typeof option === 'string') {
                    return option;
                  }
                  // Add "xxx" option created dynamically
                  if (option.inputValue) {
                    return option.inputValue;
                  }
                  // Regular option
                  return option.name;
                }}
                renderOption={(option) => option.name}
                freeSolo
                renderInput={(params) => (
                  <TextField {...params} label="Venue name" variant="outlined" />
                )}
              />
            </form>
          )}
          <DialogActions>
            <CancelButton onClick={handleClose} />
            <Button
              color="primary"
              variant="contained"
              disabled={!value || createSiteState.loading || updateSiteState.loading}
              onClick={handleSubmit}
            >
              Save
            </Button>
          </DialogActions>
        </DialogContent>
      </Dialog>
    </>
  );
}
