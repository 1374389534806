import { ActivityIndicator } from 'components/ActivityIndicator';
import ErrorDisplay from 'components/ErrorDisplay';
import { TillrModal } from 'components/Shared/TillrModal';
import FormlyForm from 'formly/FormlyForm';
import { IModel } from 'formly/IModel';
import React, { useContext } from 'react';
import { Link, useHistory } from 'react-router-dom';
import { useContactSupportMutation } from 'store/emailService/emailServiceApi';
import UserProfileContext from 'UserProfileContext';
import { getErrorMessage } from 'utils';

export function ContactUsPage() {
  const history = useHistory();

  const userProfile = useContext(UserProfileContext);

  const [contactSupport, contactSupportState] = useContactSupportMutation();

  const initialModel = {
    Name: userProfile?.name,
    Email: userProfile?.email,
  };

  const fields = [
    {
      id: 'name',
      type: 'input',
      templateOptions: {
        label: 'Your name',
        required: true,
      },
      key: 'Name',
      className: 'formly-builder-element col-xs-12',
    },

    {
      id: 'email',
      type: 'input',
      templateOptions: {
        label: 'Your email address',
        required: true,
      },
      key: 'Email',
      className: 'formly-builder-element col-xs-12',
    },

    {
      type: 'tlrSelect',
      data: {
        format: 'element',
        prompt: 'Select',
        help: 'This will ensure your message goes to the right people.',
      },
      templateOptions: {
        required: true,
        disabled: false,
        options: [
          {
            name: 'I have a question about the content',
            value: false,
          },
          {
            name: 'I have a technical issue',
            value: true,
          },
        ],
        valueProp: 'value',
        label: 'Message category',
      },
      className: 'formly-builder-element col-xs-12',
      key: 'Category',
    },

    {
      id: 'message',
      type: 'textarea',
      templateOptions: {
        label: 'Your message',
        required: true,
      },
      key: 'Message',
      className: 'formly-builder-element col-xs-12',
    },
  ];

  const handleSubmit = (submittedModel: IModel) => {
    contactSupport({
      Name: submittedModel.Name!,
      Email: submittedModel.Email!,
      Technical: submittedModel.Category === 'true',
      Message: submittedModel.Message!,
    });
  };

  const handleCloseModal = () => {
    contactSupportState.reset();
    history.push('/');
  };

  return (
    <>
      <div className="contact">
        <section>
          <h3>
            Contact us{' '}
            <Link to="/">
              <button type="button">back</button>
            </Link>
          </h3>
          {contactSupportState.isLoading && <ActivityIndicator />}
          {contactSupportState.error && (
            <ErrorDisplay error={getErrorMessage(contactSupportState.error)} />
          )}
          <FormlyForm
            config={{ fields }}
            model={initialModel}
            submitting={contactSupportState.isLoading}
            redSaveButton
            redSaveButtonLabel="Send"
            onSubmit={handleSubmit}
          />
        </section>
      </div>
      {contactSupportState.isSuccess && (
        <TillrModal title="Thank you" okButtonText="Close" onOk={handleCloseModal}>
          Your message has been sent, and we will be in touch soon.
        </TillrModal>
      )}
    </>
  );
}

