import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import { useUpdateSite } from 'apollo-hooks';
import ErrorDisplay from 'components/ErrorDisplay';
import FormlyForm from 'formly/FormlyForm';
import getStaticTemplate from 'formly/staticTemplates';
import React, { useEffect, useState } from 'react';
import { SiteItemFragment, UpdateSiteMutationVariables } from 'tillr-graphql';

interface IProps {
  site: SiteItemFragment;
  onCloseMenu: (event: React.MouseEvent<HTMLButtonElement>) => void;
  refetch: () => void;
}

export function EditSiteName(props: IProps) {
  const { site, onCloseMenu, refetch } = props;
  const [open, setOpen] = useState(false);

  const [updateSite, { loading, data, error }] = useUpdateSite();

  useEffect(() => {
    if (data?.updateSite) {
      refetch();
      setOpen(false);
    }
  }, [data]);

  const handleClose = () => {
    setOpen(false);
  };

  const handleSubmit = (model: { name: string }) => {
    if (site.name !== model.name) {
      const variables: UpdateSiteMutationVariables = {
        site: {
          id: site.id,
          name: model.name,
          type: site.type,
          parentSiteId: site.parentSiteId,
        },
      };
      updateSite({ variables });
    } else {
      handleClose();
    }
  };

  const handleClickOpen = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.preventDefault();
    setOpen(true);
    onCloseMenu(event);
  };

  const config = getStaticTemplate('edit-site-name');

  const model = {
    name: site.name,
  };

  return (
    <>
      <Button onClick={handleClickOpen} variant="text">
        Rename
      </Button>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="form-dialog-title"
        fullWidth
        maxWidth="xs"
      >
        <DialogTitle id="form-dialog-title">Rename {site.type!.toLowerCase()}</DialogTitle>
        <DialogContent>
          {error && <ErrorDisplay error={error} />}
          <FormlyForm
            config={config}
            onSubmit={handleSubmit}
            onCancel={handleClose}
            submitting={loading}
            model={model}
            hideMandatoryFieldMessage
            redSaveButton
          />
        </DialogContent>
      </Dialog>
    </>
  );
}
