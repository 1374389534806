import { addDays } from 'date-fns';
import { useEffect } from 'react';
import { useGetReviewsQuery } from 'store/api2/api2Api';
import { Review } from 'store/api2/types';

export type ReviewStatus = 'PENDING' | 'REJECTED' | 'PEND' | 'APPROVED';

function getReviewStatus(reviews: Array<Review>): ReviewStatus | null {
  if (reviews.length === 0) {
    return null;
  }

  if (reviews.some(({ Status }) => Status === 'Rejected')) {
    return 'REJECTED';
  }

  if (reviews.some(({ Status }) => Status === 'Paused')) {
    return 'PEND';
  }

  if (reviews.every(({ Status }) => Status === 'Approved' || Status === 'AutoApproved')) {
    return 'APPROVED';
  }

  return 'PENDING';
}

export function useGetReviewsData(formId: string | undefined) {
  const reviewsQuery = useGetReviewsQuery({ formId: formId ?? '' }, { skip: !formId });

  const reviewStatus = reviewsQuery.data ? getReviewStatus(reviewsQuery.data) : undefined;

  const requestedDate = reviewsQuery.data
    ? reviewsQuery.data.length > 0
      ? new Date(reviewsQuery.data[0].RequestedAt)
      : null
    : undefined;

  const expiryDate =
    requestedDate !== undefined
      ? requestedDate !== null
        ? addDays(requestedDate, 30)
        : null
      : undefined;

  return {
    ...reviewsQuery,
    reviewStatus,
    requestedDate,
    expiryDate,
  };
}

export function useScrollToTopEffect() {
  useEffect(() => {
    window.scrollTo(0, 0);
    return () => window.scrollTo(0, 0);
  }, []);
}

