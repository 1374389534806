import { Box, Card, CardContent, Typography } from '@material-ui/core';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import TreeView from '@material-ui/lab/TreeView';
import { useGetUsersAdmin } from 'apollo-hooks';
import { ActivityIndicator } from 'components/ActivityIndicator';
import ErrorDisplay from 'components/ErrorDisplay';
import LinkButton from 'components/Shared/LinkButton';
import { PageTitle } from 'components/Shared/PageTitle';
import { UpdateMarketingPreferencesDialogContainer } from 'components/Shared/UpdateMarketingPreferencesDialogContainer';
import { useGetVenueStatuses } from 'hooks/useGetVenueStatuses';
import React, { useEffect, useState } from 'react';
import { SiteItemFragment } from 'tillr-graphql';
import { CHAIN_SITE_TYPE, SiteType, VENUE_SITE_TYPE } from 'types';
import { useGetChainFromParams } from '../useGetChainFromParams';
import siteStyles from './siteStyles';
import { SummarySiteTree } from './SummarySiteTree';

export function ChainVenuesDetailsPage() {
  const getChainState = useGetChainFromParams();
  const { chainId, chainSite, chainSiteAndChildren } = getChainState;

  const classes = siteStyles();
  const getUsersState = useGetUsersAdmin({ siteId: chainId });
  const getVenueStatusesState = useGetVenueStatuses(chainId);

  const [expanded, setExpanded] = useState<string[]>([]);

  const handleToggle = (event: React.ChangeEvent<{}>, nodeIds: string[]) => {
    event.persist();
    const iconClicked = (event.target as Element).closest('.MuiTreeItem-iconContainer');
    if (iconClicked) {
      setExpanded(nodeIds);
    }
  };

  useEffect(() => {
    let expandedArray: string[] = [];
    if (chainSiteAndChildren) {
      expandedArray = chainSiteAndChildren.map((site) => site.id.toString());
    }
    setExpanded(expandedArray);
  }, [chainSiteAndChildren, setExpanded]);

  const renderTree = (sites: SiteItemFragment[], parentSiteId: number | null) => {
    const childSites = sites
      .filter((x) => x.parentSiteId === parentSiteId)
      .sort((a, b) => a.name.localeCompare(b.name))
      // Put venues above groups
      .sort((a, b) => (a.type === b.type ? 0 : a.type === VENUE_SITE_TYPE ? -1 : 1));

    if (childSites.length > 0) {
      return (
        <>
          {childSites.map((x) => (
            <SummarySiteTree
              key={x.id}
              chainId={chainId}
              siteId={x.id}
              getUsersState={getUsersState}
              getVenueStatusesState={getVenueStatusesState}
              name={x.name}
              siteType={(x.type ?? VENUE_SITE_TYPE) as SiteType}
              labelText={x.name}
              labelInfo="90"
            >
              {renderTree(sites, x.id)}
            </SummarySiteTree>
          ))}
        </>
      );
    }
    return null;
  };

  return (
    <>
      <UpdateMarketingPreferencesDialogContainer />

      <PageTitle title={chainSite?.name ?? 'Manage chain'} />
      <div className="page">
        <section>
          <Box display="flex" justifyContent="space-between">
            <Typography gutterBottom>
              To make changes, click the "Manage" button on the right.
            </Typography>
            <Box mb={1}>
              <LinkButton to={`/chains/${chainId}/manage`}>Manage</LinkButton>
            </Box>
          </Box>

          {(getChainState.loading || getUsersState.loading) && (
            <ActivityIndicator showProgressBar />
          )}
          {getChainState.error && <ErrorDisplay error={getChainState.error} />}
          {getUsersState.error && <ErrorDisplay error={getUsersState.error} />}

          {chainSiteAndChildren && chainSite && getUsersState.data && (
            <Card>
              <CardContent>
                <TreeView
                  className={classes.root}
                  expanded={expanded}
                  defaultCollapseIcon={<ExpandMoreIcon color="primary" />}
                  defaultExpandIcon={<ChevronRightIcon color="primary" />}
                  defaultEndIcon={<div style={{ width: 24 }} />}
                  onNodeToggle={handleToggle}
                >
                  <SummarySiteTree
                    chainId={chainId}
                    siteId={chainId}
                    siteType={CHAIN_SITE_TYPE}
                    name={chainSite.name}
                    labelText={chainSite.name}
                    getUsersState={getUsersState}
                    getVenueStatusesState={getVenueStatusesState}
                    labelInfo="90"
                  >
                    {renderTree(chainSiteAndChildren, chainId)}
                  </SummarySiteTree>
                </TreeView>
              </CardContent>
            </Card>
          )}
        </section>
      </div>
    </>
  );
}
